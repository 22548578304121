.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.are-vertically-aligned-center {
  display: flex;
  align-items: center;
}

.footer__address a {
  display: flex;
  margin-top: 1em;
}

.footer__address .footer__address-detail {
  margin-left: unset;
}

.footer__email a {
  display: flex;
  text-align: left;
  margin-top: 1em;
  margin-left: unset;
}

.footer__address a:hover,
.footer__email a:hover,
.footer__phone-number a:hover {
  text-decoration: unset;
}

.footer__email p {
  margin-left: 0.5em;
}

@media (min-width: 768px) {
  .footer__address a {
    margin-top: 0;
  }

  .footer__email a {
    margin-top: 0;
    margin-left: 3em;
  }
}

@media (min-width: 1280px) {
  .footer__address a,
  .footer__email a {
    margin-left: initial;
    margin-top: 1em;
  }
}

.swiper-button-disabled {
  display: none !important;
}

.home-slider + .container .swiper {
  margin-top: -80px !important;
}

.next-games--carousel .swiper-button-prev,
.next-games--carousel .swiper-button-next {
  position: absolute;
  top: 100px;
  right: 15px;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  transition: opacity 250ms ease-in-out;
}

.next-games--carousel .swiper-button-prev:hover,
.next-games--carousel .swiper-button-next:hover {
  opacity: 1;
}

.next-games--carousel .swiper-button-prev:after {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OSA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjMiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDQ4LjQyMTkgLTAuMDA3ODEyNSkiIHN0cm9rZT0iI0ZBRkFGQSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0yNy40MjE5IDI5Ljk5MjJMMjEuNDIxOSAyMy45OTIyTDI3LjQyMTkgMTcuOTkyMiIgc3Ryb2tlPSIjRkFGQUZBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
}

.next-games--carousel .swiper-button-next:after {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OSA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQuNDIxOSIgY3k9IjIzLjk5MjIiIHI9IjIzIiBzdHJva2U9IiNGQUZBRkEiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjEuNDIxOSAyOS45OTIyTDI3LjQyMTkgMjMuOTkyMkwyMS40MjE5IDE3Ljk5MjIiIHN0cm9rZT0iI0ZBRkFGQSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
  background-size: contain;
  background-position: center;
}

@media (min-width: 769px) {
  .next-games--carousel .swiper-button-prev,
  .next-games--carousel .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  .next-games--carousel .swiper-button-next {
    right: 30px;
    top: 100px;
  }

  .next-games--carousel .swiper-button-prev {
    left: 30px;
    top: 100px;
  }

  .next-games--carousel .swiper-button-prev:after,
  .next-games--carousel .swiper-button-next:after {
    height: 48px;
    width: 48px;
  }
}

.display-desktop-only {
  display: none !important;
}

@media (min-width: 1080px) {
  .display-desktop-only {
    display: block !important;
  }

  .mobile-tablet-only {
    display: none !important;
  }
  body {
    /** Fix pour que la nav puisse être sticky */
    overflow:visible!important; 
  }
  .topbar {
    position:sticky !important;
    top:0;
    z-index:11!important;
  }
}

/* C3PR et nouvelles topbar */
.btn--red-dark {
  background-color: #f2362f;
}

.topbar .btn--red-dark {
  line-height: 32px;
  height: unset;
  display: block;
  float: right;
  font-size: 13px;
  font-weight: 500;
  margin-right: 16px;
  margin-top: 4px;
}

@media (max-width: 1079px) {
  body {
    /** Fix pour que la nav puisse être sticky */
    overflow:visible!important; 
  }
  #root > .topbar:not(#topbar--mobile) {
      display: none;
  }
  
  .home-slider__item {
    padding-top:130px!important;
  }
  #topbar--mobile {
    color: white;
    height: 36px;
    text-align: center;
    position:sticky !important;
    top:0;
    z-index:11!important;
  }

  #topbar--mobile.topbar {
    padding: unset;
    background-color: unset;
    margin-bottom: unset;
    line-height: 32px;
  }

  #topbar--mobile .topbar {
    z-index:11!important;
  }

  #topbar--mobile.topbar li {
    margin-bottom: unset;
  }

  #topbar--mobile .topbar--mobile__left {
    background-color: #002348;
    flex-grow: 1;
  }

  .icon-chevron-down::before {
    content: '\e928';
    font-family: 'icon';
    font-size: 6px;
    margin-left: 6px;
    display: block;
  }

  #topbar--mobile .topbar--mobile__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #topbar--mobile .topbar--mobile__left .topbar--mobile__links {
    text-align: left;
  }

  #topbar--mobile.topbar .topbar__right {
    width: 40%;
    max-width: 180px;
  }

  #topbar--mobile > ul:nth-child(1) {
    display: flex;
    overflow:hidden;
  }

  .topbar--mobile__button {
    width: 100%;
    color: white;
  }

  .topbar--mobile__links {
    display: none;
  }

  .topbar--mobile__links.is-visible {
    display: block;
    position: absolute;
  }

  .topbar--mobile__links.is-visible li {
    line-height: 42px;
  }

  .topbar--mobile_important-link {
    background-color: #f2362f;
    width: 25%;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 30%
  }

  .topbar--mobile_important-link {
    padding: 0 8px;
  }

  .topbar--mobile_important-link a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #topbar--mobile .topbar__right {
    background-color: #7d93a8;
    display: block;
  }

  #topbar--mobile .topbar__login {
    margin-top: unset;
    margin-right: unset;
    background-color: #7d93a8;
    text-wrap: nowrap;
  }

  .topbar__right .topbar__sub {
    width: 230px;
    position: absolute;
    right: 0;
    background-color: #7d93a8;
    line-height: 2;
    padding: 8px;
    color: rgba(255, 255, 255, 0.6);
  }
  .topbar__right .topbar__sub li {
    display: block;
  }
  .topbar__right .topbar__sub a {
    padding: 0;
  }
  .topbar__right i {
    top: -2px;
  }
  .topbar__right .topbar__sub {
    padding-bottom: 16px;
  }

  .topbar--mobile__left .accordion-trigger,
  .topbar--mobile__left .accordion-trigger.is-open {
    color: #7d93a8 !important;
  }

  .topbar__user {
    padding: 0 8px;
    color: #fff;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid rgba(255, 255, 255, 0.06);
  }
  .topbar__user img {
    margin-right: 16px;
    border-radius: 100%;
  }
  .topbar__login {
    display: inline-block;
    padding: 0;
    background: #1a395a;
    color: #fff;
    padding: 0 8px;
    border-radius: 4px;
    margin-right: 4px;
    margin-top: 4px;
  }
  .topbar__login:hover {
    background: #20456e;
  }

  .header__mobile {
    line-height: unset;
    text-align: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .header__mobile > i {
    position: relative;
    right: unset;
  }

  .header__mobile div .icon.icon-menu {
    width: 72px;
    line-height: 72px;
    font-size: 20px;
    color: #7d93a8 !important;
    cursor: pointer;
  }

  .header--transparent .header__mobile div .icon.icon-menu {
    color: white;
  }

  .header__mobile .input--search.input--expand {
    color: #7d93a8;
    position: relative;
    height: fit-content;
  }

  .header__mobile .input--search.input--expand.is-open {
    position: absolute;
    right: 0;
    height: 72px;
    top: 0;
  }

  .header__mobile__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__mobile .icon.icon-place {
    display: inline-block;
    color: #7d93a8;
    font-size: 26px;
    margin-right: unset;
  }

  .header--transparent .header__mobile .icon.icon-place {
    color: white;
  }

  .menu__right {
    display: none;
  }

  .header__wrapper .topbar {
    display: none;
  }

  .header__wrapper .menu {
    padding-top: unset;
  }

  #topbar--mobile .accordion-trigger {
    padding-right: 22px;
    position: relative;
  }

  .topbar--mobile__left.is-open .icon-chevron-down::before,
  #topbar--mobile .accordion-trigger.is-open::after {
    content: '\e929';
  }

  #topbar--mobile .accordion-trigger:after {
    float: none;
    position: absolute;
    right: 8px;
    top: 0;
    line-height: unset;
  }

  .header--transparent {
    background-color: white !important;
  }

  /* + 36px pour la topbar*/
  .banner {
    padding-top: 122px !important;
  }

  /* .header.has-current-competition + section.banner {
    min-height: 532px; + 66px pour la bannière de compétitions
    padding-top: 188px;
  } */
  .banner.banner--big,
  .banner.banner--bigNoAfter {
    height: 580px;
    padding-top: 128px;
  }

  .banner.banner--events {
    min-height: 486px;
    padding-top: 128px;
  }

  .banner.banner--themes {
    min-height: 480px;
    padding-top: 128px;
  }
}

@media (max-width: 767px) {
  .header__wrapper.is-visible {
    top: 108px !important;
    height: calc(100% - 108px) !important;
  }
}
