@charset "UTF-8";

@font-face{
  font-family:Din;
  src:url(../font/din-regular.woff2) format("woff2"), url(../font/din-regular.woff) format("woff");
  font-weight:400;
  font-style:normal;
}

@font-face{
  font-family:Din;
  src:url(../font/din-medium.woff2) format("woff2"), url(../font/din-medium.woff) format("woff");
  font-weight:500;
  font-style:normal;
}

@font-face{
  font-family:Din;
  src:url(../font/din-bold.woff2) format("woff2"), url(../font/din-bold.woff) format("woff");
  font-weight:700;
  font-style:normal;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-light.woff2) format("woff2"), url(../font/roboto-light.woff) format("woff");
  font-weight:300;
  font-style:normal;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-light-italic.woff2) format("woff2"), url(../font/roboto-light-italic.woff) format("woff");
  font-weight:300;
  font-style:italic;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-regular.woff2) format("woff2"), url(../font/roboto-regular.woff) format("woff");
  font-weight:400;
  font-style:normal;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-regular-italic.woff2) format("woff2"), url(../font/roboto-regular-italic.woff) format("woff");
  font-weight:400;
  font-style:italic;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-medium.woff2) format("woff2"), url(../font/roboto-medium.woff) format("woff");
  font-weight:500;
  font-style:normal;
}

@font-face{
  font-family:Roboto;
  src:url(../font/roboto-bold.woff2) format("woff2"), url(../font/roboto-bold.woff) format("woff");
  font-weight:700;
  font-style:normal;
}

@font-face{
  font-family:icon;
  src:url(../font/icon.eot?iju4vk);
  src:url(../font/icon.eot?iju4vk#iefix) format("embedded-opentype"), url(../font/icon.woff2?iju4vk) format("woff2"), url(../font/icon.woff?iju4vk) format("woff"), url(../font/icon.ttf?iju4vk) format("truetype"), url(../font/icon.svg?iju4vk#icon) format("svg");
  font-weight:400;
  font-style:normal;
}

.container{
  width:100%;
  margin-right:auto;
  margin-left:auto;
  padding-left:16px;
  padding-right:16px;
}

.container--ride-up{
  margin-top:-148px;
}

.row{
  margin-left:-20px;
}

.row:after{
  display:table;
  clear:both;
  content:"";
}

.col{
  position:relative;
  float:left;
  min-height:1px;
  padding-left:20px;
  width:100%;
}

.col--center{
  margin-left:auto;
  margin-right:auto;
  float:none;
}

.bb1{
  border-bottom:1px solid #ebebeb;
}

.col.no-padding,.no-padding{
  padding-bottom:0;
}

.z1{
  z-index:1;
}

.z2{
  z-index:2;
}

.z3{
  z-index:3;
}

.col-xs-1{
  width:25%;
}

.col-xs-2{
  width:50%;
}

.col-xs-3{
  width:75%;
}

@media (min-width:768px){
  .container{
    max-width:1272px;
    padding-left:16px;
    padding-right:16px;
  }
  .row{
    margin-left:-16px;
  }
  .col{
    padding-left:16px;
  }
  .col-md-1{
    width:8.33333%;
  }
  .col-md-2{
    width:16.66667%;
  }
  .col-md-3{
    width:25%;
  }
  .col-md-4{
    width:33.33333%;
  }
  .col-md-5{
    width:41.66667%;
  }
  .col-md-6{
    width:50%;
  }
  .col-md-7{
    width:58.33333%;
  }
  .col-md-8{
    width:66.66667%;
  }
  .col-md-9{
    width:75%;
  }
  .col-md-10{
    width:83.33333%;
  }
  .col-md-11{
    width:91.66667%;
  }
  .col-md-12{
    width:100%;
  }
}

@media (min-width:1080px){
  .container.has-sidebar .col-lg-8{
    width:66.66667%;
  }
}

@media (min-width:1280px){
  .container{
    max-width:1680px;
    padding-left:100px;
    padding-right:100px;
  }
  .container--tv{
    padding-left:40px;
    padding-right:40px;
  }
  .container--tv .row{
    margin-left:-40px;
  }
  .container--tv .col{
    padding-left:40px;
  }
  .row{
    margin-left:-32px;
  }
  .col{
    padding-left:32px;
  }
  .col-lg-1{
    width:8.33333%;
  }
  .col-lg-2{
    width:16.66667%;
  }
  .col-lg-offset-2{
    margin-left:16.66667%;
  }
  .col-lg-3{
    width:25%;
  }
  .col-lg-offset-3{
    margin-left:25%;
  }
  .col-lg-4{
    width:33.33333%;
  }
  .col-lg-offset-4{
    margin-left:33.33333%;
  }
  .col-lg-5{
    width:41.66667%;
  }
  .col-lg-offset-5{
    margin-left:41.66667%;
  }
  .col-lg-6{
    width:50%;
  }
  .col-lg-offset-6{
    margin-left:50%;
  }
  .col-lg-7{
    width:58.33333%;
  }
  .col-lg-offset-7{
    margin-left:58.33333%;
  }
  .col-lg-8{
    width:66.66667%;
  }
  .col-lg-offset-8{
    margin-left:66.66667%;
  }
  .col-lg-9{
    width:75%;
  }
  .col-lg-offset-9{
    margin-left:75%;
  }
  .col-lg-10{
    width:83.33333%;
  }
  .col-lg-offset-10{
    margin-left:83.33333%;
  }
  .col-lg-11{
    width:91.66667%;
  }
  .col-lg-offset-11{
    margin-left:91.66667%;
  }
  .col-lg-12{
    width:100%;
  }
  .col-lg-offset-12{
    margin-left:100%;
  }
  .col-lg-offset-1{
    margin-left:8.33333%;
    margin-left:16.66667%;
  }
  .col-lg-1on9{
    width:11.11111%;
  }
  .col-lg-2on9{
    width:22.22222%;
  }
  .col-lg-3on9{
    width:33.33333%;
  }
  .col-lg-4on9{
    width:44.44444%;
  }
  .col-lg-5on9{
    width:55.55556%;
  }
  .col-lg-6on9{
    width:66.66667%;
  }
  .col-lg-7on9{
    width:77.77778%;
  }
  .col-lg-8on9{
    width:88.88889%;
  }
  .col-lg-9on9{
    width:100%;
  }
  .col-lg-offset-1on9{
    margin-left:11.11111%;
  }
  .col-lg-fixed{
    width:424px;
  }
  .col-lg-fixed+.col{
    width:calc(100% - 424px);
  }
}

.hide-minScreen{
  display:block;
}

@media screen and (min-width:768px) and (max-width:1279px){
  .hide-minScreen{
    display:none;
  }
}

.display-desktop{
  display:none;
}

@media screen and (min-width:1280px){
  .display-desktop{
    display:block;
  }
}

.grid-debug{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:10000;
  opacity:0;
  pointer-events:none;
  display:none;
}

.grid-debug.is-visible{
  display:block;
  opacity:.4;
}

.grid-debug .container,.grid-debug .row{
  height:100%;
}

.grid-debug .col,.grid-debug .col div{
  height:100%;
  background:rgba(27, 122, 181, .3);
}

body,html{
  overflow-x:hidden;
}

body{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  color:#292929;
  font-family:Roboto,arial,sans-serif;
  font-size:14px;
  line-height:1.58;
  font-weight:400;
  margin:0;
  padding:0;
  background-color:#f0f2f5;
}

body.prevent-scroll{
  position:fixed;
  width:100%;
  overflow:hidden;
}

body.body-dark{
  background-color:#04182d;
  color:#fff;
}

[role=button],a{
  outline:none;
}

@media screen and (max-device-width:480px){
  body{
    -webkit-text-size-adjust:none;
  }
}

*,:after,:before{
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
}

blockquote,caption,details,dl,figure,hgroup,label,ol,p,pre,td,textarea,th,ul{
  margin:0 0 8px;
}

body,figure,html,label,textarea{
  margin:0;
  padding:0;
}

h1,h2,h3,h4,h5,h6{
  margin:0;
  font-weight:700;
  font-family:Din;
}

ol,ul{
  padding-left:0;
}

table{
  margin-bottom:0;
}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section{
  display:block;
}
blockquote,code,img,input,pre,table,td,textarea,video{
  max-width:100%;
}

img{
  height:auto;
}

div,h1,p,table,td,textarea,th{
  word-wrap:break-word;
  -webkit-hyphens:none;
      -ms-hyphens:none;
          hyphens:none;
}

input,select,textarea{
  font-family:inherit;
  font-size:14px;
  font-weight:400;
}

input:disabled,select:disabled,textarea:disabled{
  cursor:not-allowed;
}

button,input[type=button],input[type=reset],input[type=submit]{
  -webkit-appearance:none;
  font:inherit;
}

button{
  background:none;
  border:0;
  cursor:pointer;
}

img{
  vertical-align:middle;
}

a img{
  border:0;
}
body>script{
  display:none !important;
}

a{
  color:inherit;
  text-decoration:none;
  cursor:pointer;
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
}

a:hover{
  text-decoration:underline;
  color:#e6679a;
}

ul{
  list-style:none;
}

figure div,figure img{
  margin-bottom:8px;
}

figcaption{
  font-size:12px;
  line-height:18px;
  color:#999;
  margin-bottom:16px;
  font-weight:500;
}

iframe{
  border:0;
  overflow:hidden;
}

.clearfix:after{
  content:" ";
  display:table;
  clear:both;
}

.clear{
  clear:both;
}

::placeholder{
  color:#999;
}

::-webkit-input-placeholder{
  color:#999;
}

::-moz-placeholder{
  color:#999;
}

:-ms-input-placeholder{
  color:#999 !important;
}

::-ms-input-placeholder{
  color:#999;
}

::-webkit-search-cancel-button{
  -webkit-appearance:none;
  height:14px;
  width:14px;
  border-radius:100%;
  background:url(../img/icon-delete.svg) no-repeat 50%;
  background-size:contain;
}

@media (max-width:450px){
  .mobile-ban:not(.mobile-ban--force-display),.mobile-only.mobile-only--force-hide{
    display:none;
  }
}

@media (min-width:451px){
  .mobile-only{
    display:none;
  }
}

.has-text-align-center{
  text-align:center;
}

.has-text-align-right{
  text-align:right;
}

p{
  clear:both;
}

p.img--left>img{
  float:left;
  margin-right:10px;
  margin-bottom:10px;
}

p.img--right>img{
  float:right;
  margin-left:10px;
}

.mt-0{
  margin-top:0;
}

.mb-0{
  margin-bottom:0;
}

.mt-1{
  margin-top:8px;
}

.mb-1{
  margin-bottom:8px;
}

.mt-2{
  margin-top:16px;
}

.mb-2{
  margin-bottom:16px;
}

.mt-3{
  margin-top:24px;
}

.mb-3{
  margin-bottom:24px;
}

.mt-4{
  margin-top:32px;
}

.mb-4{
  margin-bottom:32px;
}

.mt-5{
  margin-top:40px;
}

.mb-5{
  margin-bottom:40px;
}

.mt-6{
  margin-top:48px;
}

.mb-6{
  margin-bottom:48px;
}

.mt-7{
  margin-top:56px;
}

.mb-7{
  margin-bottom:56px;
}

.mt-8{
  margin-top:80px;
}

.mb-8{
  margin-bottom:80px;
}

@media (min-width:768px){
  .mt-md-0{
    margin-top:0;
  }
  .mb-md-0{
    margin-bottom:0;
  }
  .mt-md-1{
    margin-top:8px;
  }
  .mb-md-1{
    margin-bottom:8px;
  }
  .mt-md-2{
    margin-top:16px;
  }
  .mb-md-2{
    margin-bottom:16px;
  }
  .mt-md-3{
    margin-top:24px;
  }
  .mb-md-3{
    margin-bottom:24px;
  }
  .mt-md-4{
    margin-top:32px;
  }
  .mb-md-4{
    margin-bottom:32px;
  }
  .mt-md-5{
    margin-top:40px;
  }
  .mb-md-5{
    margin-bottom:40px;
  }
  .mt-md-6{
    margin-top:48px;
  }
  .mb-md-6{
    margin-bottom:48px;
  }
  .mt-md-7{
    margin-top:56px;
  }
  .mb-md-7{
    margin-bottom:56px;
  }
  .mt-md-8{
    margin-top:80px;
  }
  .mb-md-8{
    margin-bottom:80px;
  }
  .mt-md-60{
    margin-top:60px;
  }
}

@media (min-width:1280px){
  .mt-lg-0{
    margin-top:0;
  }
  .mb-lg-0{
    margin-bottom:0;
  }
  .mt-lg-1{
    margin-top:8px;
  }
  .mb-lg-1{
    margin-bottom:8px;
  }
  .mt-lg-2{
    margin-top:16px;
  }
  .mb-lg-2{
    margin-bottom:16px;
  }
  .mt-lg-3{
    margin-top:24px;
  }
  .mb-lg-3{
    margin-bottom:24px;
  }
  .mt-lg-4{
    margin-top:32px;
  }
  .mb-lg-4{
    margin-bottom:32px;
  }
  .mt-lg-5{
    margin-top:40px;
  }
  .mb-lg-5{
    margin-bottom:40px;
  }
  .mt-lg-6{
    margin-top:48px;
  }
  .mb-lg-6{
    margin-bottom:48px;
  }
  .mt-lg-7{
    margin-top:56px;
  }
  .mb-lg-7{
    margin-bottom:56px;
  }
  .mt-lg-8{
    margin-top:80px;
  }
  .mb-lg-8{
    margin-bottom:80px;
  }
  .mt-lg-8{
    margin-top:64px;
  }
}

.ft-h1,.ft-h2,.ft-h3,.ft-h4,.ft-h5,.ft-h6{
  font-weight:700;
  font-family:Din;
}

.ft-h1.is-inline,.ft-h2.is-inline,.ft-h3.is-inline,.ft-h4.is-inline,.ft-h5.is-inline,.ft-h6.is-inline{
  display:inline-block;
}

.ft-h1,h1{
  font-size:40px;
  line-height:40px;
}

.ft-h2,h2{
  font-size:30px;
  line-height:36px;
}

.ft-h3,h3{
  font-size:20px;
  line-height:24px;
}

.ft-h4,h4{
  font-size:17px;
  line-height:22px;
}

@media (min-width:768px){
  .ft-h4,h4{
    font-size:15px;
    line-height:20px;
  }
}

.ft-h5,h5{
  font-size:15px;
  line-height:20px;
}

.ft-h6,h6{
  font-size:12px;
  line-height:20px;
}

.ft-primary{
  font-size:20px;
  line-height:32px;
}

.ft-secondary{
  font-size:17px;
  line-height:24px;
}

.ft-secondary-32{
  font-size:17px;
  line-height:32px;
}

.ft-tertiary{
  font-size:12px;
  line-height:16px;
}

.ft-up{
  text-transform:uppercase;
}

.ft-center{
  text-align:center;
}

.ft-italic{
  font-style:italic;
}

.text-inline{
  white-space:nowrap;
}

.ft-right{
  float:right;
}

.ft-300{
  font-weight:300;
}

.ft-400{
  font-weight:400;
}

.ft-500{
  font-weight:500;
}

.ft-700{
  font-weight:700;
}

.ft-truncat{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.ft-expo{
  display:inline-block;
}

.ft-expo span{
  float:right;
  margin-top:4px;
  margin-left:4px;
  font-size:8px;
  line-height:7px;
  font-family:Din;
  color:rgba(41, 41, 41, .64);
}

.ft-black{
  color:#292929;
}

.ft-black-secondary{
  color:#999;
}

.ft-black-tertiary{
  color:#ccc;
}

.ft-white-secondary{
  color:hsla(0, 0%, 100%, .8);
}

.ft-white-tertiary{
  color:hsla(0, 0%, 100%, .6);
}

.ft-white-quaternary{
  color:hsla(0, 0%, 100%, .32);
}

.ft-color-tertiary{
  color:#7d93a8;
}

.ft-red{
  color:#f2362f;
}

.ft-white{
  color:#fff;
}

.icon{
  position:relative;
  display:inline-block;
  vertical-align:middle;
  font-family:icon;
  font-size:14px;
  font-weight:400;
  line-height:1;
  text-rendering:auto;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-align:center;
  font-style:normal;
  speak:none;
}

.icon.is-inline{
  margin-right:8px;
}

.icon-messenger:before{
  content:"\e955";
}

.icon-whatsapp:before{
  content:"\e956";
}

.icon-animations:before{
  content:"\e94d";
}

.icon-concours:before{
  content:"\e94e";
}

.icon-hotesses:before{
  content:"\e94f";
}

.icon-reunions:before{
  content:"\e950";
}

.icon-exp:before{
  content:"\e94c";
}

.icon-car:before{
  content:"\e948";
}

.icon-food:before{
  content:"\e949";
}

.icon-gift:before{
  content:"\e94a";
}

.icon-walk:before{
  content:"\e94b";
}

.icon-star:before{
  content:"\e946";
}

.icon-check:before{
  content:"\e944";
}

.icon-dashboard:before{
  content:"\e945";
}

.icon-ball:before{
  content:"\e942";
}

.icon-cage:before{
  content:"\e943";
}

.icon-shirt:before{
  content:"\e940";
}

.icon-substitution:before{
  content:"\e941";
}

.icon-record:before{
  content:"\e93b";
}

.icon-alert:before{
  content:"\e93c";
}

.icon-camera:before{
  content:"\e93d";
}

.icon-mail-border:before{
  content:"\e93a";
}

.icon-mail-border2:before{
  content:"\e958";
}

.icon-store:before{
  content:"\e937";
}

.icon-success:before{
  content:"\e935";
}

.icon-chevron-down:before,.icon-chrevron-down:before{
  content:"\e928";
}

.icon-chevron-up:before,.icon-chrevron-up:before{
  content:"\e929";
}

.icon-arrow-left:before{
  content:"\e901";
}

.icon-arrow-right:before{
  content:"\e902";
}

.icon-arrow-triangle-bottom:before{
  content:"\e906";
}

.icon-arrow-triangle-up:before{
  content:"\e914";
}

.icon-articles-empty:before{
  content:"\e920";
}

.icon-chevron-left:before{
  content:"\e923";
}

.icon-chevron-right:before{
  content:"\e924";
}

.icon-jersey-empty:before{
  content:"\e925";
}

.icon-menu:before{
  content:"\e926";
}

.icon-publications:before{
  content:"\e927";
}

.icon-account:before{
  content:"\e900";
}

.icon-calendar-empty:before{
  content:"\e903";
}

.icon-calendar:before{
  content:"\e904";
}

.icon-case:before{
  content:"\e905";
}

.icon-clock:before{
  content:"\e907";
}

.icon-close:before{
  content:"\e908";
}

.icon-close_alt:before{
  content:"\e938";
}

.icon-delete:before{
  content:"\e909";
}

.icon-documents:before{
  content:"\e90a";
}

.icon-download:before{
  content:"\e90b";
}

.icon-external:before{
  content:"\e90c";
}

.icon-facebook:before{
  content:"\e90d";
}

.icon-geoloc:before{
  content:"\e90e";
}

.icon-info:before{
  content:"\e90f";
}

.icon-instagram:before{
  content:"\e910";
}

.icon-jersey:before{
  content:"\e911";
}

.icon-list:before{
  content:"\e912";
}

.icon-mail:before{
  content:"\e913";
}

.icon-phone:before{
  content:"\e915";
}

.icon-pj:before{
  content:"\e916";
}

.icon-place:before{
  content:"\e917";
}

.icon-search:before{
  content:"\e918";
}

.icon-share:before{
  content:"\e919";
}

.icon-shield:before{
  content:"\e91a";
}

.icon-site:before{
  content:"\e91b";
}

.icon-trophy:before{
  content:"\e91c";
}

.icon-twitter:before{
  content:"\e91d";
}

.icon-valid:before{
  content:"\e91e";
}

.icon-youtube:before{
  content:"\e91f";
}

.icon-zoom_moins:before{
  content:"\e921";
}

.icon-zoom_plus:before{
  content:"\e922";
}

.icon-sync:before{
  content:"\e92a";
}

.icon-shield-alt:before{
  content:"\e92b";
}

.icon-arrow-down:before{
  content:"\e92c";
}

.icon-arrow-up:before{
  content:"\e92d";
}

.icon-coment-fb:before{
  content:"\e92e";
}

.icon-coment-insta:before{
  content:"\e92f";
}

.icon-like-fb:before{
  content:"\e930";
}

.icon-like-insta:before{
  content:"\e931";
}

.icon-rt:before{
  content:"\e932";
}

.icon-play:before{
  content:"\e933";
}

.icon-phone2:before{
  content:"\e936";
}

.icon-phone3:before{
  content:"\e957";
}

.icon-ticket:before{
  content:"\e939";
}

.icon-favorite:before{
  content:"\e93e";
}

.icon-logout:before{
  content:"\e93f";
}

.icon-drop:before{
  content:"\e934";
}

.icon-link:before{
  content:"\e947";
}

.icon-view:before{
  content:"\e951";
}

.icon-flickr:before{
  content:"\e952";
}

.icon-exclamation:before{
  content:"\e953";
}

.icon-repair:before{
  content:"\e954";
}

.icon-linkedin:before{
  content:"\e959";
}

.icon-plus:before{
  content:"+";
}

.icon-less:before{
  content:"-";
}
.icon-menu.is-open:before{
  content:"\e938";
}

.icon-play--circle{
  width:48px;
  height:48px;
  line-height:48px;
  border-radius:100%;
  background:#fff;
  color:#7d93a8;
}

.icon-play--circle:before{
  position:relative;
  left:2px;
}

.icon-play--border{
  width:60px;
  height:60px;
  line-height:56px;
  border-radius:100%;
  border:2px solid hsla(0, 0%, 100%, .32);
  color:#fff;
}

.icon-play--border:before{
  position:relative;
  left:2px;
}

.icon-calendar{
  top:-1px;
}

.circle-icon{
  position:relative;
  width:80px;
  height:80px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  border-width:4px;
  border-style:solid;
  border-radius:100%;
}

.circle-icon:before{
  content:"";
  display:block;
  position:absolute;
  width:100%;
  height:100%;
  border-radius:100%;
  opacity:.08;
}

.circle-icon:after{
  font-family:icon;
}

.circle-icon.circle-icon--check{
  border-color:#2ecc71;
}

.circle-icon.circle-icon--check:before{
  background-color:#2ecc71;
}

.circle-icon.circle-icon--check:after{
  content:"\e935";
  color:#2ecc71;
}

.circle-icon.circle-icon--blue{
  border-color:#e6679a;
}

.circle-icon.circle-icon--blue:after{
  content:"\e935";
  color:#e6679a;
}

.circle-icon.circle-icon--star{
  border-color:#ffb200;
}

.circle-icon.circle-icon--star:before{
  background-color:#ffb200;
}

.circle-icon.circle-icon--star:after{
  content:"\e946";
  font-size:22px;
  color:#ffb200;
}

.circle-icon.circle-icon--alert{
  border-color:#f2362f;
}

.circle-icon.circle-icon--alert:before{
  background-color:#f2362f;
}

.circle-icon.circle-icon--alert:after{
  content:"\e93c";
  font-size:22px;
  color:#f2362f;
}

.icon--red{
  color:#f2362f;
}

.btn{
  display:inline-block;
  vertical-align:middle;
  height:48px;
  min-width:48px;
  line-height:48px;
  border-radius:4px;
  border:0;
  font-size:14px;
  font-weight:500;
  -webkit-transition:all .15s ease-out;
  transition:all .15s ease-out;
  cursor:pointer;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  color:#fff;
  text-align:center;
  outline:none;
}

.btn:focus,.btn:hover{
  color:#fff;
  text-decoration:none;
}

.btn:focus{
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.btn.is-disabled,.btn[disabled]{
  pointer-events:none;
  background:#fafafa;
  color:#ccc;
  -webkit-box-shadow:0 0 0 1px rgba(0, 0, 0, .12);
          box-shadow:0 0 0 1px rgba(0, 0, 0, .12);
}

.btn.accordion-trigger:after{
  display:none;
}

.btn--competition,.btn--mail,.btn--primary,.btn--red,.btn--social{
  background-image:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(rgba(0, 35, 72, .06)));
  background-image:linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, rgba(0, 35, 72, .06));
}

.btn--competition:hover,.btn--mail:hover,.btn--primary:hover,.btn--red:hover,.btn--social:hover{
  background-image:-webkit-gradient(linear, left bottom, left top, from(hsla(0, 0%, 100%, 0)), to(rgba(0, 35, 72, .06)));
  background-image:linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0, rgba(0, 35, 72, .06));
}

.btn--competition:focus,.btn--mail:focus,.btn--primary:focus,.btn--red:focus,.btn--social:focus{
  background-image:none;
}

.btn--primary{
  white-space:nowrap;
  padding:0 32px;
  background-color:#e6679a;
}

.btn--primary:focus{
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
}

.btn--primary i{
  opacity:.48;
  top:-1px;
}

.btn--primary .icon-list{
  font-size:10px;
}

.btn--gold,.btn--gold:focus{
  background-color:#e8a801;
}

.btn--social.btn--primary{
  background-color:#2a9bff !important;
  color:#fff !important;
}

.btn--linkedin{
  background-color:#0a66c2 !important;
  color:#fff !important;
}

.btn--linkedin:focus{
  background-color:#043669 !important;
}

.btn--twitter{
  background-color:#4fadf5 !important;
  color:#fff !important;
}

.btn--twitter:focus{
  background-color:#4190cc !important;
}

.btn--facebook{
  background-color:#3a5798 !important;
  color:#fff !important;
}

.btn--facebook:focus{
  background-color:#2b4170 !important;
}

.btn--mail{
  color:#fff !important;
}

.btn--mail,.btn--mail:focus{
  background-color:#e6679a !important;
}

.btn--messenger,.btn--messenger:focus{
  background:#0084ff !important;
  color:#fff !important;
  background-image:-webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 35, 72, .05))) !important;
  background-image:linear-gradient(180deg, transparent, rgba(0, 35, 72, .05)) !important;
}

.btn--whatsapp,.btn--whatsapp:focus{
  background:#25d366 !important;
  color:#fff !important;
  background-image:-webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 35, 72, .05))) !important;
  background-image:linear-gradient(180deg, transparent, rgba(0, 35, 72, .05)) !important;
}

.btn--instagram,.btn--instagram:focus,.btn--instagram:hover{
  color:#fff !important;
  background-image:radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d)) !important;
  background-image:radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d) !important;
}

.btn--social{
  white-space:nowrap;
  width:220px;
  height:56px;
  min-width:56px;
  line-height:56px;
  padding:0;
}

.btn--social i{
  opacity:.48;
  position:relative;
  top:-1px;
}

.btn--social.btn--medium,.btn--social.btn--small{
  width:24px;
  height:24px;
  min-width:auto;
  line-height:24px;
  border-radius:2px;
}

.btn--social.btn--medium i,.btn--social.btn--small i{
  opacity:1;
  font-size:14px;
}

.btn--social.btn--medium{
  width:32px;
  height:32px;
  line-height:32px;
}

.btn--white{
  background-color:#fff;
  color:#292929;
  border:1px solid #ebebeb;
  padding:0 24px;
}

.btn--white i{
  color:#7d93a8;
}

.btn--white .icon-camera,.btn--white .icon-record{
  color:#f2362f;
  font-size:10px;
}

.btn--white:focus,.btn--white:focus i,.btn--white:hover,.btn--white:hover i,a.card:hover .btn--white,a.card:hover .btn--white i{
  color:#e6679a;
}

.btn--youtube:focus,.btn--youtube:focus .icon,.btn--youtube:hover,.btn--youtube:hover .icon{
  color:#f2362f;
}

.btn--youtube .icon{
  color:#f2362f;
  top:-1px;
}

.btn--red{
  background-color:#f2362f;
  padding:0 24px;
}

.btn--red .icon{
  top:-1px;
}

.btn--dark{
  color:#fff;
}

.btn--dark .link-icon{
  border-color:hsla(0, 0%, 100%, .32);
}

.btn--dark .link-icon:focus,.btn--dark .link-icon:hover,.btn--dark i{
  color:#fff;
}

.btn--dark i{
  font-size:10px;
}

.btn--competition{
  position:relative;
  height:auto;
  line-height:22px;
  background-color:#004c93;
  padding:10px 60px 10px 24px;
  text-align:left;
}

.btn--competition i{
  position:absolute;
  right:24px;
  top:50%;
  margin-top:-7px;
  opacity:.48;
}

.btn--cheer.btn--white{
  border:inherit;
}

.btn--cheer.btn--white i{
  background:transparent;
}

.btn--cheer.btn--white:hover i{
  background:rgba(230, 103, 154, .06);
}

.btn--cheer{
  position:relative;
  padding-left:56px;
}

.btn--cheer,.btn--cheer:hover,.card:not(.has-siblings):hover .btn--cheer{
  -webkit-box-shadow:none;
          box-shadow:none;
}

.btn--cheer:hover i,.card:not(.has-siblings):hover .btn--cheer i{
  background-color:rgba(230, 103, 154, .32);
}

.btn--cheer:hover .is-dark,.card:not(.has-siblings):hover .btn--cheer .is-dark{
  background:none;
  border-color:#fff;
}

.btn--cheer .btn--cheer.has-siblings{
  background-color:#fff;
  color:#292929;
  height:61px;
  text-align:left;
}

.btn--cheer .btn--cheer.has-siblings .icon{
  left:auto;
  right:0;
  background-color:transparent;
  border:0;
  color:#999;
}

.btn--cheer .btn--cheer.has-siblings span{
  line-height:61px;
  text-align:left;
}

.btn--cheer .btn--full.has-siblings{
  padding:0 24px;
  font-size:17px;
}

.btn--cheer .btn--full.has-siblings:hover>.icon{
  color:#e6679a;
}

.btn--cheer span{
  display:inline-block;
  vertical-align:middle;
  text-align:left;
  line-height:20px;
}

.btn--cheer i{
  font-size:10px;
  color:#e6679a;
  border-color:#e6679a;
  border-radius:100%;
  background-color:rgba(230, 103, 154, .06);
  position:absolute;
  top:50%;
  left:0;
  margin-top:-22px;
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
}

.btn--cheer i:before{
  margin-left:1px;
}

.btn--cheer i.is-dark{
  background:none;
}

.btn--transparent{
  white-space:nowrap;
  padding:0 32px;
  -webkit-box-shadow:0 0 0 1px inset hsla(0, 0%, 100%, .32);
          box-shadow:inset 0 0 0 1px hsla(0, 0%, 100%, .32);
}

.btn--transparent i{
  opacity:.4;
}

.btn--icon{
  width:32px;
  height:32px;
  min-width:auto;
  line-height:28px;
  -webkit-box-shadow:none;
          box-shadow:none;
  border:2px solid #ebebeb;
  border-radius:4px;
  text-align:center;
  background-color:#fff;
  color:#999;
}

.btn--icon i{
  font-size:10px;
  vertical-align:unset;
}

.btn--icon.is-disabled{
  opacity:.24;
  cursor:default;
}

.btn--icon.is-active{
  background-color:rgba(230, 103, 154, .12);
}

.accordion-trigger:hover .btn--icon,.btn--icon.is-active,.btn--icon:focus,.btn--icon:hover{
  border-color:#e6679a;
  color:#e6679a;
}

.btn--icon.is-dark{
  color:#fff;
  background-color:hsla(0, 0%, 100%, .06);
  border-color:hsla(0, 0%, 100%, .32);
}

.btn--icon.is-dark:hover{
  border-color:#fff;
}

.btn--icon.btn--medium{
  width:40px;
  height:40px;
  line-height:36px;
}

.btn--icon.btn--medium i{
  font-size:12px;
}

.btn--full{
  width:100%;
  padding:0 16px;
}

.btn--xs{
  padding:0 12px;
  line-height:28px;
  height:32px;
  min-height:32px;
  border:2px solid #ebebeb;
}

.btn--xs.is-active,.btn--xs:focus,.btn--xs:hover{
  outline:0;
  border-color:#e6679a;
  color:#e6679a;
}

.btn--big{
  height:80px;
  line-height:80px;
}

.btn--edit{
  border:1px solid rgba(41, 41, 41, .12);
  -webkit-box-shadow:none;
          box-shadow:none;
  background-image:-webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f6f7));
  background-image:linear-gradient(180deg, #fff, #f5f6f7);
  white-space:nowrap;
}

@media (min-width:768px){
  .btn--competition{
    padding-left:16px;
    padding-right:40px;
  }
  .btn--competition i{
    right:16px;
  }
}

@media (min-width:1280px){
  .btn--competition{
    padding-left:24px;
    padding-right:60px;
  }
  .btn--competition i{
    right:24px;
  }
}

.link-chevron{
  display:block;
  font-weight:500;
  font-size:14px;
  line-height:22px;
}

.link-chevron.has-siblings:not(:last-child){
  margin-bottom:8px;
}

.link-chevron:hover,a.card:hover .link-chevron:not(.has-siblings){
  text-decoration:none;
  color:#e6679a;
}

.link-chevron:hover i,a.card:hover .link-chevron:not(.has-siblings) i{
  color:#e6679a;
}

.link-chevron i{
  float:right;
  color:#7d93a8;
  font-size:10px;
  line-height:22px;
  -webkit-transition:color .2s;
  transition:color .2s;
}

.link-chevron .icon-zoom_plus{
  font-size:8px;
}

.link-chevron .icon-external{
  font-size:12px;
}

.link-chevron.accordion-trigger:after{
  display:none;
}

.link-chevron.is-open .icon-zoom_plus:before{
  content:"\e921";
}

.link-chevron--right{
  float:right;
}

.link-chevron--right i{
  margin-left:16px;
}

.link-chevron--left i{
  float:left;
  margin-right:16px;
}

.link-chevron--block{
  padding:16px 24px;
}

.link-chevron--inline i{
  float:none;
  margin-left:8px;
}

.link-chevron--center{
  text-align:center;
}

.link-chevron--alt{
  color:#e6679a;
}

.card:hover .link-chevron--white,.card:hover .link-chevron--white i,.link-chevron--white,.link-chevron--white:hover,.link-chevron--white:hover i{
  color:#fff;
}

.link-chevron--blue{
  color:#004c93;
}

.link-chevron--din{
  font-family:Din;
  font-weight:700;
}

.link-chevron--desktop{
  display:block;
}

.link-chevron--desktop i{
  float:right;
}

.link-icon{
  display:inline-block;
  width:48px;
  height:48px;
  line-height:44px;
  text-align:center;
  border:2px solid #ccc;
  border-radius:100%;
  color:#999;
  margin-right:16px;
  cursor:pointer;
}

.link-icon:hover{
  background-color:hsla(0, 0%, 80%, .16);
  color:#999;
}

.link-icon.is-dark{
  border-color:hsla(0, 0%, 100%, .32);
  color:#fff;
}

.link-icon.is-dark:hover,.news:hover .link-icon.is-dark{
  border-color:#fff;
  background:none;
  color:#fff;
}

.link-icon.link-icon--large{
  width:60px;
  height:60px;
  line-height:56px;
}

.link-icon.link-icon--large i{
  font-size:12px;
}

.link-icon.link-icon--medium{
  width:36px;
  height:36px;
  line-height:32px;
}

.link-icon.link-icon--medium i{
  font-size:12px;
}

.link-icon.link-icon--small{
  width:32px;
  height:32px;
  line-height:28px;
  border-width:1px;
}

.link-icon.link-icon--small i{
  font-size:12px;
}

.link-icon.link-icon--xsmall{
  width:24px;
  height:24px;
  line-height:20px;
}

.link-icon.link-icon--xsmall i{
  font-size:10px;
}

.link-icon .icon-facebook{
  top:-1px;
}

.link-icon .icon-twitter{
  top:-1px;
  right:-1px;
}

.link-icon .icon-instagram,.link-icon .icon-youtube{
  top:-1px;
}

.link-icon .icon-play{
  left:1px;
  top:-1px;
}

.link-icon--white{
  border-color:#fff;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.link-icon--white,.link-icon--white:hover{
  background-color:#fff;
  color:#7d93a8;
}

.link-competition{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  position:relative;
  padding-right:24px;
  margin-bottom:16px;
}

.link-competition:last-child{
  margin-bottom:0;
}

.link-competition:hover{
  text-decoration:none;
}

.link-competition:hover .link-competition__title{
  text-decoration:underline;
}

.link-competition:hover i{
  color:#e6679a;
}

.link-competition i{
  position:absolute;
  right:16px;
  top:50%;
  margin-top:-12px;
}

.link-competition img{
  margin-right:16px;
}

.link-competition__title{
  display:block;
}

.link-competition__title i{
  float:right;
  color:#7d93a8;
  font-size:10px;
  line-height:24px;
}

.link-competition__count{
  color:#e6679a;
}

.link-competition__empty{
  color:#999;
}

.link-competition--card{
  background-color:#fff;
  border-radius:4px;
  padding:16px 32px 16px 16px;
  margin-bottom:8px;
}

.link-competition--card:before{
  content:"";
  position:absolute;
  top:12px;
  bottom:12px;
  left:0;
  border-left:2px solid #e6679a;
}

.link-competition--access{
  border:1px solid #ebebeb;
}

.link-competition--access i{
  font-size:14px;
}

.link-club{
  display:inline-block;
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
  background-color:#e6679a;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  border-radius:4px;
  padding:8px;
  font-size:17px;
  font-weight:700;
  font-family:Din;
}

.link-club:focus,.link-club:hover{
  text-decoration:none;
  color:inherit;
}

.link-club:hover{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.link-club img{
  margin-right:8px;
}

.link-club--blue{
  background-color:#280b0d;
}

.link-club-alt{
  display:inline-block;
  font-size:12px;
  font-weight:700;
  font-family:Din;
  text-transform:uppercase;
}

.link-club-alt img{
  border-radius:100%;
  margin-right:16px;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  width:52px;
  height:52px;
  padding:12px;
  background-color:#fff;
}

.link-club-alt:hover{
  color:inherit;
}

.link-club-alt:hover img{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.link-blur{
  position:relative;
}

.link-blur:before{
  content:"";
  display:block;
  position:absolute;
  bottom:100%;
  left:0;
  background:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
  background:linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
  height:104px;
  width:100%;
}

.link-blur--blueAlt:before{
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(40, 11, 13, 0)), color-stop(69%, #280b0d));
  background:linear-gradient(180deg, rgba(40, 11, 13, 0) 0, #280b0d 69%);
}

.link-chevron--pagination{
  padding:16px 0;
}

@media (min-width:768px){
  .link-club{
    font-size:15px;
  }
  .link-chevron--player{
    max-width:220px;
    display:inline-block;
  }
  .link-chevron--player i{
    top:-10px;
  }
  .link-chevron--desktop{
    display:inline-block;
  }
  .link-chevron--desktop i{
    margin-left:16px;
  }
}

@media (min-width:1280px){
  .link-chevron--player{
    max-width:none;
    display:initial;
  }
  .link-chevron--player i{
    top:auto;
  }
  .link-chevron--desktop{
    position:absolute;
    display:block;
    top:3px;
    right:0;
  }
}

.tag{
  display:inline-block;
  vertical-align:middle;
  padding:0 12px;
  height:32px;
  line-height:34px;
  font-size:12px;
  font-family:Din;
  font-weight:700;
  color:#e6679a;
  -webkit-transition:all .15s ease-out;
  transition:all .15s ease-out;
  max-width:400px;
  border-radius:4px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  -webkit-box-shadow:0 0 0 1px #e6679a;
          box-shadow:0 0 0 1px #e6679a;
  text-transform:uppercase;
}

.tag.is-active,a.tag:focus,a.tag:hover{
  text-decoration:none;
  background-color:#e6679a;
  color:#fff;
}

.tag--blue{
  -webkit-box-shadow:none;
          box-shadow:none;
  color:#fff;
}

.tag--blue.is-active,.tag--blue:focus,.tag--blue:hover{
  background-color:#e6679a;
}

.label,.tag--small{
  border-radius:2px;
  height:24px;
  line-height:26px;
  padding:0 8px;
}

.label{
  position:relative;
  display:inline-block;
  vertical-align:middle;
  font-family:Din;
  font-size:12px;
  font-weight:700;
  color:#fff;
  background-color:#e6679a;
  max-width:200px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  text-transform:uppercase;
}

.label i{
  font-size:12px;
  line-height:2;
  vertical-align:baseline;
  margin-right:6px;
  opacity:.4;
}

.label .icon-youtube{
  font-size:14px;
  line-height:24px;
}

a.label:focus,a.label:hover{
  color:hsla(0, 0%, 100%, .8);
  text-decoration:none;
}

a.label:hover{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

a.label--blue-dark:focus,a.label--blue-dark:hover{
  background-color:#001831;
  color:#fff;
}

.label--rounded{
  border-radius:12px;
}

.label--icon{
  padding:0;
  width:24px;
  text-align:center;
  font-weight:400;
}

.label--icon i{
  margin-right:0;
  opacity:1;
}

.label--primary-focus{
  background-color:#e6679a;
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
}

.label--small{
  height:15px;
  line-height:17px;
  font-size:10px;
  padding:0 4px;
}

.label--grey{
  background-color:#ebebeb;
  color:#999;
}

.label--silver{
  background-color:#ccc;
  color:#fff;
}

.label--blue{
  background-color:#7d93a8;
}

.label--blue-alt{
  background-color:#3d5975;
  color:hsla(0, 0%, 100%, .8);
}

.label--blue-dark{
  background-color:#280b0d;
  color:#fff;
}

.label--azure{
  background-color:#33a5dd;
}

.label--red{
  background-color:#f2362f;
  color:#fff;
}

.label--transparent{
  border-radius:4px;
  background-color:hsla(0, 0%, 100%, .15);
  border:2px solid #fff;
  height:28px;
  line-height:22px;
  font-size:10px;
}

.label--circle{
  border-radius:50%;
  width:32px;
  height:32px;
  padding:0;
  position:relative;
}

@media (min-width:768px){
  .label--circle{
    width:28px;
    height:28px;
  }
}

.label--circle i{
  opacity:1;
  margin:0;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  font-size:13px;
}

@media (min-width:768px){
  .label--circle i{
    font-size:13px;
  }
}

.label--live{
  position:relative;
  padding-left:24px;
}

.label--live:before{
  position:absolute;
  content:"";
  left:8px;
  top:9px;
  width:7px;
  height:7px;
  border-radius:50%;
  background-color:#fff;
}

.box{
  overflow:hidden;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  border-radius:4px;
  -webkit-transition:-webkit-box-shadow .3s ease;
  transition:-webkit-box-shadow .3s ease;
  transition:box-shadow .3s ease;
  transition:box-shadow .3s ease,-webkit-box-shadow .3s ease;
  background-color:#fff;
}

.box.events--game{
  overflow:initial;
}

.box .card__thumbnail:after,.box .card__thumbnail:before{
  border-radius:4px;
  -webkit-border-radius:4px;
}

a.box:hover{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
  text-decoration:none;
}

.box--white{
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08), 0 0 0 1px #ebebeb;
          box-shadow:0 3px 8px rgba(51, 51, 51, .08),0 0 0 1px #ebebeb;
}

a.box--white:hover{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08), 0 0 0 1px #ebebeb;
          box-shadow:0 10px 16px rgba(51, 51, 51, .08),0 0 0 1px #ebebeb;
}

.box--content{
  padding:24px;
}

.box--grey{
  background-color:#f7f7f7;
}

.box--grey,.box--smoke{
  -webkit-box-shadow:0 0 0 1px #ebebeb;
          box-shadow:0 0 0 1px #ebebeb;
}

.box--smoke{
  background-color:#fcfcfc;
}

.box--blue-light{
  background-color:#f4f9fe;
  border:1px solid #e3ecf4;
}

.box--blue-light .link-icon{
  color:#280b0d;
  border-color:#280b0d;
}

.box--green{
  background-color:rgba(80, 183, 72, .1);
  border:1px solid rgba(80, 183, 72, .1);
}

.box--green p,.box--green span{
  color:#32812c;
}

.box--green .link-icon{
  color:#32812c;
  border-color:#32812c;
}

.box--blue{
  background-color:#280b0d;
  color:#fff;
}

a.box--grey:hover,a.box--smoke:hover{
  -webkit-box-shadow:0 0 0 1px #ebebeb, 0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 0 0 1px #ebebeb,0 10px 16px rgba(51, 51, 51, .08);
}

.accordion-trigger:after{
  font-family:icon;
  content:"\e928";
  float:right;
  font-size:6px;
  line-height:32px;
}

.accordion-trigger.is-selected:after{
  content:"\e929";
}

.accordion-trigger.is-selected .icon-zoom_plus:before{
  content:"\e921";
}

.accordion-trigger:focus,.accordion-trigger:hover{
  text-decoration:none;
}

.accordion-panel{
  opacity:1;
  -webkit-transition:opacity .5s ease,max-height .5s ease,visibility .5s ease;
  transition:opacity .5s ease,max-height .5s ease,visibility .5s ease;
  visibility:visible;
}

.accordion-panel.is-hidden{
  max-height:0;
  opacity:0;
  overflow:hidden;
  visibility:hidden;
}

.section{
  position:relative;
  padding-top:48px;
  padding-bottom:48px;
}

.container .section__link:first-child,.section .section__link:first-child{
  display:none;
}

.container .section__link--zIndex,.section .section__link--zIndex{
  position:relative;
  z-index:2;
}

.section-blueAlt,.section-dark{
  background-color:#280b0d;
  color:#fff;
}

.section-white{
  background-color:#fff;
}

.section-black{
  background-color:#04182d;
  color:#fff;
}

.section--bg{
  color:#fff;
}

.section__thumbnail{
  position:relative;
  top:48px;
  width:calc(100% + 40px);
  max-width:none;
  margin-left:-20px;
}

.section.has-half-bloc{
  padding-bottom:48px;
}

.section.has-half-bloc .card--hub{
  -webkit-transform:translateY(102px);
          transform:translateY(102px);
}

.section.half-card-hub{
  padding-top:170px;
  margin-top:-170px;
}

.section--carousel{
  height:251px;
  margin-bottom:180px;
}

@media (min-width:768px){
  .section{
    padding-bottom:56px;
  }
  .container .section__link:first-child,.section .section__link:first-child{
    display:block;
  }
  .container .section__link:last-child,.section .section__link:last-child{
    display:none;
  }
  .section__thumbnail{
    top:56px;
  }
  .section--carousel{
    height:403px;
    margin-bottom:220px;
  }
  .section.half-card-hub{
    padding-top:160px;
  }
  .section.has-half-bloc{
    padding-bottom:0;
    margin-bottom:110px;
  }
  .section.has-half-bloc .card--hub-alt{
    -webkit-transform:translateY(102px);
            transform:translateY(102px);
  }
}

@media (min-width:1280px){
  .section{
    padding-top:80px;
    padding-bottom:80px;
  }
  .section__thumbnail{
    position:absolute;
    top:-105px;
    right:0;
    width:100%;
    max-width:600px;
  }
  .section.has-half-bloc{
    margin-bottom:103px;
  }
  .section.live-feed{
    padding:100px 0;
  }
  .section--carousel{
    height:568px;
    margin-bottom:200px;
  }
  .section.half-card-hub{
    padding-top:210px;
    margin-top:-200px;
  }
}

.section--projects-wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  height:424px;
}

.section--projects-wrapper .card--linkContent{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
}

.section--projects-wrapper .card--linkContent:nth-child(2){
  margin-top:16px;
}

.section--projects-wrapper .card--linkContent .card__body{
  height:100%;
}

.section--partners{
  display:none;
}

.section--partners .title-wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

@media (min-width:1280px){
  .section--partners{
    display:block;
    padding-top:96px;
    padding-bottom:96px;
  }
}
.content-bloc .has-half-bloc{
  padding-bottom:192px !important;
}

.content-bloc .has-half-bloc+section{
  margin-top:-96px !important;
  padding-top:0 !important;
  padding-bottom:16px;
}

.content-bloc>section{
  max-width:unset;
  padding-left:unset;
  padding-right:unset;
}

.content-bloc>section>:not(.container){
  max-width:1680px;
  margin-left:auto;
  margin-right:auto;
  padding-left:68px;
  padding-right:100px;
}

.content-bloc section.section-white{
  padding-bottom:96px;
  padding-top:96px;
  padding-bottom:72px;
  margin-bottom:0 !important;
}

@media (max-width:1280px){
  .content-bloc>section>:not(.container){
    padding-left:unset;
    padding-right:16px;
  }
}

.news{
  position:relative;
  display:block;
  margin-bottom:24px;
  font-family:Din;
}

.news:focus,.news:hover{
  text-decoration:none;
  color:inherit;
}

.news:focus .news__title,.news:hover .news__title{
  text-decoration:underline;
}

.news:focus .img-blue:before,.news:hover .img-blue:before{
  opacity:.4;
}

.news img{
  display:block;
}

.news p{
  margin:0;
}

.news .img-blue:before{
  opacity:0;
}

.news__cat{
  color:#e6679a;
  margin-right:8px;
  text-decoration:none;
  display:inline-block;
}

.news__title{
  display:block;
  margin-bottom:4px;
}

.news__date{
  color:#ccc;
  display:inline-block;
}

.news__thumbnail{
  position:relative;
  height:144px;
  background:no-repeat 50%;
  background-size:cover;
  margin-bottom:8px;
}

.news__thumbnail img{
  display:none;
}

.news--row article,.news--score-thumbnail article{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

.news--row .news__thumbnail,.news--score-thumbnail .news__thumbnail{
  position:relative;
  width:80px;
  height:80px;
  margin-left:20px;
  margin-bottom:0;
  -webkit-box-ordinal-group:3;
      -ms-flex-order:2;
          order:2;
  -ms-flex-negative:0;
      flex-shrink:0;
}

.news--row.mb-0,.news--score-thumbnail.mb-0{
  margin-bottom:0;
}

.news--score article{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
}

.news--score .news__thumbnail{
  position:absolute;
  right:16px;
  top:16px;
}

.news--score .news__title{
  margin-bottom:16px;
}

.news--score .match-context,.news--score .news__title{
  width:calc(100% - 90px);
}

.news--score .score{
  min-width:100%;
}

.news--list{
  border-bottom:1px solid #ebebeb;
  padding-bottom:24px;
}

.news--list:after{
  content:" ";
  display:table;
  clear:both;
}

.news--list .news__thumbnail{
  position:relative;
  width:111px;
  height:76px;
  margin-right:12px;
  float:left;
}

.news--list .news__title{
  margin-left:123px;
}

.news--list .news__desc{
  color:#999;
  clear:both;
  padding-bottom:24px;
  margin-top:8px;
}

.news--list .label{
  margin-bottom:4px;
}

.news--list .match.box,.news--search .match.box{
  max-width:500px;
  border:1px solid #eee;
  margin-top:8px;
  min-height:43px;
  padding:8px 16px;
}

.news--list .match.box img,.news--search .match.box img{
  margin-top:-12px;
}

.news--list .match.box .match__club:first-child,.news--search .match.box .match__club:first-child{
  padding-right:38px;
  text-align:center;
}

.news--list .match.box .match__club:last-child,.news--search .match.box .match__club:last-child{
  padding-left:42px;
  text-align:center;
}

.news--search .news__desc{
  padding-bottom:0;
}

.news--search .news__date{
  position:static;
  margin-left:123px;
  margin-top:8px;
}

.news--big{
  margin-bottom:24px;
}

.news--big .news__thumbnail{
  position:relative;
  width:100%;
  height:224px;
  margin-bottom:16px;
}

.news--listSmall{
  border-bottom:0;
  padding-bottom:0;
}

.news--listSmall .news__date,.news--listSmall .news__title{
  margin-left:0;
}

.news--listSmall .news__date{
  position:inherit;
}

.news--listSmall .news__thumbnail{
  width:90px;
  height:60px;
}

.news--listSmall article:hover .img-blue:before{
  opacity:.4;
}

.news--listSmall div:last-child{
  display:inline-block;
  width:calc(100% - 122px);
  border-bottom:1px solid #ebebeb;
  padding-bottom:15px;
}

.news--video .news__thumbnail{
  -webkit-box-ordinal-group:1;
      -ms-flex-order:0;
          order:0;
  margin-left:0;
  margin-right:16px;
  width:112px;
  height:84px;
}

.news--video article{
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start;
}

.news--video .link-icon{
  position:absolute;
  top:50%;
  left:50%;
  margin-top:-12px;
  margin-left:-12px;
  z-index:1;
}

.news--video .img-blue-dark:before{
  opacity:0;
}

.news--video .img-blue-dark:after{
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(1, 57, 116, 0)), to(#013974));
  background:linear-gradient(180deg, rgba(1, 57, 116, 0) 0, #013974);
  background-repeat:repeat-y;
  opacity:.82;
}

.news--searchMatch .news__date{
  margin-left:8px;
}

.news--score-thumbnail{
  margin-bottom:0;
  padding:16px 0;
  border-bottom:1px solid #ebebeb;
}

.news--score-thumbnail .news__thumbnail:after{
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(rgba(0, 0, 0, .8)));
  background-image:linear-gradient(180deg, transparent 33%, rgba(0, 0, 0, .8));
}

@media (min-width:768px){
  .news--list .news__desc{
    padding-bottom:0;
  }
  .news--list .news__date{
    position:static;
    margin-top:4px;
    margin-left:5px;
  }
  .news--list .news__thumbnail{
    margin-right:16px;
  }
  .news--list .news__title{
    margin-left:132px;
  }
  .news--search{
    border-bottom:0;
    padding-bottom:0;
  }
  .news--search .news__content{
    min-height:120px;
    margin-left:196px;
    border-bottom:1px solid #ebebeb;
    padding-bottom:24px;
    padding-top:16px;
  }
  .news--search .news__date,.news--search .news__title{
    margin-left:0;
  }
  .news--search .news__date{
    margin-top:8px;
  }
  .news--search .news__desc{
    clear:none;
  }
  .news--search .news__thumbnail{
    width:180px;
    height:120px;
    margin-bottom:0;
  }
  .news--search .label{
    margin-bottom:8px;
  }
  .news--big{
    margin-bottom:40px;
  }
  .news--big .news__thumbnail{
    height:380px;
  }
  .news--listSmall{
    border-bottom:0;
  }
  .news--listSmall .news__date,.news--listSmall .news__title{
    margin-left:0;
  }
  .news--video .news__thumbnail{
    width:64px;
    height:48px;
  }
  .news--score .news__thumbnail{
    right:24px;
    top:12px;
  }
  .news--searchMatch .news__date{
    margin-left:8px;
    margin-top:0;
    position:relative;
    top:-3px;
  }
  .news.news--score-thumbnail{
    padding-top:0;
    border-bottom:none;
  }
  .news.news--score-thumbnail.has-score .score{
    position:absolute;
    top:0;
    height:188px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    color:#fff;
  }
  .news.news--score-thumbnail.has-score .score .match-context,.news.news--score-thumbnail.has-score .score .match__score{
    color:#fff;
  }
  .news.news--score-thumbnail.has-score .match{
    padding:0 12px 8px;
  }
  .news.news--score-thumbnail article{
    display:block;
  }
  .news.news--score-thumbnail .news__thumbnail{
    height:188px;
    width:100%;
    margin-bottom:8px;
    margin-left:0;
  }
  .news.news--score-thumbnail .match .match__info{
    width:auto;
    padding:0 8px;
  }
}

@media (min-width:1280px){
  .news__thumbnail{
    height:168px;
  }
  .news--row-lg article{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .news--row-lg .news__thumbnail{
    width:80px;
    height:80px;
    margin-left:20px;
    margin-bottom:0;
    -webkit-box-ordinal-group:3;
        -ms-flex-order:2;
            order:2;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .news--list{
    border-bottom:0;
    padding-bottom:0;
  }
  .news--list .news__thumbnail{
    width:180px;
    height:120px;
    margin-right:32px;
    margin-bottom:0;
  }
  .news--list .news__desc{
    margin-top:4px;
    clear:none;
  }
  .news--list .news__content{
    min-height:120px;
    margin-left:212px;
    padding-bottom:24px;
    border-bottom:1px solid #ebebeb;
  }
  .news--list .news__date,.news--list .news__title{
    margin-left:0;
  }
  .news--big{
    overflow:hidden;
  }
  .news--big .news__thumbnail{
    width:498px;
    height:336px;
    float:left;
    margin-bottom:0;
    margin-right:32px;
  }
  .news--big .news__title{
    font-size:30px;
    line-height:36px;
    margin-bottom:16px;
    padding-top:120px;
  }
  .news--big div:last-child{
    min-height:336px;
    margin-left:530px;
    border-bottom:1px solid #ebebeb;
  }
  .news--listSmall .news__thumbnail{
    width:90px;
    height:60px;
  }
  .news--video .news__thumbnail{
    margin-right:16px;
    width:122px;
    height:90px;
  }
  .news--video .link-icon{
    width:36px;
    height:36px;
    line-height:32px;
    margin:-18px 0 0 -18px;
  }
  .news--video .link-icon i{
    font-size:14px;
  }
  .news--searchMatch .news__date{
    margin-left:8px;
  }
}

.bg-overflow{
  margin-bottom:48px;
  padding-bottom:48px;
}

.bg-overflow:after,.bg-overflow:before{
  content:"";
  display:block;
  position:absolute;
  top:0;
  right:0;
  z-index:-1;
  width:300%;
  height:100%;
  background-color:#fff;
}

.bg-overflow:after{
  right:auto;
  left:0;
}

.bg-overflow--dark:after,.bg-overflow--dark:before{
  background-color:#280b0d;
}

@media (min-width:768px){
  .bg-overflow{
    padding-bottom:32px;
    padding-right:16px;
  }
  .bg-overflow:after{
    display:none;
  }
  .bg-overflow--dark{
    margin-bottom:0;
  }
}

@media (min-width:1280px){
  .bg-overflow{
    padding-bottom:80px;
    padding-right:0;
  }
  .bg-overflow--dark{
    padding-right:40px;
  }
}

.img-bg{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:-1;
  background:no-repeat 50%;
  background-size:cover;
}

.img-bg--left{
  background-position:0 0;
}

.img-circle{
  position:relative;
  overflow:hidden;
  width:52px;
  height:52px;
  border-radius:100%;
  border:3px solid #fff;
  background:#fff;
}

.img-circle img{
  position:absolute;
  top:51%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  width:30px;
  height:30px;
}

.img-blue-100:after,.img-blue-100:before,.img-blue-alt:after,.img-blue-alt:before,.img-blue-dark-98:after,.img-blue-dark-98:before,.img-blue-dark:after,.img-blue-dark:before,.img-blue:after,.img-blue:before,.img-primary:after,.img-primary:before,.img-secondary:after,.img-secondary:before{
  content:"";
  position:absolute;
  display:block;
  top:0;
  width:100%;
  height:100%;
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  will-change:opacity;
}

.img-blue:before{
  opacity:.6;
  background-color:#280b0d;
}

.img-blue-100:before{
  opacity:1;
  background-color:#280b0d;
}

.img-blue-alt:before{
  opacity:.92;
  background-color:#280b0d;
}

.img-blue-dark:before{
  opacity:.88;
  background-color:#280b0d;
}

.img-blue-dark-98:before{
  opacity:.98;
  background-color:#013974;
}

.img-secondary:before{
  opacity:.98;
  background-color:#280b0d;
}

.img-primary:before{
  opacity:.9;
  background-color:#e6679a;
}

.quote{
  font-size:24px;
  font-style:italic;
  font-weight:300;
  line-height:32px;
  padding:8px 16px;
  border-left:4px solid #e6679a;
}

.quote .ft-h6{
  display:block;
  font-style:normal;
  color:#999;
  margin-top:16px;
}

@media (min-width:1280px){
  .quote{
    font-size:40px;
    line-height:48px;
    padding-left:32px;
  }
}

.member-group .member{
  margin-bottom:16px;
}

.member-group .member:last-child{
  margin-bottom:0;
}

.member{
  position:relative;
  z-index:1;
}

.member .btn--icon{
  position:absolute;
  right:24px;
  top:50%;
  margin-top:-16px;
}

.member--area .member__info a{
  margin-bottom:4px;
}

.member--area .member__info a:last-child{
  margin-bottom:0;
}

.member--area .member__title{
  margin-bottom:4px;
}

.member__title{
  margin-bottom:0;
}

.member__role{
  color:#999;
  margin-bottom:4px;
}

.member__info{
  color:#999;
  margin-bottom:0;
}

.member__info a{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:baseline;
      -ms-flex-align:baseline;
          align-items:baseline;
  text-decoration:none;
}

.member__info a:hover span{
  text-decoration:underline;
}

.member__info i{
  width:14px;
  font-size:12px;
  color:#7d93a8;
}

.member--small{
  position:relative;
}

.member--small.is-selected,.member--small:hover{
  border:2px solid #e6679a !important;
}

.member--small.is-selected .member__tag,.member--small:hover .member__tag{
  color:#fff;
  background-color:#e6679a;
}

.member--small .member__container{
  padding:24px;
}

.member--small .member__title{
  margin-bottom:8px;
}

.member--small .member__tag{
  position:absolute;
  top:14px;
  right:14px;
  display:block;
  text-align:center;
  width:20px;
  height:20px;
  line-height:20px;
  border-radius:50%;
  background-color:#ebebeb;
  font-size:10px;
  color:#7d93a8;
  font-weight:500;
}

.member--small i{
  color:#7d93a8;
  margin-right:10px;
}

.member--small .member__info div{
  display:inline-block;
  vertical-align:top;
}

.member--small .member__info div:first-child{
  position:relative;
  padding-right:36px;
}

.member--small .member__info div:first-child:after{
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  right:0;
  content:"";
  display:block;
  width:1px;
  height:34px;
  background-color:#ebebeb;
}

.member--small .member__info div:last-child{
  padding-left:36px;
}

.member--small .accordion-trigger{
  cursor:pointer;
  text-align:center;
  height:56px;
  line-height:56px;
  border-top:1px solid #ebebeb;
  font-weight:500;
  -webkit-transition:all .2s ease;
  transition:all .2s ease;
}

.member--small .accordion-trigger:hover,.member--small .accordion-trigger:hover i{
  color:#e6679a;
}

.member--small .accordion-trigger i{
  font-style:normal;
  margin-left:10px;
}

.member--small .accordion-trigger.is-selected span:first-child,.member--small .accordion-trigger span:last-child{
  display:none;
}

.member--small .accordion-trigger.is-selected span:last-child{
  display:block;
}

.member--small .accordion-trigger:after{
  content:"";
}

.member--small+.directory__members{
  background-color:#fff;
}

.member--small+.directory__members .member{
  border-bottom:1px solid #ebebeb;
}

@media (min-width:768px){
  .member-group{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .member-group .member{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    width:33%;
    margin-bottom:0;
    padding-right:40px;
  }
  .member--area .member__info a{
    margin-bottom:8px;
  }
  .member__info a{
    margin-bottom:4px;
  }
  .member__info a:last-child{
    margin-bottom:0;
  }
  .accordion .member--row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .accordion .member--row .member__info{
    width:250px;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .accordion .member--row .member__role{
    margin-bottom:0;
  }
}

@media (min-width:1280px){
  .member--row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .member--row .member__info{
    width:250px;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .member--row .member__role{
    margin-bottom:0;
  }
  .member--area .member__info a{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
  }
  .member--area .member__info a:after{
    content:"";
    display:inline-block;
    vertical-align:middle;
    height:12px;
    width:2px;
    background:#ebebeb;
    margin:0 24px;
  }
  .member--area .member__info a:last-child:after{
    display:none;
  }
}

.overlay{
  content:"";
  z-index:200;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#000;
  opacity:0;
  -webkit-transition:opacity .2s ease-out;
  transition:opacity .2s ease-out;
  pointer-events:none;
}

.overlay.is-visible{
  cursor:pointer;
  pointer-events:auto;
  opacity:.6;
}

.has-overlay{
  z-index:201;
}

.list{
  list-style:none;
  margin-left:32px;
  padding-left:24px;
}

.list li{
  position:relative;
}

.list li span:before{
  color:#ccc;
  position:absolute;
  left:-24px;
  top:auto;
}

ul.list li span:before{
  content:"\2014";
}

ul.list--bullet li span:before{
  content:"•";
  font-family:serif;
}

ul.list--check li span:before{
  content:"\e944";
  padding-top:4px;
  font-family:icon;
  font-size:10px;
  color:#2ecc71;
}

ol.list{
  counter-reset:number-counter;
}

ol.list li span:before{
  content:counter(number-counter) ".";
  counter-increment:number-counter;
}

.list-type{
  color:#999;
}

.list-type li,.list-type li:after{
  display:inline-block;
}

.list-type li:after{
  content:"";
  vertical-align:middle;
  height:12px;
  width:2px;
  background:#ebebeb;
  margin:0 24px;
}

.list-type li:last-child{
  padding-right:0;
  margin-right:0;
}

.list-type li:last-child:after{
  display:none;
}

.list-type i{
  font-size:12px;
  color:#7d93a8;
}

.table{
  display:table;
  overflow-x:auto;
  border:1px solid #ebebeb;
  color:#999;
  font-weight:700;
  width:100%;
  border-radius:4px;
  border-collapse:collapse;
}

.table thead{
  color:#ccc;
  text-align:left;
}

.table tbody td:first-child{
  color:#292929;
  font-weight:400;
}

.table tbody tr{
  background:#fcfcfc;
}

.table tbody tr:nth-child(odd){
  background:#f7f7f7;
}

.table td,.table th{
  padding:16px 24px;
}

.table td.cell--inline{
  white-space:nowrap;
}

.bullet{
  font-size:20px;
  font-weight:400;
  margin:0 5px;
}

@media (min-width:768px){
  .narrow-1{
    padding-left:8px;
    padding-right:8px;
  }
  .narrow-2{
    padding-left:16px;
    padding-right:16px;
  }
  .narrow-3{
    padding-left:24px;
    padding-right:24px;
  }
  .narrow-4{
    padding-left:32px;
    padding-right:32px;
  }
}

.separator{
  border:0;
  border-bottom:1px solid #ebebeb;
}

.separator.is-dark{
  opacity:.1;
}

.tooltip{
  position:relative;
  display:inline;
  text-align:left;
}

.tooltip:hover .tooltip__content{
  display:block;
}

.tooltip i{
  cursor:pointer;
}

.tooltip__content{
  display:none;
  position:absolute;
  bottom:150%;
  left:-50px;
  white-space:nowrap;
  padding:16px 24px;
  background-color:#fff;
  border-radius:4px;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.tooltip__content:after{
  content:"";
  position:absolute;
  left:52px;
  bottom:-8px;
  border:4px solid transparent;
  border-top-color:#fff;
}

.tooltip__content p{
  margin:0;
}

.tooltip--site-club i{
  font-size:16px;
  color:rgba(43, 65, 112, .2);
}

.tooltip__content--site-club{
  display:none;
  position:fixed;
  min-width:342px;
  left:50%;
  top:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  z-index:201;
  min-height:100px;
  background:#fff;
  padding:24px 24px 0;
  white-space:inherit;
  min-height:240px;
  overflow:hidden;
  border:1px solid #eee;
  border-radius:6px;
}

.tooltip__content--site-club:after{
  display:none;
}

.tooltip__content--site-club>i{
  position:absolute;
  top:21px;
  right:21px;
  font-size:16px;
  color:#280b0d;
  opacity:.4;
}

.tooltip__content--site-club>p{
  font-size:14px;
  line-height:22px;
  padding-right:32px;
  margin-bottom:16px;
  font-weight:400;
}

.tooltip__content--site-club.is-open{
  display:block;
}

@media (min-width:1280px){
  .tooltip__content--site-club{
    position:absolute;
    width:306px;
    left:50%;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
    top:85%;
    bottom:inherit;
  }
  .tooltip__content--site-club>i{
    display:none;
  }
  .tooltip--site-club:hover+.tooltip__content--site-club{
    display:block;
  }
}

.input{
  position:relative;
  margin-bottom:16px;
}

.input label{
  display:none;
}

.input input,.input textarea{
  display:block;
  width:100%;
  height:56px;
  padding:8px 22px 8px 16px;
  background:#fff;
  color:#292929;
  border:1px solid #ccc;
  border-radius:4px;
  -webkit-appearance:none;
}

.input input:focus,.input input:hover,.input textarea:focus,.input textarea:hover{
  border-color:#999;
  outline:0;
}

.input input:disabled,.input textarea:disabled{
  background-color:#ccc;
}

.input input::-ms-clear,.input textarea::-ms-clear{
  display:none;
}

.input textarea{
  height:auto;
  min-height:100px;
}

.input.is-dark input,.input.is-dark textarea{
  border-color:hsla(0, 0%, 100%, .12);
  color:#fff;
  background:none;
}

.input.is-dark input:focus,.input.is-dark textarea:focus{
  border-color:#fff;
}

.input.is-dark ::-webkit-input-placeholder{
  color:hsla(0, 0%, 100%, .8);
}

.input.is-dark ::-moz-placeholder{
  color:hsla(0, 0%, 100%, .8);
}

.input.is-dark :-ms-input-placeholder{
  color:hsla(0, 0%, 100%, .8) !important;
}

.input.is-dark ::-ms-input-placeholder{
  color:hsla(0, 0%, 100%, .8);
}

.input.has-error input{
  border-color:#f2362f;
}

.input.has-error .icon-info,.input.has-error~.input__error{
  display:block;
}

.input .icon-info{
  position:absolute;
  right:0;
  top:0;
  height:100%;
  width:56px;
  line-height:56px;
  font-size:20px;
  display:none;
  color:#f2362f;
  border-radius:0 4px 4px 0;
}

.input.has-check .icon-check{
  width:20px;
  line-height:20px;
  position:absolute;
  right:20px;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  background:#e6679a;
  border-radius:50%;
  color:#fff;
  font-size:7px;
}

.input--secondary input{
  border-color:hsla(0, 0%, 100%, .12);
  background-color:#280b0d;
}

.input--secondary input:focus{
  border-color:#fff;
  color:#fff;
}

.input--dropdown:before,.input--search:before{
  content:"\e918";
  font-family:icon;
  font-size:16px;
  line-height:16px;
  color:#ccc;
  position:absolute;
  left:16px;
  top:50%;
  margin-top:-8px;
}

.input--search input{
  padding-left:40px;
  padding-right:16px;
  font-size:18px;
}

.input--dropdown:before{
  margin-top:-6px;
  font-size:12px;
  line-height:12px;
}

.input--dropdown input{
  height:48px;
  line-height:48px;
  padding-right:16px;
  padding-left:40px;
  border:1px solid transparent;
}

.input--dropdown input:hover{
  border-color:transparent;
  cursor:pointer;
}

.input--dropdown input:focus{
  border-color:#ebebeb #ebebeb transparent;
}

.input--dropdown input:focus~.dropdown__list{
  visibility:visible;
  opacity:1;
  max-height:300px;
}

.input--dropdown .dropdown__list{
  position:absolute;
  text-align:left;
  padding:0 14px 0 0;
  border:1px solid #ebebeb;
  border-top:0;
  margin-top:-2px;
}

.input--dropdown .dropdown__list .dropdown__wrapper{
  padding:8px 0 13px 16px;
  overflow-y:scroll;
  max-height:200px;
}

.input--dropdown .dropdown__list p{
  display:block;
  line-height:32px;
  padding:0 16px 0 0;
}

.input--dropdown .dropdown__list p:hover{
  color:#999;
  cursor:pointer;
}

.input--transparent{
  margin-bottom:24px;
}

.input--transparent:before{
  left:0;
  color:#fff;
  opacity:.4;
  font-size:20px;
  margin-top:-4px;
}

.input--transparent.is-dark input{
  border-color:rgba(0, 0, 0, .1);
}

.input--transparent.is-dark input:focus{
  border-bottom:2px solid rgba(0, 0, 0, .1);
}

.input--transparent input{
  border:0;
  border-bottom:2px solid #999;
  border-radius:0;
  font-size:40px;
  font-weight:700;
  font-family:Din;
  height:68px;
  padding:16px 0 16px 32px;
}

.input--expand{
  display:block;
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
  overflow:hidden;
  border-radius:4px;
  margin-bottom:0;
}

.input--expand:before{
  pointer-events:none;
}

.input--expand.is-open{
  width:100%;
}

.input--expand.is-open input{
  cursor:text;
}

.input--expand input{
  height:100%;
  border:0;
  cursor:pointer;
  padding-left:56px;
}

.input--file{
  position:relative;
  width:100%;
  height:54px;
  line-height:54px;
  padding-left:16px;
  background-color:#fcfcfc;
  -webkit-box-shadow:0 0 0 2px #ebebeb;
          box-shadow:0 0 0 2px #ebebeb;
  border-radius:4px;
}

.input--file:hover{
  -webkit-box-shadow:0 0 0 2px #e6679a;
          box-shadow:0 0 0 2px #e6679a;
}

.input--file:hover span{
  text-decoration:underline;
}

.input--file.has-file{
  background-color:#fcfcfc;
  border:1px solid #ccc;
  -webkit-box-shadow:none;
          box-shadow:none;
}

.input--file.has-file:hover{
  border-color:#e6679a;
}

.input--file.has-file:hover span{
  text-decoration:none;
}

.input--file.has-file label span{
  font-weight:400;
}

.input--file.has-file .icon-delete{
  display:block;
}

.input--file.has-file input{
  cursor:default;
}

.input--file.has-error{
  -webkit-box-shadow:0 0 0 2px #f2362f;
          box-shadow:0 0 0 2px #f2362f;
}

.input--file.has-error .icon-info,.input--file.has-error~.input__error{
  display:block;
}

.input--file i{
  margin-top:-2px;
  color:#999;
  margin-right:8px;
}

.input--file i.icon-valid{
  color:#e6679a;
}

.input--file label{
  height:56px;
  font-size:14px;
}

.input--file label span{
  color:#e6679a;
  font-weight:500;
}

.input--file input{
  top:0;
  left:0;
  width:100%;
  opacity:0;
}

.input--file .icon-delete,.input--file input{
  cursor:pointer;
  position:absolute;
  height:100%;
}

.input--file .icon-delete{
  right:0;
  top:2px;
  width:54px;
  margin-right:0;
  line-height:54px;
  font-size:14px;
  display:none;
  color:#7d93a8;
}

.input--file-club{
  background-color:#e6679a;
  color:#fff;
  text-align:center;
  font-weight:500;
}

.input--file-club,.input--file-club:hover{
  -webkit-box-shadow:inherit;
          box-shadow:inherit;
}

.input--file-club>p{
  font-family:Roboto;
}

.input__error{
  display:none;
  margin-top:-8px;
  margin-bottom:16px;
  padding-left:16px;
  color:#f2362f;
}

.input--material label{
  display:block;
  position:absolute;
  top:8px;
  left:16px;
  color:#999;
  font-size:10px;
  line-height:20px;
  font-weight:700;
  text-transform:uppercase;
  -webkit-transition:all .15s ease;
  transition:all .15s ease;
  cursor:text;
  pointer-events:none;
}

.input--material label:after{
  content:attr(data-label);
  color:#999;
  font-size:14px;
  line-height:24px;
  font-weight:400;
  letter-spacing:-.2px;
  text-transform:none;
  position:absolute;
  width:200%;
  left:0;
  opacity:0;
  -webkit-transition:opacity .15s ease;
  transition:opacity .15s ease;
}

.input--material input,.input--material textarea{
  padding-top:24px;
  line-height:22px;
}

.input--material input.is-empty~label,.input--material textarea.is-empty~label{
  -webkit-transform:translateY(8px);
          transform:translateY(8px);
  color:transparent;
}

.input--material input.is-empty~label:after,.input--material textarea.is-empty~label:after{
  opacity:1;
}

.input--material input.is-empty:-webkit-autofill~label,.input--material textarea.is-empty:-webkit-autofill~label{
  -webkit-transform:translate(0);
          transform:translate(0);
}

.input--material input.is-empty:-webkit-autofill~label:after,.input--material textarea.is-empty:-webkit-autofill~label:after{
  opacity:0;
}

.input--no-edit input{
  background-color:#fafafa;
  pointer-events:none;
}

.input--picture{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.input--picture .file-input{
  position:relative;
  width:128px;
  height:128px;
  border-radius:50%;
  background-color:#999;
  overflow:hidden;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:50%;
}

.input--picture input{
  position:absolute;
  display:block;
  left:50%;
  height:100%;
  top:0;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  opacity:0;
  cursor:pointer;
  z-index:1;
}

.input--picture label{
  position:relative;
  display:block;
  padding-top:8px;
  color:#999;
  font-weight:500;
  cursor:pointer;
}

.input--picture label:hover{
  color:#e6679a;
}

.form-separator{
  display:block;
  width:100%;
  height:1px;
  background-color:#ebebeb;
}

.input-group.input-group--edit{
  margin-bottom:24px;
}

.input-group.input-group--edit .input{
  margin-bottom:8px;
}

.input-group.input-group--edit a{
  font-size:14px;
  color:#999;
  cursor:pointer;
}

.input-group.input-group--edit a:hover{
  color:#e6679a;
}

.input-edit{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:56px;
  background-color:#fff;
  -webkit-box-shadow:none;
          box-shadow:none;
  border:1px solid #ccc;
  padding:0 12px;
  border-radius:4px;
  overflow:hidden;
}

.input-edit.text .input-edit__placeholder{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.input-edit.text .label{
  position:absolute;
  display:block;
  top:10px;
  left:16px;
  font-size:10px;
  line-height:14px;
}

.input-edit.text p{
  padding-top:12px;
  padding-left:2px;
  margin-bottom:0;
  font-size:14px;
}

.input-edit.image-big{
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  min-height:120px;
}

.input-edit.image-big .input-edit__placeholder{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  padding-top:12px;
  padding-left:12px;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:50%;
}

.input-edit.image-big .label{
  padding-top:5px;
}

.input-edit.image-big .btn{
  margin-top:12px;
  z-index:2;
}

.input-edit.color-picker{
  padding:0 12px;
}

.input-edit.color-picker .input-edit__placeholder{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.input-edit.color-picker .input-edit__placeholder div{
  display:inline-block;
}

.input-edit.color-picker .input-edit__placeholder div span{
  display:inline-block;
  width:28px;
  height:28px;
  border-radius:50%;
  overflow:hidden;
  border-width:2px;
  border-style:solid;
}

.input-edit.color-picker .input-edit__placeholder div span:nth-child(n+2){
  margin-left:-9px;
}

.input-edit.image .input-edit__placeholder img{
  height:25px;
}

.input-edit .label{
  margin-right:16px;
  padding:0;
  color:#999;
  font-size:12px;
  height:auto;
  background-color:transparent;
  pointer-events:none;
}

.input--placeholder{
  height:56px;
  border-radius:4px;
  border:2px dashed rgba(40, 11, 13, .2);
}

@media (min-width:768px){
  .input--search:before{
    left:24px;
  }
  .input--search input{
    padding-left:56px;
    padding-right:22px;
    font-size:17px;
  }
  .input--transparent:before{
    left:0;
  }
  .input--transparent input{
    font-size:40px;
    padding-right:0;
    padding-left:32px;
  }
  .input--dropdown:before{
    left:16px;
  }
  .input--dropdown input:focus~.dropdown__list{
    padding-bottom:16px;
    top:46px;
    width:100%;
    left:0;
    border-top-left-radius:0;
    border-top-right-radius:0;
  }
  .input--dropdown .dropdown__list{
    top:46px;
  }
  .input-edit.image-big{
    min-height:170px;
  }
  .input-edit.text .input-edit__placeholder .label{
    display:inline-block;
    position:static;
    font-size:12px;
  }
  .input-edit.text .input-edit__placeholder p{
    display:inline-block;
    padding-top:0;
    padding-left:0;
  }
}

@media (min-width:1280px){
  .input-edit.image-big{
    min-height:230px;
  }
}

.input--dropdown.input--material:before{
  content:"";
}

.input--dropdown.input--material input{
  display:block;
  width:100%;
  height:56px;
  border:1px solid #ccc;
  padding-left:16px;
  position:relative;
  z-index:3;
}

.input--dropdown.input--material i{
  display:none;
}

.input--dropdown.input--material label{
  z-index:4;
}

.input--dropdown.input--material .dropdown__list{
  border:0;
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
  -webkit-box-shadow:0 10px 16px 0 rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px 0 rgba(51, 51, 51, .08);
}

.input--dropdown.input--material .dropdown__list p{
  color:#999;
}

.input--dropdown.input--material .dropdown__list li img{
  margin-right:16px;
}

.input--dropdown.input--material .dropdown__list li a:hover{
  color:#e8a801;
}

.input--dropdown.input--material input:focus{
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.input--dropdown.input--material input:focus~.dropdown__list{
  top:56px;
  z-index:2;
}

.input-custom.input-custom--guest{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.input-custom.input-custom--guest span{
  color:#7d93a8;
  font-size:20px;
  cursor:pointer;
}

.input-custom.input-custom--guest span:first-child{
  margin-right:16px;
}

.input-custom.input-custom--guest span:last-child{
  margin-left:16px;
}

.input-custom.input-custom--guest input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  width:64px;
  height:56px;
  line-height:56px;
  text-align:center;
  border:1px solid #ccc;
  border-radius:4px;
  color:#999;
}

.input-custom.input-custom--guest input::-webkit-inner-spin-button,.input-custom.input-custom--guest input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance:none;
          appearance:none;
  margin:0;
}

.input-social{
  position:relative;
}

.input-social>i{
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  left:20px;
  font-size:16px;
  color:#7d93a8;
}

.input-social>i:after{
  content:"";
  position:absolute;
  right:-19px;
  top:-4px;
  display:block;
  width:1px;
  height:24px;
  background-color:#280b0d;
  opacity:.08;
}

.input-social input{
  padding-left:75px;
}

.input-social--site-club{
  width:100%;
}

@media (min-width:1280px){
  .input-social--site-club{
    width:calc(50% - 16px);
    display:inline-block;
  }
}

@media (min-width:1280px){
  .input-social--site-club:nth-child(2n){
    margin-left:32px;
  }
}

.checkbox,.radio,.switch{
  position:relative;
  margin-top:16px;
  margin-bottom:16px;
  color:#999;
}

.checkbox label,.radio label,.switch label{
  position:relative;
  display:inline-block;
  padding-left:24px;
  line-height:20px;
}

.checkbox label:before,.radio label:before,.switch label:before{
  content:"";
  display:inline-block;
  height:14px;
  width:14px;
  vertical-align:middle;
  position:absolute;
  top:1px;
  left:0;
  border:2px solid #7d93a8;
}

.checkbox label:after,.radio label:after,.switch label:after{
  content:"";
  position:absolute;
  top:4px;
  left:0;
}

.checkbox input,.radio input,.switch input{
  position:absolute;
  opacity:0;
}

.checkbox input:disabled~label,.radio input:disabled~label,.switch input:disabled~label{
  color:#ccc;
}

.checkbox input:focus~label:before,.radio input:focus~label:before,.switch input:focus~label:before{
  border-color:#7d93a8;
}

.checkbox.is-dark,.radio.is-dark,.switch.is-dark{
  color:hsla(0, 0%, 100%, .32);
}

.checkbox.is-dark label:before,.radio.is-dark label:before,.switch.is-dark label:before{
  border:2px solid hsla(0, 0%, 100%, .12);
}

.checkbox.is-dark input:focus~label:before,.radio.is-dark input:focus~label:before,.switch.is-dark input:focus~label:before{
  border-color:#fff;
}

.checkbox label:before{
  top:3px;
  border-radius:4px;
}

.checkbox label:after{
  opacity:0;
  -webkit-transition:opacity .2s ease-out;
  transition:opacity .2s ease-out;
  will-change:opacity;
}

.checkbox input:checked~label:after{
  content:"";
  width:8px;
  height:8px;
  margin-left:3px;
  opacity:1;
  top:6px;
  background-color:#e6679a;
}

.checkbox:hover label{
  cursor:pointer;
}

.checkbox.is-dark input:checked~label:after{
  background-color:#fff;
}

.checkbox.check-right input{
  position:relative;
  float:right;
}

.checkbox.check-right input:checked~label{
  color:#292929;
}

.checkbox.check-right label{
  display:block;
  padding-left:0;
  padding-right:24px;
}

.checkbox.check-right label:before{
  border-radius:2px;
  left:auto;
  right:0;
}

.checkbox.check-right label:after{
  left:auto;
  right:3px;
}

.footer input:checked~label:after{
  background-color:#fff;
}

.checkbox--gold label{
  color:#292929;
  line-height:20px;
}

.checkbox--gold label:before{
  border-radius:2px;
}

.checkbox--gold a{
  color:#e8a801;
  text-decoration:underline;
}

.checkbox--gold input:checked~label:after{
  background-color:#e8a801;
}

.radio label:before{
  border-radius:100%;
  background-color:#fff;
  -webkit-transition:border-color .2s ease-out;
  transition:border-color .2s ease-out;
  will-change:border-color;
}

.radio label:after{
  opacity:0;
  -webkit-transition:opacity .2s ease-out;
  transition:opacity .2s ease-out;
  will-change:opacity;
}

.radio input:checked~label:after{
  background-color:#e6679a;
  width:8px;
  height:8px;
  left:3px;
  border-radius:100%;
  opacity:1;
}

.radio.is-dark label:before{
  background-color:transparent;
}

.radio.is-dark input:checked~label:after{
  background-color:#fff;
}

.switch label{
  padding-left:48px;
}

.switch label:before{
  border-radius:40px;
  height:24px;
  width:40px;
  background-color:#ccc;
  border:0;
  -webkit-transition:background-color .2s ease-out;
  transition:background-color .2s ease-out;
  will-change:background-color;
}

.switch label:after{
  background-color:#fff;
  width:20px;
  height:20px;
  left:2px;
  top:3px;
  border-radius:100%;
  -webkit-transition:left .2s ease-out;
  transition:left .2s ease-out;
  will-change:left;
}

.switch input:checked~label:before{
  background-color:#e6679a;
}

.switch input:checked~label:after{
  top:3px;
  left:18px;
}

.switch input:focus~label:after{
  background-color:#e6679a;
  -webkit-box-shadow:#fff 0 0 0 8px inset, #999 0 3px 8px;
          box-shadow:inset 0 0 0 8px #fff,0 3px 8px #999;
}

.switch input.has-error~label:before{
  background-color:#f2362f;
}

.switch__info{
  display:block;
  margin-top:1px;
  padding-left:48px;
}

.switch__error{
  color:#f2362f;
}

.select{
  position:relative;
  overflow:hidden;
  color:#292929;
  border-radius:4px;
  background:#fff;
  margin-bottom:16px;
}

.select:after{
  content:"\e906";
  position:absolute;
  right:24px;
  top:50%;
  font-family:icon;
  font-size:8px;
  color:#7d93a8;
  pointer-events:none;
  margin-top:-5px;
  text-align:center;
}

.select:focus:after,.select:hover:after{
  color:#e6679a;
}

.select.has-error select{
  border-color:#f2362f;
}

.select.is-selected select{
  color:#292929;
}

.select select{
  display:block;
  width:100%;
  height:56px;
  -webkit-box-shadow:none;
          box-shadow:none;
  background:none;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  padding-left:16px;
  padding-right:48px;
  color:#999;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  border:1px solid #ccc;
  border-radius:4px;
}

.select select:focus,.select select:hover{
  outline:0;
  border-color:#999;
}

.select select:focus:after,.select select:hover:after{
  color:#e6679a;
}

.select select::-ms-expand{
  display:none;
}

.select select:focus::-ms-value{
  background-color:transparent;
  color:#292929;
}

.select select:disabled{
  background-color:#fcfcfc;
  pointer-events:none;
}

.select.is-dark{
  background:none;
}

.select.is-dark.has-error select{
  border-color:#f2362f;
}

.select.is-dark.is-selected select{
  color:#fff;
}

.select.is-dark:after{
  color:#7d93a8;
}

.select.is-dark select{
  color:hsla(0, 0%, 100%, .8);
  border-color:hsla(0, 0%, 100%, .12);
}

.select.is-dark select:focus::-ms-value{
  color:#fff;
}

.select.is-dark option{
  color:#292929;
}

.select--secondary{
  background-color:#280b0d;
}

.select--secondary.is-selected select{
  color:hsla(0, 0%, 100%, .56);
}

.select--secondary select{
  border-color:hsla(0, 0%, 100%, .12);
}

.select--secondary select:focus{
  border-color:#fff;
  color:#fff;
}

.select--material label{
  display:block;
  position:absolute;
  top:8px;
  left:16px;
  color:#999;
  font-size:10px;
  line-height:20px;
  font-weight:700;
  text-transform:uppercase;
  -webkit-transition:all .15s ease;
  transition:all .15s ease;
  cursor:text;
  pointer-events:none;
}

.select--material label:after{
  content:attr(data-label);
  color:#999;
  font-size:14px;
  line-height:24px;
  font-weight:400;
  letter-spacing:-.2px;
  text-transform:none;
  position:absolute;
  width:200%;
  left:0;
  opacity:0;
  -webkit-transition:opacity .15s ease;
  transition:opacity .15s ease;
}

.select--material select{
  padding-top:24px;
  padding-bottom:8px;
}

.select--material select.is-empty~label{
  -webkit-transform:translateY(8px);
          transform:translateY(8px);
  color:transparent;
}

.select--material select.is-empty~label:after{
  opacity:1;
}

.select--material select.is-empty:-webkit-autofill~label{
  -webkit-transform:translate(0);
          transform:translate(0);
}

.select--material select.is-empty:-webkit-autofill~label:after{
  opacity:0;
}

.topbar{
  position:relative;
  z-index:3;
  background-color:#280b0d;
  color:#a8a8a8;
  font-weight:500;
  line-height:2;
  padding:32px 24px;
}

.topbar ul{
  margin-bottom:0;
}

.topbar li{
  margin-bottom:16px;
  vertical-align:top;
}

.topbar a{
  display:block;
}

.topbar a:focus,.topbar a:hover{
  color:#fff;
  text-decoration:none;
}

.topbar .is-active{
  color:#fff;
}

.topbar__sub{
  padding-left:16px;
}

.topbar__sub li{
  margin-bottom:0;
}

.topbar__news,.topbar__right{
  display:none;
}

@media (min-width:1080px){
  .topbar{
    font-size:13px;
    line-height:40px;
    padding:0;
    margin-bottom:0;
  }
  .topbar li{
    display:inline-block;
    margin-bottom:0;
  }
  .topbar a{
    padding:0 8px;
  }
  .topbar .accordion-trigger{
    padding:0 24px;
  }
  .topbar .accordion-trigger:after{
    margin-left:8px;
    line-height:40px;
    float:none;
    content:"\e906";
  }
  .topbar .accordion-trigger.is-selected{
    background-color:#280b0d;
    color:#fff;
    text-decoration:none;
  }
  .topbar .accordion-trigger.is-selected:after{
    content:"\e914";
  }
  .topbar__sub{
    width:230px;
    position:absolute;
    background-color:#280b0d;
    line-height:2;
    padding:8px 24px;
    color:hsla(0, 0%, 100%, .6);
  }
  .topbar__sub li{
    display:block;
  }
  .topbar__sub a{
    padding:0;
  }
  .topbar__right{
    display:block;
    float:right;
  }
  .topbar__right i{
    top:-2px;
  }
  .topbar__right .topbar__sub{
    padding-bottom:16px;
  }
  .topbar__user{
    padding:0 24px;
    color:#fff;
    max-width:220px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    border-left:1px solid hsla(0, 0%, 100%, .06);
  }
  .topbar__user img{
    margin-right:16px;
    border-radius:100%;
  }
  .topbar__login{
    display:inline-block;
    background:hsla(0, 0%, 100%, .2);
    color:#fff;
    line-height:32px;
    padding:0 8px;
    border-radius:4px;
    margin-right:4px;
    margin-top:4px;
  }
  .topbar__login:hover{
    background:#20456e;
  }
  .topbar__news{
    display:inline-block;
    border-left:1px solid hsla(0, 0%, 100%, .06);
    padding-left:24px;
    font-weight:400;
    width:30%;
    width:calc(100% - 900px);
    font-size:13px;
  }
  .topbar__news .label{
    margin-right:8px;
  }
  .topbar__news a{
    display:inline-block;
    vertical-align:middle;
    white-space:nowrap;
    overflow:hidden;
    width:100%;
    text-overflow:ellipsis;
    padding-left:0;
  }
}

@media (min-width:1080px){
  .topbar .accordion-trigger{
    border-left:1px solid hsla(0, 0%, 100%, .06);
    margin-left:16px;
  }
  .topbar__sub{
    margin-left:16px;
  }
}

.menu{
  position:relative;
  padding:8px 24px 48px;
  background-color:#fff;
}

.menu.has-accordion-open .menu__link{
  color:#999;
}

.menu h1{
  display:none;
}

.menu a:focus,.menu a:hover{
  text-decoration:none;
}

.menu__link{
  font-weight:500;
  line-height:2;
  margin-bottom:0;
}

.menu__link li{
  margin-bottom:16px;
}

.menu__link a{
  display:block;
  -webkit-transition:color .3s ease,-webkit-box-shadow .3s ease;
  transition:color .3s ease,-webkit-box-shadow .3s ease;
  transition:color .3s ease,box-shadow .3s ease;
  transition:color .3s ease,box-shadow .3s ease,-webkit-box-shadow .3s ease;
}

.menu__link .accordion-trigger:after{
  color:#7d93a8;
}

.menu__link .accordion-trigger.is-selected{
  color:#292929;
}

.menu__link .accordion-trigger.is-selected:after{
  color:#e6679a;
}

.menu__link .accordion-trigger.is-selected+.menu__sub{
  color:#292929;
}

.menu__sub{
  padding-left:16px;
  margin-bottom:32px;
}

.menu__sub.is-hidden,.menu__sub li{
  margin-bottom:0;
}

.menu__sub ul{
  margin-bottom:16px;
}

.menu__sub .menu__link-all{
  margin:16px 0;
  color:#e6679a;
}

.menu__col .is-collapsed{
  margin-top:-16px;
}

.menu__login{
  width:100%;
  margin-bottom:24px;
}

.menu__login,.menu__social{
  text-align:center;
}

.menu__social a{
  margin:0 8px;
}

.menu__espace-ffr{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding:24px;
  margin-bottom:24px;
}

.menu__espace-ffr,.menu__espace-ffr:hover{
  color:hsla(0, 0%, 100%, .8);
}

.menu__espace-ffr:hover span{
  color:#e6679a;
}

.menu__espace-ffr span{
  color:#fff;
  font-size:16px;
}

.menu__espace-ffr .icon{
  margin:5px 16px 0 0;
  font-size:24px;
}

.menu__category{
  color:#999;
  font-family:Din;
  font-weight:700;
}

.menu__user{
  border-top:1px solid #ebebeb;
  border-bottom:1px solid #ebebeb;
  padding:16px 0 24px;
  margin-bottom:24px;
  font-weight:500;
}

.menu__user ul{
  color:#7d93a8;
  margin:8px 0 0 64px;
}

.menu__user li{
  margin-bottom:8px;
}

.menu__user li:last-child{
  margin-bottom:0;
}

.menu__user img{
  border-radius:100%;
  margin-right:16px;
}

.menu__user i{
  top:-2px;
}

.menu .input--expand,.menu__close,.menu__logo,.menu__plus{
  display:none;
}

@media (max-width:1079px){
  .menu__club{
    width:100%;
    margin-bottom:30px;
    margin-top:16px;
    z-index:4;
    text-align:center;
  }
}

@media (max-width:767px){
  .menu__club{
    width:100%;
  }
}

@media (min-width:768px){
  .menu{
    padding-top:80px;
  }
  .menu__close{
    position:absolute;
    top:0;
    right:0;
    display:block;
    width:70px;
    line-height:70px;
    color:#7d93a8;
    font-size:19px;
    cursor:pointer;
  }
  .menu__sub{
    line-height:32px;
    margin-top:8px;
  }
  .menu__sub.is-hidden{
    margin-top:0;
  }
}

@media (min-width:1080px){
  .menu{
    height:104px;
    max-width:1672px;
    padding:0 40px;
    margin:0 auto;
    z-index:2;
  }
  .menu .input--expand{
    display:block;
    position:absolute;
    top:32px;
    right:48px;
    width:44px;
    height:44px;
    -webkit-box-shadow:0 0 0 2px #ebebeb;
            box-shadow:0 0 0 2px #ebebeb;
    -webkit-transition:width .3s ease;
    transition:width .3s ease;
  }
  .menu .input--expand:before{
    left:14px;
  }
  .menu .input--expand:hover{
    -webkit-box-shadow:0 0 0 2px #999;
            box-shadow:0 0 0 2px #999;
  }
  .menu .input--expand.is-open{
    height:100%;
    width:480px;
    top:0;
    -webkit-box-shadow:-13px -3px 13px #ebebeb;
            box-shadow:-13px -3px 13px #ebebeb;
  }
  .menu .input--expand.is-open:before{
    left:24px;
  }
  .menu h1{
    display:inline-block;
    vertical-align:middle;
  }
  .menu h1 span{
    position:absolute;
    overflow:hidden;
    clip:rect(0 0 0 0);
    height:1px;
    width:1px;
    margin:-1px;
  }
  .menu__link{
    display:inline-block;
    vertical-align:top;
    line-height:104px;
    height:104px;
    font-size:0;
  }
  .menu__link>li>a,.menu__link>li>span{
    color:#999;
  }
  .menu__link>li:hover>a,.menu__link>li:hover>span{
    color:#292929;
    text-decoration:none;
    -webkit-box-shadow:0 -4px 0 #e6679a inset;
            box-shadow:inset 0 -4px 0 #e6679a;
  }
  .menu__link>li:hover>.accordion-trigger{
    -webkit-box-shadow:none;
            box-shadow:none;
  }
  .menu__link>li:hover .menu__sub{
    opacity:1;
    visibility:visible;
  }
  .menu__link .is-active{
    -webkit-box-shadow:0 -4px 0 #e6679a inset;
            box-shadow:inset 0 -4px 0 #e6679a;
    color:#292929;
  }
  .menu__link li{
    display:inline-block;
    margin-bottom:0;
    font-size:14px;
  }
  .menu__link a{
    padding:0 20px;
  }
  .menu__link .accordion-trigger:after,.menu__link .menu__link-all{
    display:none;
  }
  .menu__sub{
    position:absolute;
    top:84%;
    left:30%;
    padding:24px 32px;
    background-color:#fff;
    border-bottom:3px solid #e6679a;
    -webkit-box-shadow:0 4px 25px rgba(13, 24, 33, .15);
            box-shadow:0 4px 25px rgba(13, 24, 33, .15);
    opacity:0;
    visibility:hidden;
    margin-top:0;
  }
  .menu__sub ul{
    margin-bottom:0;
  }
  .menu__sub li{
    display:block;
    line-height:40px;
  }
  .menu__sub a{
    padding:0;
  }
  .menu__col{
    padding:32px 40px;
  }
  .menu__col>li{
    display:inline-block;
    vertical-align:top;
    margin-right:48px;
  }
  .menu__col>li:last-child{
    margin-right:0;
  }
  .menu__col .is-collapsed{
    margin-top:40px;
  }
  .menu__logo{
    max-height:56px;
    padding:0 10px;
    margin-right:16px;
  }
  .menu__right{
    float:right;
    padding-right:74px;
    line-height:104px;
  }
  .menu__club{
    position:relative;
  }
  .menu__club:after{
    content:"";
    position:absolute;
    right:-11px;
    top:8px;
    display:block;
    width:2px;
    height:32px;
    background-color:#ebebeb;
  }
  .menu__club,.menu__logo{
    display:inline-block;
  }
  .menu__close,.menu__espace-ffr,.menu__login,.menu__social,.menu__user{
    display:none;
  }
}

@media (min-width:1280px){
  .menu{
    padding:0 60px 0 55px;
  }
  .menu .input--expand{
    right:60px;
  }
  .menu__right{
    padding-right:66px;
  }
  .menu__link{
    margin-left:30px;
  }
}

@media (min-width:1080px) and (hover:none){
  .menu__link .menu__link-all{
    display:block;
  }
  .menu__link .accordion-trigger{
    pointer-events:none;
  }
}

@media (min-width:1680px){
  .menu__link>li>a,.menu__link>li>span{
    padding:0 26px;
  }
}

.header{
  position:relative;
  z-index:10;
  background-color:#fff;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  width:100%;
}

.header--over{
  position:absolute;
}

.header--fixed{
  position:fixed;
}

.header--dashboard .header__mobile{
  text-align:inherit;
}

.header--dashboard .header__mobile h1{
  display:inline-block;
  color:#004c99;
}

.header--dashboard .header__mobile img{
  height:28px;
  margin-left:16px;
  margin-right:16px;
}

.header--dashboard .topbar{
  margin-bottom:0;
}

.header--dashboard .dashboard-menu__header{
  display:none;
}

.header--transparent{
  background-color:transparent;
  -webkit-box-shadow:none;
          box-shadow:none;
  border-bottom:1px solid hsla(0, 0%, 100%, .2);
}

.header--transparent .input--search:before{
  color:#fff;
}

.header--transparent .input--search input{
  background-color:transparent;
}

.header--transparent .menu .input--search.is-open{
  color:#fff;
  background-color:#280b0d;
  -webkit-box-shadow:-13px 0 5px #280b0d, 13px 0 5px #280b0d;
          box-shadow:-13px 0 5px #280b0d,13px 0 5px #280b0d;
}

.header--transparent .menu .input--search input{
  color:#fff;
}

.header--transparent .header__mobile .input--search.is-open{
  background-color:#fff;
}

.header--transparent .header__mobile .input--search.is-open:before{
  color:#999;
}

.header__wrapper{
  display:none;
}

.header__mobile{
  position:relative;
  height:72px;
  line-height:72px;
  text-align:center;
  width:100%;
  background:#fff;
}

.header__mobile img{
  max-height:54px;
  max-width:70px;
}

.header__mobile>i{
  position:absolute;
  right:0;
  width:72px;
  line-height:72px;
  font-size:20px;
  color:#999;
  cursor:pointer;
}

.header__mobile .input--expand{
  position:absolute;
  width:72px;
  height:100%;
  border-radius:0;
}

.header__mobile .input--expand input{
  border-radius:0;
}

.header__mobile .input--expand:before{
  left:24px;
  font-size:20px;
  color:#999;
}

.header__mobile .input--expand.is-open{
  width:100%;
}

.header--site-club{
  max-height:146px;
}

.header--site-club .header__wrapper.is-visible+.header__mobile{
  border-bottom:1px solid #ccc;
}

.header--site-club .menu{
  padding-top:16px;
  padding-bottom:24px;
}

.header--site-club .menu__right .menu__link{
  display:none;
}

@media (max-width:1079px){
  .header__wrapper.is-visible{
    z-index:201;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    position:fixed;
    top:0;
    right:0;
    height:100%;
    width:100%;
    overflow:auto;
    -webkit-overflow-scrolling:touch;
    background-color:#fff;
  }
  .header__wrapper.is-visible~.overlay{
    display:block;
  }
  .header__wrapper .topbar{
    -webkit-box-ordinal-group:3;
        -ms-flex-order:2;
            order:2;
  }
}

@media (max-width:767px){
  .header__wrapper.is-visible{
    top:72px;
    height:calc(100% - 72px);
  }
  .header__wrapper.is-visible~.overlay{
    display:none;
  }
}

@media (min-width:768px){
  .header__wrapper{
    max-width:375px;
  }
  .header--site-club .menu{
    padding-top:56px;
  }
  .header__mobile{
    background:transparent;
  }
  .header__mobile img{
    max-width:60px;
  }
  .header__club-logo{
    display:inline-block;
    width:76px;
    height:78px;
    background-color:#fff;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
            box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  }
}

@media (min-width:1080px){
  .header__wrapper{
    display:block;
    max-width:none;
  }
  .header__mobile{
    display:none;
  }
  .header--transparent .menu{
    background:none;
  }
  .header--transparent .menu__link:hover>li>a,.header--transparent .menu__link:hover>li>span{
    color:hsla(0, 0%, 100%, .6);
  }
  .header--transparent .menu__link>li>a,.header--transparent .menu__link>li>span{
    color:#fff;
  }
  .header--transparent .menu__link>li:hover>a,.header--transparent .menu__link>li:hover>span{
    color:#fff;
    -webkit-box-shadow:0 -4px 0 hsla(0, 0%, 100%, .32) inset;
            box-shadow:inset 0 -4px 0 hsla(0, 0%, 100%, .32);
  }
  .header--transparent .menu__link>li:hover>.accordion-trigger{
    -webkit-box-shadow:none;
            box-shadow:none;
  }
  .header--transparent .menu__link .is-active{
    -webkit-box-shadow:0 -4px 0 hsla(0, 0%, 100%, .32) inset;
            box-shadow:inset 0 -4px 0 hsla(0, 0%, 100%, .32);
  }
  .header--transparent .menu__club:after{
    background-color:hsla(0, 0%, 100%, .06);
  }
  .header--transparent .input--expand{
    -webkit-box-shadow:0 0 0 2px hsla(0, 0%, 100%, .32);
            box-shadow:0 0 0 2px hsla(0, 0%, 100%, .32);
  }
  .header--transparent .input--expand:hover{
    -webkit-box-shadow:0 0 0 2px #fff;
            box-shadow:0 0 0 2px #fff;
  }
  .header--dashboard .dashboard-menu{
    display:none;
  }
  .header--site-club{
    max-height:146px;
  }
  .header--site-club .menu{
    padding-bottom:0;
    padding-top:0;
  }
  .header--site-club .menu>a{
    position:absolute;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }
  .header--site-club .menu__link li a{
    margin:0 20px;
  }
  .header--site-club .menu__club:after{
    display:none;
  }
  .header--site-club .menu__right{
    padding-right:20px;
  }
  .header--site-club .menu__right .menu__link{
    display:inline-block;
  }
  .header--site-club .menu__right .menu__link--only{
    position:relative;
    left:20px;
  }
  .header--site-club .menu__right .menu__link--only a{
    margin:0;
  }
  .header__club-logo{
    width:90px;
    height:116px;
    padding-top:24px;
    text-align:center;
  }
  .menu__logo{
    margin-right:0;
  }
}

.footer{
  position:relative;
  color:hsla(0, 0%, 100%, .8);
  background-color:#280b0d;
  padding-top:40px;
}

.footer .ft-h5,.footer h5{
  color:#fff;
}

.footer ul{
  margin-bottom:0;
}

.footer .checkbox{
  font-size:12px;
  line-height:20px;
}

.footer__top .btn--primary{
  width:100%;
  height:56px;
  line-height:56px;
}

.footer__top .select{
  margin-bottom:8px;
}

.footer__top hr{
  border:0;
  border-bottom:1px solid hsla(0, 0%, 100%, .06);
  margin:40px 0;
}

.footer__links{
  padding-bottom:40px;
}

.footer__links .col{
  margin-bottom:12px;
}

.footer__links ul a:focus,.footer__links ul a:hover{
  text-decoration:none;
}

.footer__links li{
  line-height:32px;
  color:hsla(0, 0%, 100%, .6);
}

.footer__links a:focus,.footer__links a:hover{
  color:#fff;
}

.footer__category{
  display:block;
  font-weight:500;
  line-height:2;
  color:#fff;
}

.footer__category.is-selected{
  margin-bottom:8px;
}

.footer__category.is-selected+ul{
  margin-bottom:16px;
}

.footer__category.is-selected:after{
  color:#e6679a;
}

.footer__category:after{
  float:right;
  color:#7d93a8;
}

.footer__corporate{
  background-color:rgba(0, 0, 0, .2);
  padding:24px 0;
  color:#fff;
}

.footer__corporate-legal{
  margin-top:16px;
  margin-bottom:0;
  color:hsla(0, 0%, 100%, .32);
}

.footer__corporate-links a{
  color:hsla(0, 0%, 100%, .6);
}

.footer__corporate-links a:focus,.footer__corporate-links a:hover{
  color:#fff;
  opacity:1;
}

.footer__logo{
  text-align:center;
}

.footer__logo img{
  height:69px;
}

.footer__logo img:last-of-type:not(:only-of-type){
  display:none;
}

.footer__logo img.ligue{
  opacity:.4;
  max-width:175px;
}

@media (min-width:768px){
  .footer__top form{
    position:relative;
  }
  .footer__top .input,.footer__top .select{
    display:inline-block;
    vertical-align:top;
    margin-bottom:0;
  }
  .footer__top .select{
    width:206px;
    margin-right:16px;
  }
  .footer__top .input{
    width:300px;
    margin-right:32px;
  }
  .footer__top .btn--primary{
    position:absolute;
    top:0;
    left:555px;
    width:180px;
  }
  .footer__category{
    font-size:15px;
    font-weight:700;
  }
  .footer__corporate{
    text-align:center;
  }
  .footer__corporate-links li{
    display:inline-block;
    margin:0 16px;
  }
}

@media (min-width:1280px){
  .footer{
    padding-top:48px;
  }
  .footer .ft-h5,.footer h5{
    font-size:20px;
    font-weight:400;
  }
  .footer__top .select{
    width:286px;
  }
  .footer__top .input{
    width:390px;
  }
  .footer__top .btn--primary{
    left:725px;
  }
  .footer__links{
    position:relative;
  }
  .footer__links .col:first-child{
    margin-left:25%;
  }
  .footer__links .row{
    margin-top:24px;
  }
  .footer__links .row:first-child{
    margin-top:0;
  }
  .footer__links ul{
    padding:0;
  }
  .footer__links .accordion-trigger:after{
    display:none;
  }
  .footer__links .accordion-panel{
    max-height:none;
    opacity:1;
    visibility:visible;
  }
  .footer__logo{
    position:absolute;
    top:0;
    left:60px;
    text-align:left;
  }
  .footer__logo img{
    height:110px;
  }
  .footer__logo img:first-child(){
    display:block;
  }
  .footer__logo img:nth-child(2){
    display:none;
  }
  .footer__category{
    margin-bottom:16px;
  }
  .footer__category i{
    display:none;
  }
  .footer__corporate{
    text-align:right;
    padding:20px 0;
  }
  .footer__corporate .col{
    float:right;
  }
  .footer__corporate-legal{
    margin:0;
    text-align:left;
  }
}

.pagination{
  font-weight:400;
  text-align:center;
  margin-bottom:0;
}

.pagination a,.pagination li{
  display:inline-block;
}

.pagination a{
  vertical-align:middle;
  color:#ccc;
  width:48px;
  height:48px;
  line-height:48px;
  border-radius:4px;
  font-weight:500;
  -webkit-transition:all .15s ease-out;
  transition:all .15s ease-out;
}

.pagination a:hover{
  color:#e6679a;
  text-decoration:none;
}

.pagination a.is-active{
  background-color:#fff;
  color:#292929;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  pointer-events:none;
}

.pagination i{
  font-size:10px;
}

.pagination__next a,.pagination__prev a{
  color:#7d93a8;
}

.pagination__next.is-disabled,.pagination__prev.is-disabled{
  opacity:.32;
  pointer-events:none;
}

.pagination__prev{
  float:left;
  margin-left:-16px;
}

.pagination__next{
  float:right;
  margin-right:-16px;
}

.banner{
  position:relative;
  padding-top:92px;
  padding-bottom:50px;
  color:#fff;
}

.banner h1{
  margin-top:48px;
}

.banner h1.has-score{
  margin-top:45px;
}

.banner h1 span{
  color:hsla(0, 0%, 100%, .6);
}

.banner .img-secondary:before{
  opacity:.7;
}

.banner .navbar{
  position:absolute;
  z-index:4;
  bottom:0;
  left:0;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.banner .banner-tooltip{
  color:#999;
}

.banner .banner-tooltip .tooltip .icon{
  font-size:18px;
}

.banner .banner-tooltip span{
  display:block;
  color:#292929;
  margin-bottom:4px;
  font-weight:500;
}

.banner .banner-tooltip .tooltip__content{
  top:25px;
  width:calc(100vw - 30px);
  max-width:345px;
  padding:20px 32px;
  white-space:normal;
  z-index:2;
}

.banner .banner-tooltip .tooltip__content,.banner .banner-tooltip .tooltip__content:after{
  bottom:auto;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}

.banner .banner-tooltip .tooltip__content:after{
  top:-4px;
  border-bottom-color:#fff;
  border-top:none;
}

.banner .banner-tooltip .tooltip__content p{
  line-height:22px !important;
}

.banner--big{
  height:560px;
  margin-bottom:-310px;
  padding-bottom:0;
}

.banner--big:after{
  content:"";
  position:absolute;
  bottom:-1px;
  left:0;
  width:100%;
  height:200px;
  background:url(../img/banner/mask.svg) no-repeat bottom;
  background-size:cover;
}

.banner--big .img-blue-alt:before{
  opacity:.6;
}

.banner--faq{
  margin-bottom:-370px;
}

.banner--searchClub{
  height:656px;
  margin-bottom:-48px;
  padding-bottom:0;
}

.banner--search{
  background:#e6679a;
  min-height:248px;
  padding-bottom:24px;
  padding-top:16px;
}

.banner--search h1{
  display:none;
}

.banner--search .input{
  margin-top:48px;
}

.banner--search .input+p{
  color:rgba(0, 0, 0, .32);
}

.banner--search ::-webkit-search-cancel-button{
  background-image:url(../img/icon-delete-black.svg);
  opacity:.32;
}

.banner--404{
  margin-top:-129px;
  margin-bottom:-390px;
}

.banner--match{
  position:relative;
  padding-top:97px;
  padding-bottom:0;
  height:auto;
  min-height:530px;
}

.banner--match.banner--bigNoAfter{
  height:540px;
  margin-bottom:0;
}

.banner--match .banner__button{
  display:inline-table;
  position:absolute;
  bottom:98px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  z-index:2;
  padding:0 22px;
  white-space:nowrap;
}

.banner--match .banner__button .icon{
  margin-top:-1px;
}

.banner--match .banner__button--score{
  bottom:110px;
}

.banner--match .banner__button--score.is-live{
  bottom:86px;
}

.banner--match .breadcrumb{
  text-align:left;
}

.banner--match h1,.banner--match h2{
  font-size:14px;
  line-height:20px;
}

.banner--match h1{
  font-weight:800;
  margin-top:45px;
}

.banner--match h2{
  opacity:.6;
  font-weight:500;
}

.banner--match .banner__infos{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  padding-bottom:32px;
}

.banner--match .banner__infos span{
  display:block;
  margin-bottom:8px;
  opacity:.6;
  font-weight:800;
  text-align:center;
}

.banner--match .banner__infos span:last-child{
  margin-bottom:0;
}

.banner--match .teams-counter{
  color:hsla(0, 0%, 100%, .6);
  text-decoration:underline;
  font-weight:500;
}

.banner--match hr{
  position:absolute;
  bottom:123px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  width:calc(100% - 32px);
  border:1px solid #fff;
  margin:0;
  border-bottom:0;
  opacity:.2;
}

.banner--match .game-date{
  display:none;
}

.banner--match .banner__scoreboard{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
      justify-content:space-around;
  text-align:center;
}

.banner--match .banner__scoreboard__image{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  margin:0 auto;
  width:70px;
  height:70px;
  -webkit-box-shadow:0 2px 26px 0 rgba(0, 0, 0, .3);
          box-shadow:0 2px 26px 0 rgba(0, 0, 0, .3);
  border-radius:50%;
  border:4px solid #ebebeb;
  background-color:#fff;
}

.banner--match .banner__scoreboard__image img{
  max-height:43px;
  max-width:41px;
}

.banner--match .banner__scoreboard>div{
  display:inline-block;
  padding-top:20px;
  vertical-align:top;
  width:100px;
}

.banner--match .banner__scoreboard>div:nth-child(2){
  width:125px;
  padding-top:32px;
  text-align:center;
}

.banner--match .banner__scoreboard>div:nth-child(2)>p{
  font-size:20px;
  line-height:22px;
}

.banner--match .banner__scoreboard>div h3{
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

.banner--match .banner__scoreboard .banner__scoreboard__score p{
  display:inline-block;
}

.banner--match .banner__scoreboard .banner__scoreboard__score p:nth-child(2){
  font-size:36px;
  line-height:36px;
  font-weight:400;
  margin-left:8px;
  margin-right:8px;
}

.banner--match .banner__scoreboard h2{
  display:block;
  font-weight:700;
}

.banner--match .banner__scoreboard h2:before{
  display:none;
}

.banner--match .banner__scoreboard .label--long{
  margin-top:16px;
  padding-top:5px;
  padding-bottom:4px;
  max-width:104px;
  white-space:normal;
  height:auto;
  line-height:15px;
}

.banner.has-score{
  min-height:444px;
  padding-top:138px;
}

.banner.has-score .live-results,.home-slider .live-results{
  top:73px;
}

.banner--social-wall{
  min-height:360px;
}

.banner--team{
  min-height:356px;
}

.banner--team h1{
  margin-top:50px;
  margin-bottom:16px;
}

.banner--wButton{
  min-height:530px;
}

.banner--wButton.has-score{
  min-height:578px;
  padding-top:138px;
}

.banner--wButton.has-score .live-results{
  top:73px;
}

.banner--match-ended,.banner--match-live{
  min-height:500px;
}

.banner--match-ended.has-score,.banner--match-live.has-score{
  min-height:545px;
  padding-top:138px;
}

.banner--match-ended.has-score .live-results,.banner--match-live.has-score .live-results{
  top:73px;
}

.banner--match-ended hr,.banner--match-live hr{
  bottom:104px;
}

.banner__thumbnail{
  position:absolute;
  top:0;
  width:100%;
  height:100%;
  z-index:-1;
  background:no-repeat 50%;
  background-size:cover;
}

.banner__desc{
  font-size:18px;
  line-height:28px;
  color:hsla(0, 0%, 100%, .8);
  margin-top:8px;
  max-width:710px;
}

.banner.has-score+.container .banner-over.banner-over--themes .card,.banner.has-score+.container .banner-over.banner-over--themes .news{
  margin-top:-104px;
}

.banner.has-score+.container .banner-over .card,.banner.has-score+.container .banner-over .news{
  margin-top:-119px;
}

.banner-over.banner-over--themes .card,.banner-over.banner-over--themes .news{
  margin-top:-140px;
}

.banner-over .card,.banner-over .news{
  margin-top:-119px;
}

.banner.banner--live-score{
  padding-top:134px;
}

.banner.banner--live-score .live-results{
  top:73px;
}

.banner--team-cat{
  height:537px;
}

.banner--team-cat .banner__desc{
  max-width:288px;
}

.banner--team-cat+.container{
  margin-top:-229px;
}

@media (min-width:768px){
  .banner{
    min-height:auto;
    height:370px;
  }
  .banner.has-score{
    padding-top:160px;
  }
  .banner h1{
    font-size:48px;
    line-height:60px;
  }
  .banner__desc{
    font-size:17px;
    line-height:24px;
  }
  .banner--big{
    height:572px;
    margin-bottom:-342px;
  }
  .banner--faq{
    margin-bottom:-358px;
  }
  .banner--searchClub{
    height:740px;
    margin-bottom:-80px;
  }
  .banner--search{
    height:248px;
  }
  .banner--center h1,.banner--center p{
    margin-left:0;
  }
  .banner-over.banner-over--themes .card,.banner-over.banner-over--themes .news,.banner.has-score+.container .banner-over.banner-over--themes .card,.banner.has-score+.container .banner-over.banner-over--themes .news{
    margin-top:-70px;
  }
  .banner-over .card,.banner-over .news{
    margin-top:-135px;
  }
  .banner--match{
    text-align:center;
    height:460px;
    padding-top:96px;
  }
  .banner--match.banner--bigNoAfter{
    height:460px;
    margin-bottom:0;
  }
  .banner--match>.container{
    position:relative;
    height:100%;
  }
  .banner--match .banner__button,.banner--match .banner__button--score.is-live{
    bottom:52px;
  }
  .banner--match .banner__button{
    bottom:48px;
  }
  .banner--match h1{
    margin-top:65px;
  }
  .banner--match .banner__infos{
    padding-bottom:29px;
  }
  .banner--match .banner__infos>div{
    position:relative;
    height:14px;
  }
  .banner--match .banner__infos span{
    display:inline-block;
    margin-bottom:0;
  }
  .banner--match .banner__infos span:first-child{
    text-align:left;
    position:absolute;
    left:0;
  }
  .banner--match .banner__infos span:last-child{
    text-align:right;
    position:absolute;
    right:0;
  }
  .banner--match h1,.banner--match h2{
    font-size:14px;
    line-height:20px;
  }
  .banner--match .banner__scoreboard__image{
    width:90px;
    height:90px;
  }
  .banner--match .banner__scoreboard__image img{
    max-height:55px;
    max-width:52px;
  }
  .banner--match .banner__scoreboard>div{
    width:inherit;
  }
  .banner--match .banner__scoreboard>div h3{
    max-width:158px;
    margin-left:auto;
    margin-right:auto;
  }
  .banner--match .banner__scoreboard>div:first-child{
    width:200px;
    padding-top:0;
  }
  .banner--match .banner__scoreboard>div:nth-child(2){
    width:inherit;
    padding-top:16px;
    margin-left:48px;
    margin-right:48px;
  }
  .banner--match .banner__scoreboard>div:nth-child(2) p{
    line-height:28px;
  }
  .banner--match .banner__scoreboard>div:nth-child(2) .label{
    margin-top:8px;
  }
  .banner--match .banner__scoreboard>div:last-child{
    width:200px;
    padding-top:0;
  }
  .banner--match .banner__scoreboard>div .banner__scoreboard__score p{
    font-size:40px;
    line-height:48px;
  }
  .banner--match .banner__scoreboard>div .banner__scoreboard__score p:nth-child(2){
    margin-left:24px;
    margin-right:24px;
  }
  .banner--match .banner__scoreboard h2+.label.label--long{
    margin-top:8px;
  }
  .banner--match .banner__scoreboard .label--long{
    white-space:nowrap;
    overflow:auto;
    max-width:100%;
    padding-bottom:3px;
  }
  .banner--match .banner__scoreboard .direct-dot{
    display:inline-block;
    width:7px;
    height:7px;
    border-radius:50%;
    margin-right:8px;
    background-color:#fff;
    -webkit-transform:translateY(-1px);
            transform:translateY(-1px);
  }
  .banner--wButton{
    min-height:inherit;
  }
  .banner--wButton.has-score{
    min-height:530px;
    padding-top:169px;
  }
  .banner--wButton hr{
    bottom:70px;
  }
  .banner--match-ended,.banner--match-live{
    min-height:470px;
  }
  .banner--match-ended.has-score,.banner--match-live.has-score{
    min-height:545px;
    padding-top:169px;
  }
  .banner--match-ended hr,.banner--match-live hr{
    bottom:70px;
  }
  .banner.banner--live-score{
    padding-top:169px;
  }
  .banner--team-cat{
    height:430px;
  }
  .banner--team-cat h1{
    margin-top:56px;
  }
  .banner--team-cat .banner__desc{
    max-width:inherit;
  }
  .banner--team-cat+.container{
    margin-top:-167px;
  }
}

@media (min-width:1080px){
  .home-slider.has-score .live-results{
    top:106px;
  }
  .banner{
    padding-top:170px;
    height:448px;
  }
  .banner.has-score{
    height:536px;
    margin-top:40px;
    padding-top:210px;
  }
  .banner.has-score .live-results{
    top:106px;
  }
  .banner .breadcrumb{
    position:relative;
    top:10px;
  }
  .banner .search-club{
    margin-left:8.33333%;
  }
  .banner .search-club .btn{
    left:726px;
    right:0;
    width:270px;
    padding:0 32px;
    background-color:#fff;
    border-radius:4px;
  }
  .banner .search-club .btn span{
    display:inline;
  }
  .banner .search-club .btn i{
    margin-right:8px;
  }
  .banner .search-club .input input{
    padding-right:24px;
  }
  .banner .search-club__autocompletion{
    width:100%;
  }
  .banner--big{
    margin-bottom:-326px;
  }
  .banner--big:after{
    background-size:100% 200px;
  }
  .banner--searchClub{
    margin-bottom:-56px;
  }
  .banner--faq{
    margin-bottom:-348px;
  }
  .banner--center h1,.banner--center p{
    margin-left:16.66667%;
  }
  .banner--search{
    height:244px;
    padding-top:16px;
  }
  .banner--match{
    padding-top:170px;
    height:540px;
  }
  .banner--match.banner--bigNoAfter{
    height:540px;
    margin-bottom:0;
  }
  .banner--match>.container{
    padding:8px 56px 0;
  }
  .banner--match h1{
    margin-top:48px;
  }
  .banner--match .banner__scoreboard{
    display:block;
  }
  .banner--match .banner__scoreboard__image{
    width:110px;
    height:110px;
  }
  .banner--match .banner__scoreboard__image img{
    max-height:67px;
    max-width:63px;
  }
  .banner--match .banner__scoreboard>div:nth-child(2){
    margin-left:100px;
    margin-right:100px;
  }
  .banner--match .banner__scoreboard>div:first-child,.banner--match .banner__scoreboard>div:last-child{
    width:244px;
  }
  .banner--match .banner__scoreboard>div .banner__scoreboard__score p{
    font-size:48px;
    line-height:56px;
  }
  .banner--match .banner__scoreboard>div .banner__scoreboard__score p:nth-child(2){
    margin-left:32px;
    margin-right:32px;
  }
  .banner--match .banner__infos{
    padding-left:56px;
    padding-right:56px;
  }
  .banner--match hr{
    width:calc(100% - 120px);
  }
  .banner--match .banner__button--score.is-live{
    bottom:52px;
  }
  .has-score.banner--match-live,.has-score.banner--wButton{
    height:590px;
  }
  .banner--match-live:not(.has-score)>.container{
    padding-top:0;
  }
  .banner--match-ended hr{
    display:none;
  }
  .banner--match-ended .game-date{
    position:absolute;
    bottom:61px;
    left:50%;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
    width:100%;
    display:inline-block;
    color:hsla(0, 0%, 100%, .6);
    padding:0 60px;
  }
  .banner--match-ended .game-date:after,.banner--match-ended .game-date:before{
    position:absolute;
    content:"";
    height:1px;
    top:12px;
    width:calc(45% - 60px);
    background-color:hsla(0, 0%, 100%, .2);
  }
  .banner--match-ended .game-date:before{
    left:60px;
  }
  .banner--match-ended .game-date:after{
    right:60px;
  }
  .banner.banner--live-score>.container{
    padding:0 60px;
  }
  .banner--team-cat{
    height:608px;
  }
  .banner--team-cat h1{
    margin-top:80px;
  }
  .banner--team-cat+.container{
    margin-top:-217px;
  }
  .banner.banner--live-score{
    min-height:560px;
    padding-top:255px;
  }
  .banner.banner--live-score .live-results{
    top:105px;
  }
}

@media (min-width:1280px){
  .banner-over .card{
    left:-40px;
    width:calc(100% + 40px);
  }
  .banner.banner--live-score{
    min-height:560px;
    padding-top:255px;
  }
  .banner.banner--live-score .live-results{
    top:105px;
  }
  .banner--team h1{
    margin-top:75px;
  }
}

.banner--contact{
  min-height:372px;
}

.banner--contact+.container .page{
  margin-top:-70px;
  padding-top:32px;
}

.banner--events{
  min-height:372px;
  padding-top:92px;
}

.banner--events+.container{
  margin-top:-70px;
}

.banner--map{
  z-index:2;
}

.banner--searchClub{
  height:728px;
}

.banner--big,.banner--bigNoAfter{
  height:544px;
}

.banner--big{
  margin-bottom:-260px;
}

.banner--big.banner--faq{
  margin-bottom:-342px;
}

.banner--big:after{
  background:inherit;
}

.banner--bigNoAfter{
  margin-bottom:-150px;
}

.banner--bigNoAfter h1{
  margin-top:112px;
}

.banner--compact{
  min-height:206px;
}

.banner--themes{
  min-height:444px;
}

.banner--themes .home-slider__links{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.banner--themes .home-slider__links .billboard__sub{
  top:150%;
  opacity:0;
  visibility:hidden;
  color:#292929;
}

.banner--themes .home-slider__links .billboard__sub li{
  display:block;
}

.banner--themes .home-slider__links .billboard__sub li:first-child{
  opacity:1;
}

.banner--themes .home-slider__links li{
  font-size:14px;
  margin-right:8px;
}

.banner--themes .home-slider__links li:hover .billboard__sub{
  opacity:1;
  visibility:visible;
}

.banner--themes .home-slider__links li a:focus{
  color:inherit;
}

.banner--themes .home-slider__links li.is-active{
  display:inline-block;
}

.banner--themes .home-slider__links li.is-active a{
  text-decoration:underline;
}

.banner--themes .home-slider__links li.see-all{
  display:inline-block;
}

.banner--themes .home-slider__links li.see-all:after{
  position:absolute;
  content:"\e906";
  line-height:22px;
  margin-left:4px;
  color:#7d93a8;
  font-family:icon;
  float:right;
  font-size:6px;
}

@media (min-width:768px){
  .banner--themes .home-slider__links li.see-all:after{
    display:none;
  }
}

.banner--themes .dropdown{
  position:relative;
  top:4px;
  font-size:14px;
}

.banner--themes .dropdown .dropdown__head{
  font-size:14px;
}

.banner--themes .dropdown .dropdown__label{
  color:#fff;
  text-transform:uppercase;
  text-decoration:underline;
}

.banner--themes .dropdown.is-open .dropdown__label{
  color:#292929;
  text-decoration:initial;
}

.banner--cat h1{
  display:inline-block;
  margin-right:16px;
  opacity:.72;
  margin-top:80px;
}

.banner--cat .menu-team{
  display:inline-block;
  min-width:140px;
}

.banner--cat .menu-team h1{
  display:inline-block;
  border-bottom:2px solid #fff;
  opacity:1;
}

.banner--cat .menu-team .accordion-panel>li{
  width:100%;
}

@media (min-width:768px){
  .banner--big,.banner--bigNoAfter{
    height:544px;
    margin-bottom:-150px;
  }
  .banner--compact{
    height:300px;
  }
  .banner--searchClub{
    height:812px;
  }
  .banner--events h1{
    margin-top:47px;
  }
  .banner--themes .home-slider__links li{
    margin-right:8px;
  }
  .banner--themes .home-slider__links .billboard__sub li{
    display:block;
  }
  .banner--cat h1{
    margin-top:48px;
  }
  .banner--cat .menu-team{
    min-width:450px;
  }
  .banner--cat .menu-team h2{
    font-size:40px;
    line-height:56px;
  }
  .banner--cat .breadcrumb{
    margin-top:0;
  }
}

@media (min-width:1080px){
  .banner--big,.banner--bigNoAfter,.banner--lounge{
    height:688px;
  }
  .banner--events{
    padding-top:170px;
  }
  .banner--big{
    margin-bottom:-350px;
  }
  .banner--compact{
    height:356px;
  }
  .banner--searchClub{
    height:885px;
  }
  .banner--team{
    height:504px;
  }
}

.breadcrumb{
  list-style:none;
  margin-bottom:0;
  color:#999;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.breadcrumb li{
  display:inline-block;
}

.breadcrumb li:after{
  content:"\e924";
  font-family:icon;
  font-size:6px;
  position:relative;
  top:-1px;
  margin:0 8px;
}

.breadcrumb li:last-child{
  color:#292929;
}

.breadcrumb li:last-child:after{
  display:none;
}

.breadcrumb a{
  display:inline-block;
}

.breadcrumb a:hover{
  color:#292929;
}

.breadcrumb.is-dark{
  color:hsla(0, 0%, 100%, .6);
}

.breadcrumb.is-dark li:last-child{
  color:#fff;
}

.breadcrumb.is-dark a:hover{
  color:hsla(0, 0%, 100%, .8);
}

.card{
  display:block;
  position:relative;
  width:100%;
  height:424px;
  overflow:hidden;
}

.card:hover{
  color:inherit;
}

.card:hover .img-blue:before{
  opacity:.7;
}

.card .icon-play--circle{
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-24px;
  margin-top:-24px;
}

.card hr{
  border:0;
  border-bottom:1px solid #ebebeb;
  margin:24px 0;
}

.card__thumbnail{
  position:absolute;
  top:0;
  height:240px;
  width:100%;
  background:no-repeat 50%;
  background-size:cover;
}

.card__thumbnail img{
  display:none;
}

.card__body,.card__footer{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  padding:16px 24px;
}

.card__body{
  height:184px;
}

.card__flex-body{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}

.card__title{
  margin-bottom:8px;
}

.card__desc{
  color:#999;
  display:-webkit-box;
  line-clamp:4;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

.card__date{
  color:hsla(0, 0%, 100%, .8);
}

.card__meta{
  color:#7d93a8;
}

.card__meta i{
  width:20px;
  font-size:12px;
  top:-1px;
}

.card__url{
  text-transform:uppercase;
  font-size:12px;
  font-weight:800;
  color:#ccc;
}

.card__type{
  left:16px;
}

.card__social,.card__type{
  position:absolute;
  top:24px;
}

.card__social{
  right:16px;
}

.card__social .label{
  margin-left:4px;
}

.card__row{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

.card--content:hover .card__body{
  height:204px;
}

.card--content:hover .card__thumbnail{
  -webkit-transform:scale(1.02);
          transform:scale(1.02);
}

.card--content:hover .img-blue:before{
  opacity:.3;
}

.card--content .card__thumbnail{
  -webkit-transition:-webkit-transform .3s ease;
  transition:-webkit-transform .3s ease;
  transition:transform .3s ease;
  transition:transform .3s ease,-webkit-transform .3s ease;
}

.card--content .img-blue:before{
  opacity:0;
}

.card--content .card__body{
  background-color:#fff;
  border-top:4px solid #e6679a;
  -webkit-transition:height .3s ease;
  transition:height .3s ease;
}

.card--wide .card__body{
  height:147px;
  padding:24px;
}

.card--wide .card__title{
  margin-bottom:3px;
}

.card--wide .card__thumbnail{
  height:278px;
}

.sidebar .card--content{
  height:316px;
}

.sidebar .card--content:hover .card__body{
  height:200px;
}

.sidebar .card--content .card__thumbnail{
  height:136px;
}

.sidebar .card--content .card__body{
  height:180px;
}

.card--picture.card-player--compact .card__thumbnail--empty span{
  width:56px;
  height:50px;
}

.card--picture .img-blue:before{
  display:none;
}

.card--picture .card__thumbnail{
  height:304px;
  background:no-repeat top;
  background-size:cover;
}

.card--picture .card__thumbnail--empty{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  background-color:#ebebeb;
}

.card--picture .card__thumbnail--empty span{
  display:block;
  width:204px;
  height:168px;
  background-size:100%;
  background-repeat:no-repeat;
}

.card--picture .card__body{
  height:120px;
}

.card--picture .card__title{
  color:#292929;
}

.card--pictureFavorite{
  height:388px;
}

.card--pictureFavorite .card__body{
  height:100px;
  background:#fff;
}

.card--pictureFavorite .card__footer .btn{
  position:absolute;
  top:17px;
  right:24px;
}

.card--mvp{
  display:block;
  margin:0 auto;
  max-width:341px;
  height:336px;
}

.card--mvp .card__thumbnail{
  height:244px;
}

.card--mvp .card__body{
  height:92px;
  padding:0 24px;
}

.card--mvp .card__body>div{
  display:inline-block;
  vertical-align:top;
}

.card--mvp .card__body>div .card__title{
  font-size:16px;
  color:#0f2842;
}

.card--mvp .card__body>div .card__desc{
  font-size:12px;
  color:rgba(15, 40, 66, .4);
  margin-bottom:0;
}

.card--mvp .card__body>div .card__desc i{
  margin-right:8px;
}

.card--mvp .card__body>div:first-child{
  padding-right:16px;
  border-right:1px solid #ccc;
  margin-top:24px;
}

.card--mvp .card__body>div:last-child{
  padding-left:24px;
  margin-top:14px;
}

.card--mvp .card__body>div:last-child p{
  font-size:24px;
  color:#0f2842;
  margin-bottom:0;
}

.card--mvp .card__body>div:last-child span{
  color:rgba(15, 40, 66, .4);
  font-size:12px;
  text-transform:uppercase;
  display:block;
  line-height:14px;
}

.card--compact{
  height:353px;
}

.card--compact:hover .card__body{
  height:133px;
}

.card--compact .card__body{
  height:113px;
}

.card--simple{
  height:auto;
}

.card--simple .card__body,.card--simple .card__footer{
  position:static;
  height:auto;
  padding:24px;
}

.card--simple .card__body{
  padding-bottom:16px;
}

.card--simple .card__footer{
  padding-top:0;
}

.card--date{
  height:324px;
}

.card--date .card__label{
  position:absolute;
  top:16px;
  right:24px;
}

.card--date .card__label.label--transparent{
  top:auto;
  right:auto;
  bottom:108px;
  left:24px;
  -webkit-transition:-webkit-transform .3s ease;
  transition:-webkit-transform .3s ease;
  transition:transform .3s ease;
  transition:transform .3s ease,-webkit-transform .3s ease;
}

.card--date:hover .card__body{
  height:113px;
}

.card--date:hover .card__label.label--transparent{
  -webkit-transform:translateY(-29px);
          transform:translateY(-29px);
}

.card--date .card__body{
  padding:20px 24px;
  height:84px;
}

.card--date .card__thumbnail:before{
  opacity:.4;
  background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(41, 41, 41, .2)), to(rgba(41, 41, 41, .6)));
  background-image:linear-gradient(180deg, rgba(41, 41, 41, .2), rgba(41, 41, 41, .6));
}

.card--date .card__title{
  margin-bottom:4px;
  color:#292929;
}

.card--billboard:hover .card__thumbnail,.card--hub-alt:hover .card__thumbnail,.card--hub:hover .card__thumbnail,.card--news-richscore:hover .card__thumbnail{
  -webkit-transform:scale(1.02);
          transform:scale(1.02);
}

.card--billboard:hover .img-blue-dark:before,.card--hub-alt:hover .img-blue-dark:before,.card--hub:hover .img-blue-dark:before,.card--news-richscore:hover .img-blue-dark:before{
  opacity:.7;
}

.card--billboard .card__thumbnail,.card--hub-alt .card__thumbnail,.card--hub .card__thumbnail,.card--news-richscore .card__thumbnail{
  -webkit-transition:-webkit-transform .3s ease;
  transition:-webkit-transform .3s ease;
  transition:transform .3s ease;
  transition:transform .3s ease,-webkit-transform .3s ease;
  will-change:transform;
  border-radius:4px;
}

.card--billboard .img-blue,.card--billboard .img-blue:after,.card--billboard .img-blue:before,.card--hub-alt .img-blue,.card--hub-alt .img-blue:after,.card--hub-alt .img-blue:before,.card--hub .img-blue,.card--hub .img-blue:after,.card--hub .img-blue:before,.card--news-richscore .img-blue,.card--news-richscore .img-blue:after,.card--news-richscore .img-blue:before{
  width:100%;
  border-radius:4px;
}

.card--billboard .img-blue-dark:before,.card--hub-alt .img-blue-dark:before,.card--hub .img-blue-dark:before,.card--news-richscore .img-blue-dark:before{
  opacity:.5;
}

.card--billboard .img-blue-dark:after,.card--hub-alt .img-blue-dark:after,.card--hub .img-blue-dark:after,.card--news-richscore .img-blue-dark:after{
  background:-webkit-gradient(linear, left top, left bottom, color-stop(14%, rgba(0, 57, 116, 0)), color-stop(79%, #003974));
  background:linear-gradient(-180deg, rgba(0, 57, 116, 0) 14%, #003974 79%);
  opacity:.72;
}

.card--hub-ffr .card__thumbnail.img-blue-dark:before{
  background-color:#013974;
  opacity:.88;
}

.card--hub-ffr .card__thumbnail.img-blue-dark:after{
  display:none;
}

.card--hub .card__thumbnail{
  height:100%;
}

.card--hub .card__body{
  position:relative;
  max-width:300px;
  height:auto;
  padding:32px 32px 96px;
  color:#fff;
}

.card--hub .card__footer{
  padding:32px;
}

.card--hub.has-siblings .card__body,.card--hub.has-siblings .card__footer{
  padding:32px 24px 8px;
  max-width:unset;
}

@media (min-width:1280px){
  .card--hub.has-siblings .card__body,.card--hub.has-siblings .card__footer{
    padding:32px 48px 24px;
  }
}

.card--hub .card__desc{
  color:hsla(0, 0%, 100%, .8);
}

.card--hub.card--small{
  height:auto;
  min-height:288px;
}

.card--hub--big .card__body{
  max-width:100%;
}

.card--hub-mobile-bcksp .card__body{
  max-width:290px;
}

.card--hub--compact{
  height:316px;
}

.card--hub--compact .label{
  font-size:10px;
  line-height:18px;
  height:17px;
  margin-right:16px;
}

.card--hub--compact .card__body{
  position:absolute;
  bottom:96px;
  padding-bottom:0;
}

.card--hub--compact .card__title{
  margin-bottom:16px;
}

.card--hub--compact .card__infodate{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.card--hub--compact .card__infodate p{
  font-size:12px;
  line-height:16px;
  margin-bottom:0;
}

.card--billboard{
  height:224px;
}

.card--billboard.is-home{
  height:272px;
}

.card--billboard.has-score{
  height:350px;
  background-color:transparent;
  overflow:inherit;
}

.card--billboard.has-score,.card--billboard.has-score:hover{
  -webkit-box-shadow:none;
          box-shadow:none;
}

.card--billboard.has-score .card--billboard__content{
  height:272px;
}

.card--billboard.has-score .card--billboard__content .card__body{
  bottom:80px;
}

.card--billboard.inset-score .card__body{
  bottom:63px;
  padding-bottom:16px;
}

.card--billboard.inset-score .match{
  position:absolute;
  bottom:8px;
  left:8px;
  right:8px;
  border:1px solid #eee;
  margin-top:8px;
  height:55px;
  min-height:55px;
  padding:8px 16px;
  background-color:#fff;
  -webkit-box-shadow:0 3px 8px 0 rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px 0 rgba(51, 51, 51, .08);
}

.card--billboard.inset-score .match img{
  width:28px;
  height:28px;
  border-radius:100%;
  padding:5px;
  background:#fff;
  border:1px solid #ebebeb;
  margin-top:-12px;
}

.card--billboard.inset-score .match .match__club{
  font-size:14px;
}

.card--billboard.inset-score .match .match__club:first-child{
  padding-right:38px;
  text-align:left;
}

.card--billboard.inset-score .match .match__club:last-child{
  padding-left:42px;
  text-align:right;
}

.card--billboard .card--billboard__content{
  position:relative;
  overflow:hidden;
  height:100%;
}

.card--billboard .card--billboard__content .card__body{
  color:#fff;
}

.card--billboard .card--score{
  margin:-80px auto 0;
  width:calc(100% - 16px);
}

.card--billboard .card__thumbnail{
  height:100%;
}

.card--billboard .card__body{
  height:auto;
  padding:24px 16px;
}

.card--billboard .card__title{
  font-size:20px;
  line-height:24px;
  margin-bottom:0;
}

.card--billboard .card__footer{
  display:none;
}

.card--billboard .card__type{
  top:16px;
}

.card--billboard .match__club:first-child{
  text-align:left;
}

.card--billboard .match__club:last-child{
  text-align:right;
}

.card--hub-alt .card__thumbnail{
  height:100%;
}

.card--hub-alt .card__body{
  height:auto;
  padding:32px 32px 130px;
  color:#fff;
}

.card--hub-alt .card__footer{
  padding:32px;
}

.card--hub-alt .card__desc{
  color:hsla(0, 0%, 100%, .8);
}

.card--hub-alt.card--small{
  height:auto;
  min-height:288px;
}

.card--hub-alt.card--small .card__body{
  position:relative;
  padding:24px;
}

.card--hub-alt.card--small .card__footer{
  padding:24px;
}

.card--hub-alt.card--xSmall{
  min-height:242px;
}

.card--hub-alt.card--xSmall h3{
  font-size:30px;
  line-height:38px;
}

.card--hub.card--small.card--xSmall{
  min-height:242px;
}

.card--hub.card--small.card--xSmall h3{
  font-size:30px;
  line-height:38px;
}

.card--hub-alt--sidebar{
  height:316px;
}

.card--hub-alt--sidebar .card__body{
  padding-bottom:92px;
}

.card--hub-alt--sidebar .link-icon{
  color:#fff;
}

.card--social{
  height:376px;
  background-color:rgba(0, 0, 0, .16);
}

.card--social .card__thumbnail{
  height:180px;
}

.card--social .card__thumbnail+.card__body{
  height:196px;
  padding:16px 24px;
}

.card--social .card__body{
  height:100%;
  padding:24px 32px;
}

.card--social .card__desc{
  display:-webkit-box;
  -webkit-line-clamp:5;
  -webkit-box-orient:vertical;
  overflow:hidden;
  color:hsla(0, 0%, 100%, .8);
}

.card--social .card__desc strong{
  font-weight:500;
  color:#fff;
}

.card--social .card__footer{
  background-color:rgba(0, 0, 0, .2);
  padding-top:8px;
  padding-bottom:8px;
  padding-left:16px;
}

.card--social .card__social{
  top:12px;
  right:12px;
}

.card--social .card__stat i,.card__stat{
  color:#fff;
}

.card__stat{
  float:right;
}

.card__stat i{
  font-size:12px;
  color:#7d93a8;
  margin-right:8px;
}

.card__stat span{
  margin-left:16px;
}

.card--user{
  height:100%;
}

.card--user .card__thumbnail{
  width:84px;
  height:100%;
  background-position:top;
}

.card--user .card__body,.card--user .card__footer{
  margin-left:84px;
}

.card--user .card__body{
  height:142px;
  width:calc(100% - 84px);
  padding:8px 24px;
}

.card--user .card__desc{
  font-size:12px;
  line-height:15px;
}

.card--user .card__title{
  margin-bottom:4px;
  line-height:24px;
  display:-webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical;
  display:block;
  overflow:visible;
}

.card--user .card__footer{
  font-size:14px;
  font-weight:500;
  padding:8px 24px;
}

.card--user .card__footer i{
  color:#7d93a8;
}

a.card.card--competition:hover .link-chevron,a.card.card--competition:hover .link-chevron i{
  color:#fff;
}

.card--competition{
  height:424px;
}

.card--competition .card__thumbnail{
  height:100%;
}

.card--competition .card__body{
  height:100%;
  padding:56px 24px;
  color:#fff;
  text-align:center;
}

.card--competition .card__body>img{
  margin-bottom:16px;
}

.card--competition .card__title{
  margin-bottom:90px;
}

.card--competition .card__desc{
  color:hsla(0, 0%, 100%, .6);
}

.card--competition .card__desc b{
  color:#fff;
}

.card--competition .icon-shield-alt{
  font-size:56px;
  display:block;
  margin-bottom:16px;
}

.card--competition ul{
  text-align:left;
}

.card--competition ul img{
  margin-right:8px;
  border-radius:2px;
}

.card--competition li{
  margin-bottom:16px;
}

.card--competition.is-logged .card__thumbnail{
  background-position:top;
}

.card--competition.is-logged .card__body{
  padding-top:24px;
}

.card--competition.is-logged .card__body>img{
  float:left;
}

.card--competition.is-logged .card__title{
  float:left;
  margin-bottom:109px;
  margin-left:16px;
}

.card--competition.is-logged .card__desc{
  clear:both;
}

.card--competition.is-logged .card__footer{
  padding-top:80px;
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, 0)), to(#003974));
  background:linear-gradient(180deg, rgba(0, 57, 116, 0) 0, #003974);
}

.card--competition.has-club .card__title{
  margin-bottom:70px;
}

.card--competition.has-club .card__desc{
  margin-bottom:32px;
}

.card--competition.has-club .card__footer{
  padding-top:70px;
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, .48)), to(#003974));
  background:linear-gradient(180deg, rgba(0, 57, 116, .48) 0, #003974);
}

.card--competition.has-club ul{
  height:80px;
}

.card--competition.card--small{
  height:auto;
}

.card--competition.card--small .card__body{
  position:relative;
}

.card--game{
  height:432px;
}

.card--game .card__thumbnail{
  height:100%;
}

.card--game .card__body{
  position:relative;
  height:100%;
  padding:32px 24px 88px;
  color:#fff;
  text-align:center;
}

.card--game .card__footer{
  padding-bottom:24px;
}

.card--game .card__desc{
  color:#292929;
}

.card--game.card--smallBlue .card__desc{
  color:hsla(0, 0%, 100%, .6);
}

.card--game.card--small{
  height:auto;
}

.card--game.card--small .card__body{
  padding-top:24px;
}

.card--game.card--small .card__team p{
  line-height:22px;
  margin-bottom:0;
}

.card__team{
  color:#fff;
  font-weight:700;
  text-align:left;
  font-family:Din;
  margin-top:8px;
}

.card__team p{
  width:50%;
  min-height:44px;
  margin-bottom:24px;
  color:#fff;
}

.card__team p:last-child{
  text-align:right;
}

.card__status{
  color:hsla(0, 0%, 100%, .6);
}

.card--nextgame{
  height:auto;
}

.card--nextgame .card__thumbnail{
  height:100%;
}

.card--nextgame .card__body{
  position:relative;
  height:100%;
  padding:32px;
  color:#fff;
  text-align:center;
}

.card--nextgame .card__footer{
  padding-bottom:32px;
  position:relative;
  padding-top:0;
}

.card--nextgame .card__title{
  display:none;
}

.card--nextgame .card__desc{
  color:hsla(0, 0%, 100%, .6);
}

.card--nextgame .match{
  position:relative;
  padding:0;
  min-height:40px;
  margin-bottom:64px;
}

.card--nextgame .match__info{
  position:absolute;
  top:100%;
  width:100%;
  margin-top:16px;
}

.card--nextgame .match__time{
  color:#fff;
}

.card--nextgame .match__club:first-child{
  margin-right:20px;
  padding-right:56px;
}

.card--nextgame .match__club:last-child{
  padding-left:56px;
}

.card--nextgame .match__club img{
  width:40px;
  height:40px;
  margin-top:-20px;
  border-radius:100%;
  padding:8px;
  background:#fff;
}

.card--club{
  min-height:140px;
  height:auto;
}

.card--club:focus,.card--club:hover{
  text-decoration:none;
}

.card--club:focus .card__title,.card--club:hover .card__title{
  text-decoration:underline;
}

.card--club img{
  position:absolute;
  left:16px;
  top:50%;
  margin-top:-24px;
}

.card--club .card__body{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  position:static;
  height:100%;
  width:calc(100% - 80px);
  margin-left:80px;
  border-left:1px solid #ebebeb;
}

.card--club .card__body>div{
  width:100%;
}

.card--club .card__title{
  margin-bottom:2px;
  padding-right:24px;
  font-size:15px;
}

.card--club .label{
  margin:8px 8px 0 0;
}

.card--club .card__position{
  display:none;
}

.card--club.card--small{
  height:72px;
}

.card--club.card--small .label{
  display:none;
}

.card--club.card--small .card__position{
  display:block;
}

.card--club.card--small img{
  width:32px;
  height:32px;
  margin-top:-16px;
  left:20px;
}

.card--club.card--small .card__body{
  width:calc(100% - 72px);
  margin-left:72px;
}

.card--clubSmall .card__title{
  font-size:inherit;
  line-height:inherit;
}

.card--clubSmall .card__body{
  padding:30px 30px 22px;
  margin-left:0;
  border-left:0;
}

@media (max-width:768px){
  .card--clubSmall .card__body{
    width:100%;
  }
}

.card--clubSmall .card__desc i{
  color:#7d93a8;
}

.card--clubSmall .card__desc--address i{
  position:relative;
  vertical-align:top;
  top:5px;
}

.card--clubSmall .card__desc--address span{
  display:inline-block;
}

.card--clubSmall.box:hover{
  -webkit-box-shadow:0 0 0 2px inset #e6679a, 0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:inset 0 0 0 2px #e6679a,0 10px 16px rgba(51, 51, 51, .08);
}

.card--clubSmall.box:hover h4{
  text-decoration:none;
}

.card--clubSmall.box:hover .card__position{
  background:#e6679a;
  color:#fff;
}

.card__position{
  position:absolute;
  right:16px;
  top:16px;
  min-width:24px;
  height:24px;
  line-height:26px;
  text-align:center;
  border-radius:100%;
  background:#f0f2f5;
  color:#999;
}

.card--download{
  height:auto;
}

.card--download .card__body{
  position:relative;
  height:auto;
  padding:24px 24px 88px;
}

.card--download .card__title{
  margin-bottom:0;
}

.card--download .card__title a{
  margin-right:8px;
}

.card--download .card__desc{
  color:#ccc;
}

.card--download .card__footer{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding:16px 24px 24px;
}

.card--download .card__category{
  margin-bottom:0;
  color:#e6679a;
}

.card--download .label{
  top:-2px;
}

.card--download .share{
  margin-right:8px;
}

.card--data{
  height:auto;
}

.card--data .card__thumbnail{
  height:100%;
}

.card--data p{
  position:relative;
  width:216px;
  height:216px;
  margin:24px auto;
  padding-top:70px;
  border:6px solid #fff;
  border-radius:100%;
  color:hsla(0, 0%, 100%, .8);
  text-align:center;
}

.card--data p span{
  max-width:170px;
  display:block;
  margin:0 auto;
}

.card--data strong{
  display:block;
  font-size:48px;
  line-height:1;
  font-family:Din;
  color:#fff;
}

.card--interact{
  height:auto;
  padding:24px;
}

.card--interact .card__body{
  position:relative;
  padding:0 0 16px;
  height:auto;
}

.card--interact .card__body p{
  line-height:28px;
  margin-bottom:0;
}

.card--address{
  height:188px;
  border-top:4px solid #e6679a;
  border-radius:0 0 4px 4px;
}

.card--address .card__body{
  height:100%;
}

.card.card--ticket{
  padding:0;
  margin-top:0;
}

.card--ticket{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:auto;
}

.card--ticket:not(.box){
  border:1px solid #ebebeb;
  background-color:#fff;
  border-radius:4px;
}

.card--ticket .card__header{
  position:relative;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  width:120px;
  padding:24px 16px;
}

.card--ticket .card__header,.card--ticket .transport{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.card--ticket .transport{
  margin-top:-40px;
}

.card--ticket .transport img:first-child{
  margin-right:4px;
}

.card--ticket .transport span{
  position:absolute;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  bottom:24px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  font-size:12px;
}

.card--ticket .transport i{
  color:#fff;
}

.card--ticket .formule .icon{
  display:block;
  margin-bottom:16px;
  color:#e6679a;
}

.card--ticket .formule .icon:last-child{
  margin-bottom:0;
}

.card--ticket .formule[data-level="1"] .icon:nth-child(n+2),.card--ticket .formule[data-level="2"] .icon:nth-child(n+3){
  color:rgba(230, 103, 154, .24);
}

.card--ticket .card__body{
  position:relative;
  width:calc(100% - 120px);
  height:auto;
  padding:24px 24px 24px 16px;
}

.card--ticket ul li:last-child{
  margin-bottom:0;
}

.card--ticket:not(.card--ticket--nolicense) ul li{
  position:relative;
  padding-left:12px;
  margin-bottom:8px;
}

.card--ticket:not(.card--ticket--nolicense) ul li:before{
  position:absolute;
  content:"";
  left:0;
  top:12px;
  width:3px;
  height:3px;
  border-radius:50%;
  background-color:#999;
}

.card--ticket--home{
  height:216px;
  cursor:pointer;
}

.card--ticket--home .card__header{
  background-color:rgba(15, 40, 66, .08);
}

.card--partner{
  display:block;
  height:330px;
  margin-bottom:16px;
}

.card--partner .card__header{
  height:150px;
  width:100%;
}

.card--partner .card__header img{
  height:55px;
}

.card--partner .card__body{
  width:100%;
}

.card--video{
  height:216px;
}

.card--video:hover .img-blue-dark:before{
  opacity:.4;
}

.card--video h5,.card--video p{
  margin:0;
}

.card--video h5{
  margin-top:8px;
}

.card--video .img-blue-dark:before{
  opacity:0;
}

.card--video .img-blue-dark:after{
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(1, 57, 116, 0)), to(#013974));
  background:linear-gradient(180deg, rgba(1, 57, 116, 0) 0, #013974);
  background-repeat:repeat-y;
  opacity:.82;
}

.card--video .card__thumbnail{
  height:100%;
  border-radius:4px;
}

.card--video .card__footer{
  padding:16px;
  color:#fff;
}

.card--video .link-icon{
  float:left;
}

.card--video .card__type{
  top:16px;
}

.card--video .card__date{
  font-size:14px;
}

.card--result .card__header{
  padding:16px 24px;
  border-bottom:1px solid #ebebeb;
}

.card--result .card__body{
  position:relative;
  height:auto;
  padding-top:24px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .card__body{
    padding:24px 16px 16px;
  }
}

.card--result .card__body>p{
  margin-bottom:0;
}

.card--result .card__body .ft-up{
  margin-bottom:8px;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}

.card--result .card__body .card__body__dots{
  position:absolute;
  top:33px;
  right:24px;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .card__body .card__body__dots{
    display:none;
  }
}

.card--result .card__body .card__body__dots span{
  border-radius:50%;
  width:10px;
  height:10px;
  background:#ccc;
  margin-right:5px;
}

.card--result .card__body .card__body__dots span:last-child{
  margin-right:0;
}

.card--result .card__body .card__body__dots span.is-color{
  background:#e6679a;
}

.card--result .card__footer{
  padding:0;
  border-top:1px solid #ebebeb;
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .card__footer a{
    padding:16px;
  }
  .card--result .card__footer a i{
    top:-10px;
  }
}

.card--result .match{
  padding:0;
  height:auto;
  min-height:40px;
  margin-bottom:8px;
  font-size:15px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .match{
    height:30px;
  }
}

.card--result .match__club{
  font-size:14px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .match__club{
    width:40%;
  }
  .card--result .match__club img{
    width:20px;
    height:20px;
  }
  .card--result .match__club:first-child{
    padding-right:24px;
    text-align:left;
  }
  .card--result .match__club:last-child{
    padding-left:28px;
  }
}

@media (min-width:768px) and (max-width:1279px){
  .card--result .match__info{
    width:70px;
    -ms-flex-negative:0;
    flex-shrink:0;
  }
}

.card--result .match__score{
  font-size:20px;
}

@media (min-width:1280px){
  .card--result .match__score{
    width:100px;
  }
}

.card--result .ft-h6{
  color:#e6679a;
}

.card--result .ft-center{
  color:#999;
}

.card--nextGameWhite .card__body{
  top:0;
  height:calc(100% - 54px);
  padding:24px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--nextGameWhite .card__body{
    padding:24px 16px 16px;
  }
}

.card--nextGameWhite .card__body h3{
  margin-bottom:24px;
}

.card--nextGameWhite .card__body .label{
  position:absolute;
  top:24px;
  right:24px;
  max-width:100%;
}

@media (min-width:768px) and (max-width:1279px){
  .card--nextGameWhite .card__body .label{
    font-size:10px;
    right:16px;
  }
}

.card--nextGameWhite .card__body p:first-child{
  color:#e6679a;
  margin-bottom:5px;
}

.card--nextGameWhite .card__body p:nth-child(2){
  color:#999;
  line-height:inherit;
}

.card--nextGameWhite .card__body p:nth-child(5){
  color:#999;
}

.card--nextGameWhite .card__body img{
  margin-right:18px;
  height:24px;
  width:24px;
}

.card--nextGameWhite .card__body a:not(.btn):hover{
  color:inherit;
  text-decoration:none;
}

.card--nextGameWhite .card__body .btn{
  margin-top:auto;
}

@media (min-width:768px) and (max-width:1279px){
  .card--nextGameWhite .card__body .btn{
    padding:0;
  }
}

.card--nextGameWhite .card__footer{
  border-top:1px solid #ebebeb;
}

.card--rankingSmall .card__body{
  height:100%;
  padding-top:24px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body{
    padding:24px 16px 16px;
  }
}

.card--rankingSmall .card__body .card__body__head{
  position:relative;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
}

.card--rankingSmall .card__body .card__body__head .dropdown{
  position:relative;
  left:-16px;
  max-width:85%;
}

.card--rankingSmall .card__body .card__body__head .dropdown .dropdown__head{
  color:#e6679a;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  max-width:100%;
}

.card--rankingSmall .card__body .card__body__head .dropdown .dropdown__head:after{
  color:#e6679a;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__head .dropdown .dropdown__head:after{
    margin-left:8px;
  }
}

.card--rankingSmall .card__body .card__body__head span:not(.dropdown):nth-child(2){
  position:absolute;
  right:40px;
  margin-left:68px;
  line-height:47px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__head span:not(.dropdown):nth-child(2){
    right:0;
  }
}

.card--rankingSmall .card__body .card__body__head span:not(.dropdown):nth-child(3){
  position:absolute;
  right:0;
  line-height:47px;
  color:rgba(41, 41, 41, .3);
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__head span:not(.dropdown):nth-child(3){
    display:none;
  }
}

.card--rankingSmall .card__body .card__body__rank>div{
  position:relative;
  height:50px;
  line-height:50px;
  margin-bottom:12px;
  border-top:1px solid #f1f1f1;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__rank>div{
    margin-bottom:8px;
  }
}

.card--rankingSmall .card__body .card__body__rank>div:last-child{
  margin-bottom:0;
}

.card--rankingSmall .card__body .card__body__rank>div img{
  width:31px;
  margin-right:32px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__rank>div img{
    width:24px;
    margin-right:13px;
  }
}

.card--rankingSmall .card__body .card__body__rank>div p{
  display:inline-block;
}

.card--rankingSmall .card__body .card__body__rank>div span{
  font-size:18px;
}

.card--rankingSmall .card__body .card__body__rank>div span:first-child{
  color:rgba(41, 41, 41, .6);
  margin-right:32px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__rank>div span:first-child{
    margin-right:16px;
  }
}

.card--rankingSmall .card__body .card__body__rank>div span:nth-child(4){
  position:absolute;
  right:40px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__rank>div span:nth-child(4){
    right:0;
  }
}

.card--rankingSmall .card__body .card__body__rank>div span:nth-child(5){
  position:absolute;
  right:0;
  font-size:15px;
}

@media (min-width:768px) and (max-width:1279px){
  .card--rankingSmall .card__body .card__body__rank>div span:nth-child(5){
    display:none;
  }
}

.card--rankingSmall .card__body .card__body__rank>div.is-club p,.card--rankingSmall .card__body .card__body__rank>div.is-club span:nth-child(4){
  font-weight:700;
}

.card--rankingSmall .card__footer{
  border-top:1px solid #ebebeb;
}

.card--noHover,.card--noHover:hover{
  -webkit-box-shadow:0 0 0 1px #ebebeb;
          box-shadow:0 0 0 1px #ebebeb;
}

.card--actus{
  height:auto;
}

.card--actus:hover .img-blue:before{
  opacity:0;
}

.card--actus .news:last-of-type div:last-child{
  border-bottom:1px;
}

.card--actus.has-accordion-open .news:last-of-type div:last-child{
  border-bottom:1px solid #ebebeb;
}

.card--actus .card__body{
  position:inherit;
  height:auto;
  padding:24px 24px 0;
}

.card--actus .card__body .news:last-child div:last-child{
  border-bottom:0;
}

.card--actus .card__footer{
  position:inherit;
  border-top:1px solid #ebebeb;
  padding:0;
}

.card--actus .card__footer a{
  display:block;
  height:58px;
  line-height:58px;
}

.card--actus .card__footer a:hover{
  text-decoration:none;
}

.card--actus .card__footer a:hover span:nth-child(2),.card--actus .card__footer a:hover span:nth-child(3){
  color:#e6679a;
}

.card--actus .card__footer a span:nth-child(2),.card--actus .card__footer a span:nth-child(3){
  margin-left:5px;
  color:#7d93a8;
}

.card--actus .card__footer a.is-selected span:nth-child(2),.card--actus .card__footer a:after,.card--actus .card__footer a span:nth-child(3){
  display:none;
}

.card--actus .card__footer a.is-selected span:nth-child(3){
  display:inline-block;
}

.card--exp{
  padding:24px;
  border:1px solid #ebebeb;
  border-radius:4px;
  background:#fcfcfc;
  height:auto !important;
}

.card--exp i{
  margin-bottom:16px;
  width:40px;
  height:40px;
  border-radius:50%;
  border:2px solid #999;
  background:hsla(0, 0%, 60%, .08);
  color:#999;
  line-height:38px;
  font-size:12px;
}

.card--exp p{
  color:#999;
  margin-bottom:0;
  font-size:14px;
}

.card--exp p a{
  text-decoration:underline;
}

.card--exp p br{
  display:none;
}

.card--sidebar{
  height:316px;
}

.card--sidebar .card__thumbnail{
  height:136px;
}

.card--sidebar .card__body{
  height:180px;
}

.card--link.card--content:hover .card__body{
  height:258px;
}

.card--link.card--content:hover .card__thumbnail{
  -webkit-transform:scale(1);
          transform:scale(1);
}

.card--link .card__thumbnail{
  height:166px;
}

.card--link .card__body{
  border:0;
  height:258px;
  padding-top:24px;
}

.card--link .card__footer{
  padding-bottom:24px;
}

.card--linkSidebar{
  height:258px;
}

.card--linkSidebar .card__thumbnail{
  display:none;
}

.card--linkSidebar .card__body{
  height:258px;
}

.card--news .card__body{
  top:0;
  bottom:54px;
  height:auto;
}

.card--news hr{
  margin:16px 0;
}

.card--news .news{
  margin:0;
  padding:16px 0;
  border-bottom:1px solid #ebebeb;
}

.card--news .news:last-child{
  border-bottom:none;
}

.card--news .news p,.card--news .news span{
  display:inline-block;
}

.card--news .news p{
  margin-left:8px;
  color:#999;
}

.card--news .card__footer{
  height:54px;
  padding:0;
  border-top:1px solid #ebebeb;
}

.card--news-direct{
  position:relative;
  height:auto;
  background-color:#fff;
}

.card--news-direct.full-width{
  width:auto;
  margin-left:-16px;
  margin-right:-16px;
}

.card--news-direct .card__head{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding:0 16px;
}

.card--news-direct .dropdown{
  margin-top:8px;
}

.card--news-direct .dropdown .dropdown__head{
  margin-top:8px;
  padding-right:0;
}

.card--news-direct .box{
  padding:16px;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.card--news-direct .news{
  border-top:1px solid #ebebeb;
  padding:16px;
  margin-bottom:0;
}

.card--news-direct .card__body{
  position:relative;
  height:auto;
  padding:0;
  border-bottom:1px solid #ebebeb;
}

.card--news-direct .card__footer{
  position:relative;
}

.card--news-direct .card__desc{
  font-size:16px;
  margin-bottom:24px;
  color:hsla(0, 0%, 100%, .8);
}

.card--news-direct-container{
  z-index:2;
}

.card--news-direct-container .container{
  padding:0;
}

.card--news-direct-container .card--news-direct .dropdown{
  margin-top:0;
}

@media not all and (min-resolution:0.001dpcm){
  @supports (-webkit-appearance:none){
    .card--news-direct-container .card--news-direct .dropdown .dropdown__head{
      margin-top:0;
    }
  }
}

.card--news-richscore{
  height:auto;
  overflow:visible;
}

.card--news-richscore:hover{
  text-decoration:none;
}

.card--news-richscore .card--news-richscore__content{
  position:relative;
  min-height:240px;
}

.card--news-richscore .card--score{
  margin:-80px auto 0;
  width:calc(100% - 16px);
}

.card--news-richscore .card__content{
  position:relative;
  min-height:240px;
  z-index:2;
  padding:24px 16px 120px;
  color:#fff;
}

.card--news-richscore .card__thumbnail{
  height:100%;
}

.card--news-richscore .card__title{
  line-height:28px;
  margin-bottom:0;
}

.card--news-richscore .card--news-richscore__info{
  position:absolute;
  bottom:96px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.card--news-richscore .card--news-richscore__info .label{
  margin-right:16px;
}

.card--news-richscore .card--news-richscore__info .card__date{
  font-size:12px;
  line-height:15px;
  margin-bottom:0;
}

.card--simple-event{
  height:auto;
  padding:32px 24px;
  background-color:#fcfcfc;
  border:1px solid #ebebeb;
  -webkit-box-shadow:none;
          box-shadow:none;
}

.card--simple-event p{
  margin-bottom:0;
}

.card--simple-event .ft-h6{
  font-size:12px;
  line-height:15px;
}

.card--histoire{
  position:relative;
  background:transparent;
}

.card--histoire .card__video{
  position:absolute;
  top:0;
  background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, 0)), to(#280b0d));
  background-image:linear-gradient(180deg, rgba(0, 57, 116, 0), #280b0d);
  width:100%;
  height:calc(100% - 80px);
  z-index:2;
}

.card--histoire .card__video .link-icon{
  position:relative;
  z-index:2;
  margin-right:0;
  width:60px;
  height:60px;
}

.card--histoire .card__video .link-icon,.card--histoire .card__video .link-icon i{
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
}

.card--histoire .card__video .link-icon i{
  position:absolute;
}

.card--histoire .card__container{
  position:static;
  height:100%;
  text-decoration:none;
}

.card--histoire .card__thumbnail{
  display:block;
  position:inherit;
  height:auto;
}

.card--histoire .card__club{
  overflow:hidden;
  position:relative;
  background-color:rgba(40, 11, 13, .9);
  height:40px;
  line-height:40px;
  color:#fff;
  padding:0 16px;
  text-transform:uppercase;
  font-size:12px;
  z-index:3;
}

.card--histoire .card__club i{
  position:relative;
  top:-2px;
  color:hsla(0, 0%, 100%, .6);
  margin-right:8px;
}

.card--histoire .card__club span{
  position:relative;
  top:2px;
}

.card--histoire .card__club span:first-child{
  margin-right:16px;
}

.card--histoire .card__footer{
  position:inherit;
  background-color:#280b0d;
  color:hsla(0, 0%, 100%, .6);
  padding:10px 16px;
  z-index:3;
}

.card--histoire .card__footer .card__stat i{
  font-size:9px;
  position:relative;
  top:0;
  color:hsla(0, 0%, 100%, .6);
}

.card--newsletter{
  height:auto;
}

.card--newsletter .card__thumbnail{
  height:100%;
}

.card--newsletter .card__body{
  height:100%;
  color:#fff;
  position:relative;
  padding:24px;
}

.card--newsletter .card__desc{
  font-size:16px;
  margin-bottom:24px;
  color:hsla(0, 0%, 100%, .72);
}

.card--newsletter .select{
  margin-bottom:8px;
}

.card--newsletter .is-dark{
  border-radius:4px;
  background-color:#280b0d;
}

.card--newsletter .input input{
  color:hsla(0, 0%, 100%, .56);
}

.card--score{
  height:157px;
}

.card--score.is--dark{
  background-color:#280b0d;
  color:#fff;
  border:none;
}

.card--score.is--dark .card__header{
  background-color:rgba(0, 0, 0, .2);
  -webkit-box-shadow:0 1px 0 0 hsla(0, 0%, 100%, .12);
          box-shadow:0 1px 0 0 hsla(0, 0%, 100%, .12);
  border-bottom:none;
}

.card--score.is--dark .card__header span:not(.label):first-child{
  font-family:Din;
  font-weight:700;
  color:#fff;
}

.card--score.is--dark .card__header span:not(.label):nth-child(2){
  color:hsla(0, 0%, 100%, .8);
}

.card--score.is--dark .card__header .label{
  background-color:#e6679a;
  top:8px;
}

.card--score.is--dark .match__club p{
  font-size:17px;
  line-height:18px;
}

.card--score.is--dark .match__club img{
  width:28px;
  height:28px;
  background-color:#fff;
  border:2px solid #ebebeb;
  border-radius:100%;
  padding:5px;
}

.card--score.is--dark .match__info{
  margin:0 16px;
}

.card--score.is--dark .match__score{
  color:#fff;
}

.card--score.is--dark .match__time{
  color:hsla(0, 0%, 100%, .8);
}

.card--score.is--big{
  height:auto;
  min-height:157px;
}

.card--score.card--score-medium .card__header{
  padding:24px;
}

.card--score.card--score-medium .card__header .label{
  top:24px;
}

.card--score.upcoming .card__header .label{
  background-color:rgba(41, 41, 41, .4);
}

.card--score.upcoming .match__info{
  -webkit-transform:translateY(6px);
          transform:translateY(6px);
}

.card--score.score-team{
  height:auto;
}

.card--score.score-team .card__header span:not(.label).ft-up{
  color:#e6679a;
}

.card--score.score-team .card__header span:not(label){
  display:inline-block;
}

.card--score.score-team .card__header span:not(label):first-child{
  margin-right:16px;
}

.card--score.score-team .match__club img{
  border:none;
  padding:0;
  border-radius:0;
}

.card--score.score-team .match__time{
  font-size:17px;
}

.card--score .card__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  padding:16px;
  border-bottom:1px solid #ebebeb;
}

.card--score .card__header .label{
  position:absolute;
  top:24px;
  right:16px;
}

.card--score .card__header>div{
  overflow:hidden;
  max-width:70%;
  padding-right:5px;
}

.card--score .card__header span:first-child:not(:last-child){
  color:#e6679a;
}

.card--score .card__header span:not(.label){
  display:block;
  color:#999;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}

.card--score .card__body{
  position:inherit;
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  padding:24px 16px;
  height:auto;
  text-align:left;
}

.card--score .match{
  padding:0;
  height:auto;
  min-height:auto;
}

.card--score .match__club p{
  font-size:17px;
  line-height:18px;
}

.card--score .match__club img{
  width:21px;
  height:21px;
}

.card--score .match__info{
  position:relative;
}

.card--score .match__progress{
  position:absolute;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  top:-90%;
  font-family:Din;
  font-size:12px;
  font-weight:700;
}

.card--score .event__info{
  font-family:Din;
  line-height:22px;
}

.card--game-highlight{
  height:auto;
  max-width:392px;
  margin:0 auto;
}

.card--game-highlight .card__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  padding:16px 24px;
  border-bottom:1px solid #ebebeb;
}

.card--game-highlight .card__body{
  position:relative;
  height:auto;
  padding:32px 24px 16px;
}

.card--share-game{
  height:432px;
  background-color:#062a50;
  color:#fff;
}

.card--share-game:hover{
  color:#fff;
}

.card--share-game .card__thumbnail{
  position:absolute;
  bottom:0;
  top:auto;
  width:100%;
  height:70%;
  background-position:top;
  z-index:2;
}

.card--share-game .card__thumbnail.desktop{
  display:none;
}

.card--share-game .video-preview{
  position:absolute;
  top:40px;
  right:40px;
  left:40px;
  border-radius:4px;
  overflow:hidden;
  z-index:1;
}

.card--share-game .video-preview img{
  width:100%;
}

.card--share-game .video-preview .link-icon{
  position:absolute;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  top:32px;
  margin:0;
  width:60px;
  height:60px;
  line-height:58px;
}

.card--share-game .card__body{
  height:auto;
  bottom:90px;
  text-align:center;
  z-index:3;
  line-height:26px;
  padding:24px 32px;
}

.card--share-game .card__footer{
  text-align:center;
  z-index:2;
  bottom:48px;
}

.card--share-game .card__footer .icon{
  float:none;
  margin-left:8px;
  color:#fff;
  -webkit-transform:translateY(-2px);
          transform:translateY(-2px);
}

@media (min-width:768px){
  .card--hub.card--small .card__body{
    padding-top:32px;
    max-width:480px;
  }
  .card--billboard{
    height:380px;
  }
  .card--billboard.is-home{
    height:550px;
  }
  .card--billboard.has-score .card--billboard__content{
    height:473px;
  }
  .card--billboard.has-score .card--billboard__content .card__body{
    bottom:170px;
    padding-bottom:0;
  }
  .card--billboard.has-score .card--billboard__content .card__footer{
    bottom:80px;
  }
  .card--billboard.has-score .card--score{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    width:calc(100% - 32px);
  }
  .card--billboard .card--billboard__content .card__body{
    bottom:87px;
  }
  .card--billboard.inset-score .card__body{
    bottom:150px;
  }
  .card--billboard.inset-score .card__footer{
    bottom:78px;
  }
  .card--billboard.inset-score .match{
    left:24px;
    right:24px;
    bottom:24px;
  }
  .card--billboard.inset-score .match .match__club:first-child,.card--billboard.inset-score .match .match__club:last-child{
    text-align:center;
  }
  .card--billboard .card__title{
    max-width:400px;
    font-size:30px;
    line-height:36px;
  }
  .card--billboard .card__body{
    padding:24px;
  }
  .card--billboard .card__type{
    left:24px;
    top:24px;
  }
  .card--billboard .card__social{
    right:24px;
  }
  .card--billboard .card__footer{
    display:block;
    padding:24px;
  }
  .card--billboard .card--score .card__body{
    padding:24px 16px;
  }
  .card--share-game{
    height:240px;
    padding:24px 32px;
  }
  .card--share-game .video-preview{
    height:150px;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
    right:32px;
    left:auto;
  }
  .card--share-game .card__body{
    top:0;
    left:0;
    text-align:left;
    width:55%;
    max-width:420px;
    padding:24px 32px;
  }
  .card--share-game .card__thumbnail{
    left:0;
    top:0;
    height:100%;
    width:95%;
    background-position:100% 0;
  }
  .card--share-game .card__thumbnail.desktop{
    display:block;
  }
  .card--share-game .card__thumbnail.mobile{
    display:none;
  }
  .card--share-game .card__footer{
    bottom:0;
    max-width:220px;
    text-align:left;
    padding:24px 32px;
  }
  .card--user{
    height:444px;
  }
  .card--user .card__thumbnail{
    width:100%;
    height:303px;
  }
  .card--user .card__body,.card--user .card__footer{
    margin-left:0;
  }
  .card--user .card__body{
    height:148px;
    width:100%;
    padding:16px;
  }
  .card--user .card__footer{
    padding:16px;
  }
  .card--download.card--large{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    min-height:104px;
  }
  .card--download.card--large .card__body,.card--download.card--large .card__footer{
    -ms-flex-item-align:center;
        align-self:center;
    padding:24px;
  }
  .card--download.card--large .card__footer{
    position:relative;
    width:auto;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .card--download.card--large .btn--full{
    width:auto;
    padding:0 32px;
  }
  .card--download.card--large .share__wrapper{
    left:auto;
    right:0;
  }
  .card--download.card--large .share__wrapper .icon-delete{
    float:right;
  }
  .card--date{
    height:234px;
  }
  .card--date .card__thumbnail{
    height:160px;
  }
  .card--wide{
    height:306px;
  }
  .card--wide .card__body{
    height:140px;
  }
  .card--wide .card__thumbnail{
    height:167px;
  }
  .card--hub-alt.card--xSmall,.card--hub.card--small.card--xSmall{
    min-height:212px;
  }
  .card--ticket .card__body{
    padding:24px;
  }
  .card--partner{
    height:370px;
    margin-bottom:0;
  }
  .card--partner .card__header img{
    height:65px;
  }
  .card--news-direct-container .container{
    padding:0;
  }
  .card--news-direct{
    height:550px;
  }
  .card--news-direct.full-width{
    margin-left:0;
    margin-right:0;
  }
  .sidebar .card--news-direct{
    background-color:transparent;
  }
  .sidebar .card--news-direct:first-child{
    margin-top:-20px;
  }
  .sidebar .card--news-direct h3{
    font-weight:400;
  }
  .sidebar .card--news-direct .card__head{
    padding:0;
  }
  .sidebar .card--news-direct .dropdown__head{
    color:#999;
  }
  .sidebar .card--news-direct .news{
    margin-bottom:16px;
  }
  .sidebar .card--news-direct .news__thumbnail{
    width:56px;
    height:56px;
  }
  .sidebar .card--news-direct .news__title{
    word-wrap:break-word;
    max-height:40px;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .card--news-direct .card__head{
    padding:0 24px;
  }
  .card--news-direct .card__body{
    position:absolute;
    top:70px;
    bottom:55px;
  }
  .card--news-direct .card__footer{
    position:absolute;
  }
  .card--news-direct .news{
    padding:12px 24px;
  }
  .home-slider+.card--news-direct-container{
    position:absolute;
    top:72px;
    right:0;
    height:485px;
  }
  .home-slider+.card--news-direct-container>.container{
    height:100%;
  }
  .home-slider+.card--news-direct-container .card--news-direct{
    position:relative;
    float:right;
    width:35vw;
    height:100%;
    max-width:600px;
    background-color:rgba(40, 11, 13, .48);
  }
  .home-slider+.card--news-direct-container .card--news-direct .box{
    -webkit-box-shadow:none;
            box-shadow:none;
    height:435px;
    background-color:transparent;
    padding-bottom:0;
  }
  .home-slider+.card--news-direct-container .card--news-direct .card__head{
    padding-top:16px;
    color:#fff;
  }
  .home-slider+.card--news-direct-container .card--news-direct .dropdown.is-open{
    color:#292929;
  }
  .home-slider+.card--news-direct-container .card--news-direct .dropdown .dropdown__head{
    height:20px;
    line-height:20px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .dropdown .dropdown__head:after{
    margin-left:8px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .news{
    color:#fff;
    margin-bottom:8px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .news__title{
    word-wrap:break-word;
    max-height:40px;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .home-slider+.card--news-direct-container .card--news-direct .news__thumbnail{
    margin-left:8px;
    width:56px;
    height:56px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .btn--primary{
    display:none;
  }
  .home-slider+.card--news-direct-container .card--news-direct .link-chevron{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    display:block;
    color:#fff;
    line-height:26px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .link-chevron i{
    line-height:26px;
  }
  .home-slider+.card--news-direct-container .card--news-direct .link-chevron:hover{
    color:#e6679a;
  }
  .card--score.is--dark .match__club:first-child{
    padding-right:65px;
    text-align:right;
  }
  .card--score.is--dark .match__club:last-child{
    text-align:left;
    padding-left:65px;
  }
  .card--score.is--dark .match__club p{
    font-size:20px;
    line-height:26px;
  }
  .card--score.is--dark .match__club img{
    height:44px;
    width:44px;
    -webkit-transform:translateY(-6px);
            transform:translateY(-6px);
  }
  .card--score.is--dark .match__info{
    font-size:17px;
    line-height:18px;
  }
  .card--score.is--dark .card__header{
    position:relative;
    padding:16px 24px;
  }
  .card--score.is--dark .card__header div{
    max-width:100%;
    width:100%;
  }
  .card--score.is--dark .card__header div span:first-child{
    float:left;
  }
  .card--score.is--dark .card__header div span:nth-child(2){
    float:right;
  }
  .card--score.is--dark .card__header .label{
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate3d(-50%, -50%, 0);
            transform:translate3d(-50%, -50%, 0);
    float:none;
    display:table;
  }
  .card--score.is--dark .card__body{
    padding:32px 24px;
  }
  .card--score.score-team .match__club:first-child{
    text-align:left;
  }
  .card--score.score-team .match__club:last-child{
    text-align:right;
  }
  .card--score.score-team .match__club p{
    font-size:17px;
    line-height:22px;
  }
  .card--score .card__body{
    padding:32px 16px;
    text-align:center;
  }
  .card--news-richscore .card--news-richscore__content{
    min-height:204px;
  }
  .card--news-richscore .card--score{
    margin:-72px auto 0;
    width:calc(100% - 32px);
  }
  .card--news-richscore .card__content{
    min-height:204px;
    padding:24px 16px 112px;
  }
  .card--news-richscore .card--news-richscore__info{
    bottom:88px;
  }
  .card--histoire{
    max-width:286px;
  }
  .card--interact{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .card--interact .card__body{
    width:60%;
    max-width:310px;
    padding:0;
  }
  .card--interact .btn{
    width:auto;
  }
  .card--game-highlight .card__header{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    padding:8px 16px;
  }
  .card--game-highlight .card__body{
    padding-top:24px;
  }
  .card--hub-alt.card--small .card__body{
    padding:32px 32px 130px;
  }
  .card--hub-alt.card--small .card__footer{
    padding:32px;
  }
  .card--exp{
    position:relative;
  }
  .card--exp i{
    position:absolute;
    left:24px;
    top:50%;
    margin:-20px 0 0;
  }
  .card--exp p{
    max-width:900px;
    padding-left:64px;
  }
}

@media (max-width:768px){
  .card--score .match__club:first-child{
    text-align:left;
  }
  .card--score .match__club:last-child{
    text-align:right;
  }
}

@media (min-width:1080px){
  .card--news-direct.has-score .card--billboard__content{
    height:468px;
  }
}

@media (min-width:1280px){
  .card--hub .card__body{
    padding-left:32px;
  }
  .card--hub .card__footer{
    padding:32px;
  }
  .card--hub.card--small .card__body{
    padding-left:24px;
  }
  .card--hub-mobile-bcksp .card__body{
    max-width:100%;
  }
  .card--billboard{
    height:504px;
  }
  .card--billboard.is-home{
    height:528px;
  }
  .card--billboard.has-score .card--billboard__content{
    height:468px;
  }
  .card--billboard.has-score .card--billboard__content .card__body{
    bottom:180px;
  }
  .card--billboard.has-score .card--billboard__content .card__footer{
    bottom:60px;
  }
  .card--billboard.has-score .card--score{
    height:116px;
    width:calc(100% - 48px);
    margin-top:-56px;
  }
  .card--billboard.has-score .card--score .match__club p{
    font-size:17px;
    line-height:22px;
  }
  .card--billboard .card--billboard__content .card__body{
    padding-bottom:0;
  }
  .card--billboard .card__footer{
    padding:40px;
  }
  .card--billboard .card__title{
    max-width:610px;
  }
  .card--billboard .card__type{
    top:40px;
    left:40px;
  }
  .card--billboard .card__social{
    top:40px;
    right:40px;
  }
  .card--billboard.is-small-desktop{
    height:292px;
  }
  .card--billboard.is-small-desktop .card__body,.card--billboard.is-small-desktop .card__footer{
    padding:24px;
  }
  .card--billboard.is-small-desktop .card__body{
    padding-bottom:0;
    bottom:88px;
  }
  .card--billboard.is-small-desktop .card__title{
    font-size:20px;
    line-height:24px;
    max-width:400px;
  }
  .card--billboard.is-small-desktop .card__type{
    top:24px;
    left:24px;
  }
  .card--billboard.is-small-desktop .card__social{
    top:24px;
    right:24px;
  }
  .card--billboard.inset-score .card__body{
    bottom:150px;
  }
  .card--billboard.inset-score .card__footer{
    padding:16px 24px;
  }
  .card--billboard.inset-score .match__score{
    font-size:17px;
  }
  .card--billboard .match__club:first-child{
    text-align:right;
  }
  .card--billboard .match__club:last-child{
    text-align:left;
  }
  .card--billboard-actu .card--billboard__content .card__body{
    padding-bottom:24px;
    padding-left:40px;
  }
  .card--score.is--big.live .card__body{
    padding:48px 16px 24px;
  }
  .card--score.is--big .card__body{
    padding:40px 16px 24px;
  }
  .card--score.is--dark .match__club img{
    padding:6px;
    border-radius:50%;
  }
  .card--score.is--dark .card__header{
    padding:12px 24px;
  }
  .card--score.score-team .card__header{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:16px;
  }
  .card--score.score-team .card__header>div{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex:1;
        -ms-flex:1;
            flex:1;
  }
  .card--score.score-team .card__header .label{
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }
  .card--score.score-team .match__club:first-child{
    padding-right:60px;
    text-align:right;
  }
  .card--score.score-team .match__club:last-child{
    padding-left:60px;
    text-align:left;
  }
  .card--score.score-team .match__club p{
    font-size:20px;
    line-height:26px;
  }
  .card--score .card__header{
    position:relative;
    display:block;
    padding:8px 16px;
  }
  .card--score .card__header>div{
    display:inline-block;
    max-width:100%;
    width:100%;
    padding-right:0;
    vertical-align:middle;
  }
  .card--score .card__header>div span:first-child{
    float:left;
  }
  .card--score .card__body{
    padding:24px;
  }
  .live .card--score .card__body{
    padding:32px 24px 24px;
  }
  .card--score .match__club{
    font-size:17px;
  }
  .card--score .match__club img{
    width:32px;
    height:32px;
    border-radius:0;
    border:none;
    padding:0;
  }
  .card--score .match__score{
    font-size:24px;
  }
  .card--score .match__progress{
    top:-100%;
  }
  .card--score.upcoming .card__header span:last-child:not(.label){
    padding-left:16px;
  }
  .card--score.over .card__header .label{
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate3d(-50%, -50%, 0);
            transform:translate3d(-50%, -50%, 0);
    right:inherit;
  }
  .card--score.over .card__header span:last-child:not(.label){
    float:right;
    max-width:42%;
  }
  .card--wide{
    height:424px;
  }
  .card--wide .card__body{
    height:147px;
  }
  .card--wide .card__thumbnail{
    height:278px;
  }
  .card--partner{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    height:216px;
  }
  .card--partner .card__header{
    width:135px;
    height:auto;
  }
  .card--partner .card__header img{
    height:auto;
    max-width:94px;
    max-height:94px;
  }
  .card--partner .card__body,.card--partner .card__footer{
    width:calc(100% - 135px);
  }
  .card--partner .card__footer{
    left:auto;
    right:0;
  }
  .card--date{
    height:324px;
  }
  .card--date .card__thumbnail{
    height:inherit;
  }
  .card--date .card__body{
    height:84px;
  }
  .card.card--nextGameWhite div:first-child h3{
    margin-bottom:16px;
  }
  .card.card--nextGameWhite div:first-child p:first-child{
    margin-bottom:1px;
  }
  .card.card--nextGameWhite div:first-child img{
    height:40px;
    width:40px;
  }
  .card--competition .card__body{
    padding:56px 40px;
  }
  .card--share-game,.card--share-game .card__body{
    padding:32px;
  }
  .card--share-game .card__footer{
    max-width:100%;
  }
  .card--share-game .card__footer .link-chevron.link-chevron--external{
    position:relative;
  }
  .card--news-richscore .card--news-richscore__content{
    min-height:176px;
  }
  .card--news-richscore .card--score{
    width:calc(100% - 48px);
  }
  .card--news-richscore .card__content{
    min-height:176px;
    padding:32px 24px 122px;
  }
  .card--news-richscore .card--news-richscore__info{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline;
    bottom:88px;
  }
  .card--news-richscore .card--news-richscore__info .label-desktop{
    display:inline-block;
    margin-right:16px;
    color:#e6679a;
  }
  .card--news-richscore .card--score{
    height:116px;
  }
  .card--news-richscore .card--score .match__club p{
    font-size:17px;
  }
  .card--news-direct.is-home{
    height:528px;
  }
  .card--game-highlight{
    float:left;
    margin-right:32px;
  }
  .article .card--game-highlight+p{
    margin-top:40px;
  }
}

@media (max-width:1590px){
  .home-slider+.card--news-direct-container .dropdown__list{
    left:auto;
    right:-16px;
  }
}

@media (min-width:1590px){
  .home-slider+.card--news-direct-container{
    right:calc(50vw - 795px);
  }
  .home-slider+.card--news-direct-container:after{
    position:absolute;
    content:"";
    top:0;
    left:100%;
    width:calc(50vw - 795px);
    height:100%;
    background-color:rgba(40, 11, 13, .48);
  }
}

.card--chevrons .card__body{
  top:0;
  padding-top:32px;
}

.card--chevrons .card__footer{
  padding-bottom:40px;
}

.card--chevrons .card__body,.card--chevrons .card__footer{
  padding-left:24px;
  padding-right:24px;
}

@media (min-width:1280px){
  .card--chevrons .card__body,.card--chevrons .card__footer{
    padding-left:32px;
    padding-right:32px;
  }
}

.fb_iframe_widget,.instagram-media,.twitter-tweet{
  margin-bottom:16px !important;
}

.fb_iframe_widget,.fb_iframe_widget>span,.fb_iframe_widget iframe,.instagram-media{
  min-width:auto !important;
  width:100% !important;
}

.fb_iframe_widget iframe{
  background-color:#fff;
}

.event-row:hover,.event-top:hover{
  color:inherit;
  text-decoration:none;
}

.event-row:hover .event__title,.event-top:hover .event__title{
  color:#e6679a;
  text-decoration:underline;
}

.event-top{
  display:block;
  overflow:hidden;
  position:relative;
  text-align:center;
  padding:56px 16px 32px;
  height:184px;
}

.event-top:before{
  content:attr(data-day);
  top:0;
  font-size:360px;
  font-weight:700;
  line-height:.7;
  right:50%;
  margin-right:-200px;
  opacity:.05;
}

.event-top:after,.event-top:before{
  position:absolute;
  pointer-events:none;
}

.event-top:after{
  content:"";
  bottom:0;
  left:0;
  height:135px;
  width:100%;
  background:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
  background:linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
}

.event-top .label{
  position:absolute;
  top:16px;
  left:16px;
}

.event-top+.event-row{
  border-top:1px solid #ebebeb;
}

.event-top .event__title{
  padding:0 20px;
}

.event-row{
  display:table;
  min-height:93px;
  width:100%;
  border-bottom:1px solid #ebebeb;
}

.event-row.event--has-status .event__status{
  display:inline-block;
}

.event-row:last-child{
  border-bottom:none;
}

.event-row p{
  margin-bottom:0;
}

.event-row .event__date{
  display:table-cell;
  width:80px;
  padding-top:24px;
  vertical-align:top;
  border-right:1px solid #ebebeb;
  text-align:center;
}

.event-row .event__date .event__day{
  line-height:38px;
}

.event-row .event__content{
  display:table-cell;
  vertical-align:middle;
  padding:8px 16px;
}

.event-row .event__title{
  margin-bottom:4px;
}

.event-row .btn,.event-row .event__status{
  display:none;
}

.event-row .card--nextGameFull{
  border-radius:0;
  -webkit-box-shadow:none;
          box-shadow:none;
  background:none;
}

.event-row .card--nextGameFull .card__header{
  border:0;
}

.event-row .card--nextGameFull .card__body{
  padding-top:0;
}

.event-row .card--nextGameFull .card__body .card--nextGameFull__address{
  margin-bottom:0;
}

.event-row--large .event__content{
  padding:24px 16px 16px;
}

.event-row--delete .btn{
  display:block;
}

.event__content{
  position:relative;
  z-index:1;
}

.event__date{
  line-height:1;
}

.event__info,.event__month{
  color:#999;
}

.event__info i,.event__month i{
  font-size:14px;
  color:#999;
  top:-1px;
}

.event__type{
  color:#e6679a;
}

.event__info,.event__type{
  display:block;
  margin-bottom:8px;
}

.event__info:last-child,.event__type:last-child{
  margin-bottom:0;
}

.event__info{
  font-size:14px;
  line-height:22px;
}

.event-single__info li{
  display:block;
  color:#999;
}

.event-single__info li:after{
  content:"";
  display:none;
  vertical-align:middle;
  height:12px;
  width:2px;
  background:#ebebeb;
  margin:0 24px;
}

.event-single__info li:last-child{
  padding-right:0;
  margin-right:0;
}

.event-single__info li:last-child:after{
  display:none;
}

.event-single__info i{
  font-size:12px;
  color:#7d93a8;
}

.event-single__content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.event-single__content img{
  margin-bottom:32px;
}

.event-single__content .ft-secondary{
  line-height:32px;
}

.event-single__content .btn{
  margin-top:16px;
}

.event-single__content>div{
  width:100%;
}

.event--small{
  border:1px solid #eee;
}

.event--small .tag,.event--small h3{
  margin-left:16px;
}

.event--small h3{
  padding-right:16px;
}

.event--small hr{
  border-top:1px solid #eee;
}

.event--small .event__row{
  padding-left:16px;
  padding-top:24px;
}

.event--small .event__row p{
  font-size:17px;
  margin-bottom:0;
}

.event--small .event__row p i{
  position:relative;
  top:-2px;
  margin-right:8px;
}

.event--small .event__row span{
  margin-left:24px;
  color:rgba(41, 41, 41, .72);
}

.event--small .btn{
  width:calc(100% - 32px);
  margin:24px 16px 16px;
}

@media (min-width:768px){
  .event-row--large{
    min-height:116px;
  }
  .event-row--large .event__content{
    padding:30px 32px;
  }
  .event-row--large .event__title{
    font-size:20px;
    line-height:24px;
    margin-bottom:8px;
  }
  .event-row .event__date{
    padding-top:30px;
  }
  .event__info,.event__type{
    display:inline-block;
    margin-bottom:0;
    vertical-align:top;
    line-height:22px;
  }
  .event__info:after,.event__type:after{
    content:"";
    display:inline-block;
    vertical-align:middle;
    height:12px;
    width:2px;
    background:#ebebeb;
    margin:0 16px;
  }
  .event__info:last-child:after,.event__type:last-child:after{
    display:none;
  }
  .event-single__content .btn{
    width:auto;
    padding:0 16px;
    margin-top:16px;
    margin-right:16px;
  }
  .event-single__content .btn:last-child{
    margin-right:0;
  }
  .event-single__info li,.event-single__info li:after{
    display:inline-block;
  }
}

@media (min-width:1280px){
  .event-row .card--nextGameFull .btn{
    right:24px;
    bottom:24px;
    top:auto;
  }
  .event-row--large .event__content{
    padding:24px 300px 24px 40px;
  }
  .event-row--large .event__date{
    width:120px;
  }
  .event-row--large .btn{
    display:inline-block;
    position:absolute;
    right:40px;
    top:50%;
    margin-top:-24px;
  }
  .event__info:after,.event__type:after{
    margin:0 24px;
  }
  .event-single__content{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start;
  }
  .event-single__content img{
    margin-bottom:0;
    margin-right:32px;
    max-width:45%;
  }
  .event-single__content>div{
    margin-top:16px;
  }
  .event-single__content .btn{
    padding:0 32px;
  }
}

.event-row:hover .event__title{
  color:inherit;
  text-decoration:none;
}

.event-row:hover a{
  text-decoration:none;
}

.event__title a:hover{
  color:#e6679a;
  text-decoration:underline;
}

@media (min-width:1280px){
  .event-row--large:hover .btn{
    opacity:1;
  }
  .event-row--large .btn{
    opacity:0;
  }
}

.carousel .card{
  display:inline-block;
  vertical-align:top;
  width:286px;
  margin-right:8px;
  margin-bottom:-4px;
  white-space:normal;
}

.carousel .card--club{
  width:303px;
  height:72px;
}

.carousel .card--club .label{
  display:none;
}

.carousel .card--date{
  width:316px;
}

.carousel__wrapper{
  overflow:auto;
  -webkit-overflow-scrolling:touch;
  white-space:nowrap;
  margin-left:-16px;
  margin-right:-16px;
  padding-left:16px;
  padding-bottom:16px;
  padding-top:1px;
}

.carousel__nav{
  display:none;
}

.carousel-photo figure img{
  width:100%;
}

.carousel-photo.is-full .news-slider__wrapper--picture{
  width:auto;
}

.carousel-photo.is-full .news-slider .picture{
  -ms-flex-negative:0;
      flex-shrink:0;
}

.carousel-photo__wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow:initial;
  white-space:nowrap;
}

.carousel-photo__wrapper figure{
  margin-right:16px;
}

.carousel-photo__wrapper figure img{
  height:185px;
  width:auto;
  max-width:none;
  border-radius:4px;
}

.carousel-photo__wrapper figure figcaption{
  white-space:normal;
}

@media (min-width:768px){
  .carousel .card{
    margin-right:10px;
  }
  .carousel .card--club{
    width:498px;
    height:132px;
  }
  .carousel .card--club .label{
    display:inline-block;
  }
  .carousel .card--video{
    width:320px;
    height:224px;
    opacity:1;
    -webkit-animation:fadeInCard .3s ease both;
            animation:fadeInCard .3s ease both;
  }
  @-webkit-keyframes fadeInCard{
    0%{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  @keyframes fadeInCard{
    0%{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  .carousel__nav{
    display:block;
    position:absolute;
    right:0;
    top:0;
  }
  .carousel__nav a{
    margin-left:8px;
  }
  .carousel__wrapper{
    overflow:initial;
    margin-left:0;
    padding-left:0;
    padding-bottom:0;
  }
  .carousel--tv{
    position:relative;
  }
  .carousel--tv:hover .carousel__next,.carousel--tv:hover .carousel__prev{
    opacity:1;
  }
  .carousel--tv .carousel__nav{
    position:static;
  }
  .carousel--tv .carousel__next,.carousel--tv .carousel__prev{
    position:absolute;
    z-index:1;
    top:50%;
    margin-top:-30px;
    margin-right:0;
    opacity:0;
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
    -webkit-transform-origin:center center;
            transform-origin:center center;
  }
  .carousel--tv .carousel__next.is-disabled,.carousel--tv .carousel__prev.is-disabled{
    display:none;
  }
  .carousel--tv .carousel__next:hover,.carousel--tv .carousel__prev:hover{
    -webkit-transform:scale(1.1);
            transform:scale(1.1);
  }
  .carousel--tv .carousel__next:hover i,.carousel--tv .carousel__prev:hover i{
    -webkit-transform:scale(.9);
            transform:scale(.9);
  }
  .carousel--tv .carousel__next i,.carousel--tv .carousel__prev i{
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
  }
  .carousel--tv .carousel__prev{
    left:0;
  }
  .carousel--tv .carousel__next{
    right:0;
  }
  .carousel--tv .carousel__wrapper{
    -ms-overflow-x:hidden;
  }
  .carousel-photo__wrapper figure img{
    height:360px;
  }
}

@media (min-width:768px) and (hover:none){
  .carousel .carousel__wrapper{
    overflow:auto;
    padding-bottom:16px;
    margin-left:-16px;
    padding-left:16px;
  }
  .carousel--tv .carousel__nav{
    display:none;
  }
}

@media (min-width:1280px){
  .carousel .card{
    margin-right:32px;
  }
  .carousel .card--club{
    width:498px;
    height:132px;
  }
  .carousel .card--video{
    width:392px;
    height:292px;
  }
  .carousel .card--date{
    width:392px;
  }
  .carousel__wrapper{
    margin-right:0;
  }
  .carousel--tv .carousel__prev{
    left:-30px;
  }
  .carousel--tv .carousel__next{
    right:-30px;
  }
  .carousel-photo .news-slider__wrapper{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .carousel__nav{
    right:59px;
  }
  .carousel-photo__wrapper figure img{
    height:460px;
  }
}

@media (min-width:1280px) and (hover:none){
  .carousel .carousel__wrapper{
    margin-left:0;
    padding-left:0;
  }
}

.live-feed .btn--primary{
  width:100%;
  margin-top:24px;
}

.live-feed .carousel__wrapper{
  padding-bottom:0;
  -ms-overflow-x:hidden;
}

.live-feed__tag a{
  margin-right:8px;
}

.live-feed__desc{
  color:hsla(0, 0%, 100%, .48);
  line-height:32px;
}

@media (min-width:768px){
  .live-feed .btn--primary{
    width:auto;
    position:absolute;
    bottom:4px;
  }
  .live-feed .link-icon{
    width:40px;
    height:40px;
    line-height:36px;
  }
  .live-feed .link-icon i{
    font-size:11px;
  }
  .live-feed__title{
    width:220px;
  }
  .live-feed__desc{
    width:95%;
  }
}

@media (min-width:1280px){
  .live-feed .link-icon{
    width:48px;
    height:48px;
    line-height:44px;
  }
  .live-feed .link-icon i{
    font-size:14px;
  }
  .live-feed__title{
    width:100%;
    padding-right:40px;
    font-size:40px;
    line-height:40px;
  }
  .live-feed__desc{
    width:100%;
  }
}

.tab-links,.tab__links{
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.tab-links:after,.tab__links:after{
  content:"";
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  border-bottom:4px solid #ebebeb;
}

.tab-links a,.tab__links a{
  cursor:pointer;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  -ms-flex-preferred-size:0;
      flex-basis:0;
  line-height:52px;
  text-align:center;
  color:#999;
}

.tab-links a.is-active,.tab-links a:focus,.tab__links a.is-active,.tab__links a:focus{
  color:#292929;
  text-decoration:none;
}

.tab-links a:hover,.tab__links a:hover{
  text-decoration:none;
  color:#e6679a;
}

.tab-links:after{
  border-radius:4px;
}

.tab-links.is-dark:after{
  border-color:hsla(0, 0%, 100%, .32);
}

.tab-links.is-dark a{
  color:hsla(0, 0%, 100%, .6);
}

.tab-links.is-dark a.is-active,.tab-links.is-dark a:focus,.tab-links.is-dark a:hover{
  color:#fff;
}

.tab-links.is-dark .tab__indicator{
  border-radius:4px;
  border-bottom:0;
}

.tab__indicator{
  position:absolute;
  bottom:0;
  left:0;
  z-index:1;
  height:4px;
  width:50%;
  background-color:#e6679a;
  border-radius:0 4px 4px 0;
  -webkit-transition:-webkit-transform .3s ease;
  transition:-webkit-transform .3s ease;
  transition:transform .3s ease;
  transition:transform .3s ease,-webkit-transform .3s ease;
}

.tab__indicator.on-right{
  border-radius:4px 0 0 4px;
  -webkit-transform:translate3d(100%, 0, 0);
          transform:translate3d(100%, 0, 0);
}

.tab__wrapper{
  width:200%;
  -webkit-transition:-webkit-transform .3s ease;
  transition:-webkit-transform .3s ease;
  transition:transform .3s ease;
  transition:transform .3s ease,-webkit-transform .3s ease;
  -webkit-transform:translateZ(0);
          transform:translateZ(0);
}

.tab__wrapper.on-right{
  -webkit-transform:translate3d(-50%, 0, 0);
          transform:translate3d(-50%, 0, 0);
}

.tab__content{
  width:50%;
  float:left;
  padding-left:48px;
  counter-reset:c;
  opacity:0;
  -webkit-transition:opacity .6s ease;
  transition:opacity .6s ease;
}

.tab__content.is-active{
  opacity:1;
}

.tab__content .news{
  counter-increment:c;
  margin-bottom:0;
  padding:16px 16px 16px 0;
  border-bottom:1px solid #ebebeb;
}

.tab__content .news:before{
  content:counter(c, decimal-leading-zero) ".";
  margin-left:-22px;
  font-size:12px;
  font-family:Din;
  font-weight:700;
  line-height:2;
  float:left;
  color:#999;
}

.tab__content .news:last-child{
  border-bottom:0;
}

.partenaires,.partner{
  position:relative;
  height:144px;
  padding:40px 0;
  margin-bottom:0;
  background-color:#fff;
  text-align:center;
  -webkit-box-shadow:0 -3px 8px rgba(0, 0, 0, .08);
          box-shadow:0 -3px 8px rgba(0, 0, 0, .08);
}

.partner--ligue li{
  display:inline-block;
  height:100%;
  vertical-align:top;
  margin:0 16px;
}

.partner--ligue li img{
  height:100%;
}

.partenaires:not(.partner--ligue),.partner:not(.partner--ligue){
  padding:24px 0;
}

.partenaires:not(.partner--ligue) li,.partner:not(.partner--ligue) li{
  display:none;
  width:93px;
  height:93px;
  overflow:hidden;
  background:url(../img/partners/partner.png) no-repeat 0;
  background-size:auto 93px;
  margin:0 24px;
  text-indent:-200%;
}

.partenaires:not(.partner--ligue) li:first-child,.partenaires:not(.partner--ligue) li:nth-child(2),.partenaires:not(.partner--ligue) li:nth-child(3),.partner:not(.partner--ligue) li:first-child,.partner:not(.partner--ligue) li:nth-child(2),.partner:not(.partner--ligue) li:nth-child(3){
  display:inline-block;
}

.partenaires:not(.partner--ligue) li:first-child,.partner:not(.partner--ligue) li:first-child{
  background-position:0 0;
}

.partenaires:not(.partner--ligue) li:nth-child(2),.partner:not(.partner--ligue) li:nth-child(2){
  background-position:-165px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(3),.partner:not(.partner--ligue) li:nth-child(3){
  background-position:-330px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(4),.partner:not(.partner--ligue) li:nth-child(4){
  background-position:-495px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(5),.partner:not(.partner--ligue) li:nth-child(5){
  background-position:-660px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(6),.partner:not(.partner--ligue) li:nth-child(6){
  background-position:-825px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(7),.partner:not(.partner--ligue) li:nth-child(7){
  background-position:-990px 0;
}

.partenaires:not(.partner--ligue) li:nth-child(8),.partner:not(.partner--ligue) li:nth-child(8){
  background-position:-1155px 0;
}

.partenaires:not(.partner--ligue) a,.partner:not(.partner--ligue) a{
  display:block;
  height:100%;
  text-indent:-200%;
}

.block-partner__footer{
  position:absolute;
  z-index:1;
  left:24px;
  bottom:24px;
}

@media (-webkit-min-device-pixel-ratio:1.25),(min-resolution:120dpi){
  .partenaires:not(.partner--ligue) li,.partner:not(.partner--ligue) li{
    background-image:url(../img/partners/partner.png);
  }
}

@media (min-width:768px){
  .partenaires:not(.partner--ligue),.partner:not(.partner--ligue){
    padding:24px 0;
  }
  .partenaires:not(.partner--ligue) li,.partner:not(.partner--ligue) li{
    margin:0 24px;
    width:99px;
    height:93px;
    background-size:auto 93px;
  }
  .partenaires:not(.partner--ligue) li:nth-child(4),.partenaires:not(.partner--ligue) li:nth-child(5),.partner:not(.partner--ligue) li:nth-child(4),.partner:not(.partner--ligue) li:nth-child(5){
    display:inline-block;
  }
  .partenaires:not(.partner--ligue) li:nth-child(2),.partner:not(.partner--ligue) li:nth-child(2){
    background-position:-163px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(3),.partner:not(.partner--ligue) li:nth-child(3){
    background-position:-326px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(4),.partner:not(.partner--ligue) li:nth-child(4){
    background-position:-489px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(5),.partner:not(.partner--ligue) li:nth-child(5){
    background-position:-652px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(6),.partner:not(.partner--ligue) li:nth-child(6){
    background-position:-815px 0;
  }
  .partner-partner__right .link-chevron:hover{
    -webkit-text-decoration:#e6679a;
            text-decoration:#e6679a;
  }
  .block-partner__footer{
    bottom:29px;
  }
}

@media (min-width:1280px){
  .partenaires:not(.partner--ligue) li,.partner:not(.partner--ligue) li{
    margin:0 32px;
    width:80px;
    height:80px;
    background-size:auto 80px;
  }
  .partenaires:not(.partner--ligue) li:nth-child(6),.partenaires:not(.partner--ligue) li:nth-child(7),.partenaires:not(.partner--ligue) li:nth-child(8),.partner:not(.partner--ligue) li:nth-child(6),.partner:not(.partner--ligue) li:nth-child(7),.partner:not(.partner--ligue) li:nth-child(8){
    display:inline-block;
  }
  .partenaires:not(.partner--ligue) li:nth-child(2),.partner:not(.partner--ligue) li:nth-child(2){
    background-position:-144px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(3),.partner:not(.partner--ligue) li:nth-child(3){
    background-position:-288px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(4),.partner:not(.partner--ligue) li:nth-child(4){
    background-position:-432px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(5),.partner:not(.partner--ligue) li:nth-child(5){
    background-position:-576px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(6),.partner:not(.partner--ligue) li:nth-child(6){
    background-position:-710px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(7),.partner:not(.partner--ligue) li:nth-child(7){
    background-position:-830px 0;
  }
  .partenaires:not(.partner--ligue) li:nth-child(8),.partner:not(.partner--ligue) li:nth-child(8){
    background-position:-970px 0;
  }
  .block-partner__footer{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    left:212px;
  }
  .block-partner__footer .link-chevron:first-child{
    margin-right:16px;
  }
  .block-partner__footer .link-chevron:last-child{
    margin-top:0;
  }
}

.block-partner{
  position:relative;
  display:block;
  width:100%;
  background-color:#fff;
  border:1px solid #ebebeb;
  -webkit-box-shadow:0 3px 8px 0 rgba(41, 41, 41, .08);
          box-shadow:0 3px 8px 0 rgba(41, 41, 41, .08);
  border-radius:4px;
}

.block-partner .block-partner__card-link{
  display:inherit;
  height:100%;
  text-decoration:none;
  color:#292929;
}

.block-partner:after{
  position:absolute;
  z-index:-1;
  top:0;
  bottom:0;
  left:0;
  right:0;
  opacity:0;
  border-radius:4px;
  -webkit-box-shadow:0 8px 20px 0 rgba(41, 41, 41, .12);
          box-shadow:0 8px 20px 0 rgba(41, 41, 41, .12);
  -webkit-transition:opacity .2s ease-in-out;
  transition:opacity .2s ease-in-out;
  content:"";
}

@media (min-width:768px){
  .block-partner:hover:after{
    opacity:1;
  }
}

@media (min-width:1280px){
  .block-partner{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    height:286px;
  }
}

.block-partner .block-partner__left{
  position:relative;
  width:100%;
  height:150px;
  background:rgba(15, 40, 66, .08);
}

@media (min-width:1280px){
  .block-partner .block-partner__left{
    width:180px;
    height:auto;
    border-right:1px solid #ebebeb;
    border-bottom:0;
  }
}

.block-partner .block-partner__left img{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  height:55px;
}

@media (min-width:768px){
  .block-partner .block-partner__left img{
    height:65px;
  }
}

@media (min-width:1280px){
  .block-partner .block-partner__left img{
    height:auto;
    max-width:112px;
    max-height:112px;
  }
}

.block-partner.block-partner--detail .block-partner__left{
  width:100%;
}

@media (min-width:1280px){
  .block-partner.block-partner--detail .block-partner__left{
    height:278px;
  }
}

.block-partner .block-partner__footer+.block-partner__card-link{
  padding-bottom:80px;
}

@media (min-width:1280px){
  .block-partner.has-address .block-partner__right .link-chevron:last-child{
    margin-left:32px;
  }
  .block-partner.has-address .block-partner__right .link-chevron:first-child{
    margin-left:0;
  }
  .block-partner .block-partner__footer+.block-partner__card-link{
    padding-bottom:0;
  }
  .block-partner .block-partner__footer+.block-partner__card-link>block-partner__right{
    padding-bottom:80px;
  }
}

.block-partner .social{
  position:absolute;
  top:174px;
  right:24px;
}

@media (min-width:768px){
  .block-partner .social{
    bottom:24px;
    top:inherit;
  }
}

@media (min-width:1280px){
  .block-partner .social{
    right:32px;
  }
}

.block-partner .social a{
  margin-left:10px;
}

.block-partner .social a:first-child{
  margin-left:0;
}

.block-partner .social a .label{
  -webkit-transition:background .2s;
  transition:background .2s;
}

.block-partner .social a:hover .label{
  background:#5b6d7e;
}

.block-partner .block-partner__right{
  position:relative;
  width:100%;
  padding:24px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}

@media (min-width:1280px){
  .block-partner .block-partner__right{
    width:calc(100% - 180px);
    padding:24px 32px;
  }
}

.block-partner .block-partner__right h2{
  font-size:20px;
  line-height:24px;
}

.block-partner .block-partner__right .block-partner__right___address,.block-partner .block-partner__right p{
  color:#999;
  font-size:14px;
  line-height:22px;
  margin-bottom:0;
}

.block-partner .block-partner__right .block-partner__right___address{
  margin-top:16px;
}

@media (min-width:1280px){
  .block-partner .block-partner__right .block-partner__right___address{
    margin-top:8px;
  }
}

.block-partner .block-partner__right .link-chevron{
  cursor:pointer;
}

@media (min-width:1280px){
  .block-partner .block-partner__right .link-chevron{
    display:inline-block;
  }
}

.block-partner .block-partner__right .link-chevron .icon{
  margin-left:16px;
}

.search-club.has-autocompletion .search-club__autocompletion,.search-club.has-autocompletion .search-faq__autocompletion,.search-faq.has-autocompletion .search-club__autocompletion,.search-faq.has-autocompletion .search-faq__autocompletion{
  display:block;
}

.search-club .input,.search-faq .input{
  z-index:2;
}

.search-club .input input,.search-faq .input input{
  border-radius:4px;
  border-color:#ebebeb;
  padding-right:72px;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.search-club .input input:focus,.search-club .input input:hover,.search-faq .input input:focus,.search-faq .input input:hover{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.search-club .input .icon-info,.search-faq .input .icon-info{
  right:56px;
}

.search-club .btn,.search-faq .btn{
  position:absolute;
  z-index:3;
  right:0;
  top:0;
  width:56px;
  height:56px;
  text-align:center;
  background-color:rgba(0, 0, 0, .06);
  -webkit-box-shadow:none;
          box-shadow:none;
  border-radius:0 4px 4px 0;
}

.search-club .btn span,.search-faq .btn span{
  display:none;
}

.search-club .search-club__club .list-label li{
  margin-right:32px;
}

.search-club .input input{
  font-size:16px;
}

.search-club .btn{
  padding:0 8px;
  border-left-color:#e0e0e0;
}

.search-club__wrapper,.search-faq__wrapper{
  position:relative;
  z-index:5;
}

.search-club__city{
  display:none;
}

.search-club__autocompletion{
  top:54px;
  padding:24px;
}

.search-club__autocompletion div:first-of-type{
  border-bottom:2px solid #ebebeb;
  margin-bottom:16px;
  padding-bottom:16px;
}

.search-faq__autocompletion{
  top:53px;
  padding:24px 16px 8px;
}

.search-faq__autocompletion ul li{
  font-family:Roboto;
}

.search-faq__autocompletion ul li span{
  color:#e6679a;
  font-weight:700;
}

.search-club__autocompletion,.search-faq__autocompletion{
  position:absolute;
  display:none;
  z-index:1;
  left:0;
  width:100%;
  background-color:#fff;
  color:#292929;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
  border-radius:0 0 4px 4px;
}

.search-club__autocompletion p,.search-faq__autocompletion p{
  color:#999;
}

.search-club__autocompletion img,.search-faq__autocompletion img{
  margin-right:8px;
}

.search-club__autocompletion ul,.search-faq__autocompletion ul{
  line-height:32px;
}

@media (min-width:768px){
  .search-club.has-autocompletion .search-club__autocompletion,.search-club.has-autocompletion .search-faq__autocompletion,.search-faq.has-autocompletion .search-club__autocompletion,.search-faq.has-autocompletion .search-faq__autocompletion{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .search-club .input input,.search-faq .input input{
    height:72px;
    padding-right:96px;
  }
  .search-club .input .icon-info,.search-faq .input .icon-info{
    line-height:72px;
    right:72px;
  }
  .search-club .btn,.search-faq .btn{
    height:72px;
    width:72px;
  }
  .search-club__autocompletion{
    width:calc(100% - 72px);
    top:70px;
  }
  .search-club__autocompletion div{
    width:calc(50% - 14px);
  }
  .search-club__autocompletion div:first-child{
    border-right:2px solid #ebebeb;
  }
  .search-faq__autocompletion{
    top:69px;
    padding:24px 0 16px 48px;
  }
  .search-club__autocompletion div,.search-faq__autocompletion div{
    display:inline-block;
    vertical-align:top;
    min-height:130px;
  }
  .search-club__autocompletion div:first-of-type,.search-faq__autocompletion div:first-of-type{
    margin:0 24px 0 0;
    padding:0 24px 0 0;
    border-bottom:0;
  }
}

@media (min-width:1280px){
  .search-club .input input{
    border-width:2px;
  }
  .search-club .input .icon-info{
    right:0;
  }
  .search-club .col-lg-4 li{
    display:block;
    margin:0 0 16px;
  }
  .search-club .col-lg-4 li:nth-child(n+5){
    display:none;
  }
  .search-club__wrapper{
    width:710px;
  }
  .search-club__city{
    display:block;
  }
}

.block-label{
  padding:16px 16px 8px;
}

.block-label .label{
  margin-right:8px;
  margin-bottom:8px;
}

.block-label p{
  color:#999;
  display:inline-block;
  margin-right:16px;
}

.block-label ul{
  vertical-align:top;
  margin-bottom:0;
}

.block-label li,.block-label ul{
  display:inline-block;
}

@media (min-width:768px){
  .block-label{
    padding:24px 24px 16px;
  }
}

.block-social{
  float:right;
}

.block-social a{
  margin-left:6px;
}

.block-share{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.block-share .btn{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  margin-left:16px;
}

.block-share .btn:first-child{
  margin-left:0;
}

.block-share .icon{
  opacity:1;
  margin-right:0;
}

.block-share span{
  display:none;
}

.block-share--white .btn--mail i{
  font-size:11px;
}

.block-share--white .icon{
  opacity:1;
}

.block-share--stack{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}

.block-share--stack .btn{
  width:100%;
  margin:0 0 8px;
}

.block-share--stack .icon{
  margin-right:8px;
  opacity:.48;
}

.block-share--stack span{
  display:inline;
}

@media (min-width:768px){
  .block-share--stack{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .block-share--stack .btn{
    margin-left:16px;
  }
}

@media (min-width:1280px){
  .block-share .btn{
    width:33%;
    min-width:141px;
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0;
    margin-left:24px;
  }
  .block-share .icon{
    margin-right:8px;
    opacity:.48;
  }
  .block-share span{
    display:inline;
  }
  .block-share--white .icon{
    opacity:1;
  }
}

.block-news{
  position:relative;
  padding:24px 16px 64px;
}

.block-news .link-chevron{
  position:absolute;
  left:16px;
  bottom:24px;
}

.block-news .news:last-child{
  margin-bottom:0;
}

.block-news .news article{
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
}

@media (min-width:768px){
  .block-news{
    padding:24px 24px 8px;
  }
  .block-news .link-chevron{
    position:static;
  }
  .block-news .news{
    display:inline-block;
    vertical-align:top;
    width:calc(50% - 8px);
    margin-bottom:16px;
  }
  .block-news .news:nth-child(2n){
    margin-left:16px;
  }
  .block-news .news article{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start;
  }
  .block-news .news__thumbnail{
    -webkit-box-ordinal-group:1;
        -ms-flex-order:0;
            order:0;
    margin-left:0;
    margin-right:16px;
  }
}

@media (min-width:1280px){
  .block-news{
    padding:32px 40px 40px;
  }
  .block-news .news{
    width:calc(25% - 30px);
    margin-bottom:0;
    margin-right:40px;
  }
  .block-news .news:nth-child(2n){
    margin-left:0;
  }
  .block-news .news:last-child{
    margin-right:0;
  }
}

.block-carousel{
  position:relative;
  padding:16px 24px 24px;
}

.block-carousel .carousel__nav{
  top:32px;
  right:40px;
}

@media (min-width:768px){
  .block-carousel{
    padding:40px;
  }
}

.block-competition{
  position:relative;
}

.block-competition .link-chevron--blue{
  margin-top:24px;
}

.block-competition__head{
  color:#999;
}

.block-competition__head span{
  color:#ccc;
}

.block-competition__counter{
  float:right;
}

@media (min-width:1280px){
  .block-competition .link-chevron--blue{
    position:absolute;
    top:0;
    right:0;
    margin-top:0;
  }
}

.players-list .card-player--compact{
  height:84px;
}

.players-list .card-player--compact .card__thumbnail{
  position:relative;
  float:left;
  height:100%;
  width:84px;
}

.players-list .card-player--compact .card__title{
  margin-bottom:4px;
}

.players-list .card-player--compact .card__desc{
  line-height:18px;
}

.players-list .card-player--compact .card__body{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  height:100%;
  width:100%;
  left:84px;
  width:auto;
  padding:16px 24px;
}

.players-list .card-player--compact h3{
  font-size:15px;
}

.players-list .col.mb-1:last-child{
  margin-bottom:0;
}

.players-list--compact .card-player--compact{
  height:84px;
}

.players-list--compact .card-player--compact .card__thumbnail{
  position:relative;
  float:left;
  height:100%;
  width:84px;
}

.players-list--compact .card-player--compact .card__body{
  height:100%;
  left:84px;
  width:auto;
  padding:16px;
}

.players-list--compact .card-player--compact h3{
  font-size:14px;
}

@media (min-width:768px){
  .players-list:not(.players-list--compact) .card-player--compact{
    height:388px;
    border-radius:4px;
  }
  .players-list:not(.players-list--compact) .card-player--compact .card__thumbnail{
    position:absolute;
    height:304px;
    width:100%;
  }
  .players-list:not(.players-list--compact) .card-player--compact .card__body{
    height:100%;
    left:0;
  }
  .players-list:not(.players-list--compact) .card-player--compact .card__title{
    margin-bottom:8px;
  }
  .players-list:not(.players-list--compact) .card-player--compact h3{
    font-size:17px;
  }
  .players-list:not(.players-list--compact) .card-player--compact .card__body{
    height:84px;
  }
}

@media (min-width:1280px){
  .players-list--compact .card-player--compact .card__body{
    padding:16px 24px;
  }
}

.block-faq{
  width:100%;
}

.block-faq__header div:first-child{
  display:none;
}

.block-faq__header .dropdown__head{
  height:54px;
  line-height:54px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom:2px solid #ebebeb;
  padding:0 16px;
}

.block-faq__header .dropdown__head:after{
  right:16px;
}

.block-faq__header .dropdown{
  -webkit-box-shadow:inherit;
          box-shadow:inherit;
}

.block-faq__header .dropdown.is-open .dropdown__head{
  border-bottom:0;
}

.block-faq__content{
  padding:24px 16px 8px;
}

.block-faq__content h2{
  font-size:20px;
  line-height:24px;
}

.block-faq__content p{
  line-height:32px;
}

.block-faq__content p.is-grey{
  color:#999;
}

.block-faq__content p span{
  display:block;
}

.block-faq__content p span a:first-child{
  margin-right:8px;
}

.block-faq__content p span a:last-child{
  margin-left:8px;
}

.block-faq__links{
  border-top:2px solid #ebebeb;
}

.block-faq__links a{
  margin-bottom:24px;
}

.block-faq__links a:first-child{
  margin-top:24px;
}

.block-faq__links a i{
  display:none;
}

.block-faq__write{
  padding:24px 16px;
  border-top:2px solid #ebebeb;
  background-color:#f7f7f7;
}

.block-faq__write h3{
  font-weight:500;
}

.block-faq__write p{
  color:#999;
}

.block-faq__write a{
  width:100%;
}

@media (min-width:768px){
  .block-faq{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
  }
  .block-faq__header{
    display:inline-block;
    vertical-align:top;
    border-bottom:0;
    padding:0 48px;
    height:auto;
    margin-top:48px;
  }
  .block-faq__header div:first-child{
    display:block;
  }
  .block-faq__header .dropdown{
    display:none;
  }
  .block-faq__header a{
    display:block;
    text-transform:inherit;
    line-height:inherit;
    height:40px;
    line-height:40px;
    color:#999;
    font-size:14px;
    margin-bottom:0;
  }
  .block-faq__header a.is-active{
    color:#292929;
    position:relative;
  }
  .block-faq__header a.is-active:before{
    position:absolute;
    content:"";
    display:inline-block;
    width:4px;
    height:30px;
    background:#e6679a;
    border-radius:2.5px;
    top:5px;
    left:-16px;
  }
  .block-faq__header i{
    display:none;
  }
  .block-faq__content{
    padding:40px 40px 40px 0;
  }
  .block-faq__content h2{
    font-size:30px;
    line-height:36px;
  }
  .block-faq__content p span{
    display:inline-block;
  }
  .block-faq__content p span:first-child{
    margin-left:16px;
  }
  .block-faq__links a:last-child{
    margin-bottom:0;
  }
  .block-faq__links a i{
    display:inline-block;
  }
  .block-faq__write{
    padding:24px;
    margin:0 40px 40px 0;
    border:1px solid #ebebeb;
    border-radius:4px;
  }
  .block-faq__write a{
    width:inherit;
  }
}

@media (min-width:1280px){
  .block-faq__content{
    padding:48px 0 32px;
    margin-right:106px;
  }
  .block-faq__content p{
    padding-right:170px;
  }
  .block-faq__write{
    position:relative;
    margin-right:106px;
  }
  .block-faq__write a{
    position:absolute;
    top:50%;
    right:24px;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }
  .block-faq__write p{
    margin-bottom:0;
  }
}

.player-stats{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  padding:24px 0;
  margin-top:24px;
  border:1px solid #ebebeb;
  -webkit-box-shadow:none;
          box-shadow:none;
  background-color:#fcfcfc;
}

.player-stats .player-stats__item{
  display:inline-block;
  width:50%;
  padding:24px 32px 0;
}

.player-stats .player-stats__item:nth-child(odd){
  border-right:1px solid #ebebeb;
}

.player-stats .player-stats__item p{
  display:block;
  font-size:12px;
  line-height:15px;
  font-family:Din;
  font-weight:700;
  color:#999;
  text-transform:uppercase;
}

@media (min-width:768px){
  .player-stats .player-stats__item p{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
}

.player-stats .player-stats__item span{
  font-family:Din;
  font-size:40px;
  line-height:51px;
  letter-spacing:-.83px;
  font-weight:500;
}

@media (min-width:768px){
  .player-stats .player-stats__item{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    -ms-flex-preferred-size:0;
        flex-basis:0;
    width:auto;
    padding:8px;
    overflow:hidden;
    border-right:1px solid #ebebeb;
  }
  .player-stats .player-stats__item:last-child{
    border-right:0;
  }
}

@media (min-width:1280px){
  .player-stats .player-stats__item{
    padding:16px 24px 8px;
  }
}

.player-stats--small{
  padding-bottom:0;
  height:424px;
}

@media (min-width:768px){
  .player-stats--small{
    margin-top:0;
  }
}

.player-stats--small h3{
  width:100%;
  padding-left:24px;
  margin-bottom:24px;
}

@media (min-width:768px){
  .player-stats--small h3{
    margin-bottom:16px;
  }
}

.player-stats--small .player-stats__item{
  padding-left:24px;
}

.player-stats--small .player-stats__item:nth-child(2n){
  border-right:1px solid #ebebeb;
}

.player-stats--small .player-stats__item:nth-child(2){
  padding-top:0;
}

.player-stats--small .player-stats__item:nth-child(3){
  padding-top:0;
  border-right:0;
}

.player-stats--small .player-stats__item:nth-child(5){
  border-right:0;
}

@media (min-width:768px){
  .player-stats--small .player-stats__item{
    -webkit-box-flex:inherit;
        -ms-flex-positive:inherit;
            flex-grow:inherit;
    -ms-flex-preferred-size:inherit;
        flex-basis:inherit;
    width:50%;
    border-right:inherit;
    padding:16px 16px 0 24px;
  }
}

@media (min-width:1280px){
  .player-stats--small .player-stats__item{
    padding:16px 16px 8px 24px;
  }
}

.player-stats--small .player-stats__footer{
  position:relative;
  height:54px;
  padding:0 24px;
  border-top:1px solid #ebebeb;
  min-width:100%;
  margin-top:16px;
}

@media (min-width:768px){
  .player-stats--small .player-stats__footer{
    padding:0 24px;
  }
}

.player-stats--small .player-stats__footer a{
  position:relative;
  line-height:54px;
}

@media (min-width:768px){
  .player-stats--small .player-stats__footer a{
    line-height:normal;
    height:100%;
  }
  .player-stats--small .player-stats__footer a span{
    position:absolute;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
  }
}

.player-stats--small .player-stats__footer a i{
  line-height:55px;
}

.block-auteur{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.block-auteur.block-auteur--empty .block-auteur__profile{
  background:none;
}

.block-auteur.block-auteur--empty .block-auteur__profile span{
  display:block;
}

.block-auteur .block-auteur__profile{
  width:56px;
  height:56px;
  border-radius:50%;
  margin-right:12px;
  background-size:cover;
  background-position:50%;
  background-repeat:no-repeat;
}

.block-auteur .block-auteur__profile span{
  display:none;
  width:56px;
  height:56px;
  border-radius:50%;
  background:#f0f2f5;
  background-size:cover;
  background-position:50%;
  background-repeat:no-repeat;
}

.block-auteur .link-competition__empty{
  font-weight:500;
}

.block-intro-team{
  position:relative;
}

@media (min-width:1280px){
  .block-intro-team{
    margin-bottom:93px;
  }
}

.block-intro-team .block-intro-team__img{
  position:relative;
  z-index:2;
  width:100%;
  min-height:220px;
  background-size:cover;
  border-radius:4px;
}

@media (min-width:768px){
  .block-intro-team .block-intro-team__img{
    display:inline-block;
    vertical-align:top;
    width:50%;
    height:512px;
    bottom:0;
  }
}

@media (min-width:1280px){
  .block-intro-team .block-intro-team__img{
    width:66%;
  }
}

.block-intro-team .block-intro-team__text{
  position:relative;
  width:calc(100% + 32px);
  left:-16px;
  background:#fff;
  padding:32px 16px 49px;
}

@media (min-width:768px){
  .block-intro-team .block-intro-team__text{
    display:inline-block;
    position:absolute;
    right:0;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
    width:50%;
    left:inherit;
    padding:40px 24px;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
    -webkit-box-shadow:0 3px 8px 0 rgba(51, 51, 51, .08);
            box-shadow:0 3px 8px 0 rgba(51, 51, 51, .08);
  }
}

@media (min-width:1280px){
  .block-intro-team .block-intro-team__text{
    width:34%;
    padding:32px;
  }
}

.block-intro-team .block-intro-team__text p{
  margin-bottom:16px;
  line-height:30px;
}

@media (min-width:1280px){
  .block-intro-team .block-intro-team__text p{
    max-width:361px;
  }
}

.block-intro-team .block-intro-team__text .citation{
  border-left:2px solid #e6679a;
  padding-left:16px;
  font-style:italic;
  font-size:20px;
}

.block-intro-team .block-intro-team__text .link-competition{
  border:1px solid #eee;
  height:80px;
}

.block-intro-team .block-intro-team__text .link-competition img{
  width:80px;
  height:80px;
}

.block-intro-team .block-intro-team__text .link-competition .icon-chevron-right{
  display:none;
}

.block-intro-team .block-intro-team__text .link-competition .link-competition__empty{
  font-weight:700;
}

.not-found{
  margin-bottom:40px;
  text-align:center;
  padding:56px 24px 48px;
}

.not-found img{
  margin-bottom:32px;
}

.not-found h1{
  margin-bottom:16px;
}

.not-found p{
  margin-bottom:32px;
  color:#999;
}

.not-found .btn{
  color:#292929;
}

.modal{
  position:relative;
  display:none;
}

.modal.visible{
  display:block;
}

.modal .container{
  bottom:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  max-width:100%;
  z-index:20;
}

.modal .container,.modal__overlay{
  position:fixed;
  top:0;
  left:0;
  width:100%;
}

.modal__overlay{
  height:100vh;
  background-color:rgba(41, 41, 41, .6);
  z-index:1;
}

.modal .icon-close{
  right:14px;
}

.modal .icon-arrow-left,.modal .icon-close{
  position:absolute;
  top:14px;
  display:block;
  width:16px;
  line-height:16px;
  color:#999;
  font-size:12px;
  cursor:pointer;
}

.modal .icon-arrow-left{
  left:14px;
}

.newsletter-subscription .interests{
  margin-bottom:40px;
}

.newsletter-subscription .newsletter-subscription__success{
  display:none;
  padding:16px 0;
}

.newsletter-input{
  max-width:492px;
  padding-bottom:8px;
}

.newsletter-input .icon-close{
  color:#fff;
  z-index:2;
  opacity:.6;
}

.newsletter-input .modal__wrapper{
  padding-top:24px !important;
}

@media (min-width:768px){
  .newsletter-input .modal__wrapper{
    padding-top:32px;
  }
}

.newsletter-input .modal__header{
  position:relative;
  height:114px;
  text-align:center;
  background:#280b0d;
  color:#fff;
  -webkit-box-shadow:0 3px 8px 0 rgba(51, 51, 51, .12);
          box-shadow:0 3px 8px 0 rgba(51, 51, 51, .12);
  background-image:url(../img/banner/newsletter.jpg);
  background-size:cover;
}

@media (min-width:768px){
  .newsletter-input .modal__header{
    height:214px;
  }
}

.newsletter-input .modal__header:before{
  content:"";
  position:absolute;
  left:0;
  width:100%;
  height:100%;
  background:#013974;
  opacity:.88;
}

.newsletter-input .modal__header h3{
  width:100%;
  max-width:320px;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
}

.newsletter-input .modal__header h3 br{
  display:none;
}

@media (min-width:768px){
  .newsletter-input .modal__header h3 br{
    display:block;
  }
}

.newsletter-valid{
  max-width:492px;
  padding-top:24px;
}

.newsletter-valid .modal__body{
  padding:0;
}

.newsletter-valid .modal__wrapper{
  padding-top:24px;
  padding-bottom:32px;
}

.newsletter-valid .list--check span{
  font-size:14px;
}

.newsletter-valid .btn--cheer,.newsletter-valid .list--check span,.newsletter-valid h3{
  color:#292929;
}

.modal-share-content{
  max-width:345px;
}

.modal-share-content .icon-close{
  top:24px;
  right:24px;
}

.modal-share-content .btn--white:hover{
  color:#292929;
}

.modal-share-content .btn--white:focus{
  background:#fff;
}

.modal-share-content.modal-share-content--with-bg .icon-close{
  color:#fff;
}

.modal-share-content.modal-share-content--with-bg .modal__header{
  background-size:cover;
  height:337px;
  color:#fff;
  text-align:center;
  padding:52px 24px 24px;
  background-color:#280b0d;
}

.modal-share-content.modal-share-content--with-bg .modal__header h3{
  font-size:24px;
}

.modal-share-content.modal-share-content--with-bg .modal__header p{
  font-size:17px;
  opacity:.8;
  line-height:28px;
}

.modal-share-content.modal-share-content--intro .modal__header{
  height:270px;
  background-image:url(../img/share-content-modal-intro.png);
}

.modal-share-content.modal-share-content--intro .modal__header p{
  margin-bottom:13px;
}

.modal-share-content.modal-share-content--intro .modal__header a{
  font-size:17px;
  opacity:.8;
  text-decoration:underline;
}

.modal-share-content.modal-share-content--intro .modal__wrapper{
  padding:32px 24px;
}

.modal-share-content.modal-share-content--end .modal__header{
  background-image:url(../img/share-content-modal-intro.png);
  padding:70px 24px 32px;
  height:auto;
}

.modal-share-content.modal-share-content--end .modal__header p{
  margin-bottom:24px;
}

.modal-share-content.modal-share-content--end .modal__header .btn--white{
  margin-bottom:12px;
}

.modal-share-content.modal-share-content--form .modal__header{
  padding-top:19px;
  padding-left:24px;
}

.modal-share-content.modal-share-content--form .modal__wrapper{
  padding:32px 24px 24px;
}

.modal-content{
  position:relative;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
  z-index:20;
  max-height:calc(100% - 32px);
  overflow-y:auto;
  background-color:#fff;
  -webkit-overflow-scrolling:touch;
}

.modal-content .modal__wrapper{
  padding:40px 24px 16px;
}

.modal-content .modal__wrapper.modal__wrapper--footer{
  border-top:1px solid #ebebeb;
  background-color:#fafafa;
  color:#999;
}

.modal-content .checkbox.mb-3,.modal-content .input.mb-3,.modal-content .radio.mb-3{
  margin-bottom:24px;
}

.modal-content .checkbox.mb-4,.modal-content .input.mb-4,.modal-content .radio.mb-4{
  margin-bottom:32px;
}

.modal-content strong{
  color:#292929;
  font-weight:500;
}

.modal-content .modal__body{
  color:#999;
}

.modal-content .modal__body>*{
  max-width:100%;
}

.modal-content .card{
  color:#292929;
}

.modal-content .modal__body--medium{
  font-size:17px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.modal-content .modal__body--medium .circle-icon{
  margin-bottom:16px;
}

.modal-content.modal-content--stripe{
  width:392px;
}

.modal-content.modal-content--stripe .modal__body,.modal-content.modal-content--stripe h3{
  text-align:center;
}

.modal-content .modal-or-separator{
  margin:16px 0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:27px;
}

.modal-content .modal-or-separator .modal-or-separator__line{
  -webkit-box-flex:1;
      -ms-flex:1 0 0px;
          flex:1 0 0;
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.modal-content .modal-or-separator .modal-or-separator__line:before{
  display:block;
  -webkit-box-flex:1;
      -ms-flex:1 0 0px;
          flex:1 0 0;
  height:1px;
  background-color:#ebebeb;
  content:"";
}

.modal-content .modal-or-separator .modal-or-separator__text{
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding:4px 16px 0;
  text-transform:uppercase;
  font-family:Din;
  font-size:12px;
  font-weight:700;
  color:#999;
}

.modal-content .list{
  margin-left:0;
}

.modal-content .list li+li{
  margin-top:8px;
}

.modal-content .modal__footer-option{
  margin:0;
  font-size:14px;
  color:#999;
  text-align:center;
}

.modal-content .modal__footer-option a{
  font-weight:500;
}

.modal-content .modal__footer-option a.footer-option__link{
  color:#e6679a;
  font-weight:700;
}

.modal-content .modal__snack-bar{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  overflow:hidden;
  pointer-events:none;
}

.modal-content .modal__snack-bar>.snack-bar__content{
  position:absolute;
  bottom:0;
  width:100%;
  height:48px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  background-color:#2ecc71;
  color:#fff;
  font-weight:500;
  -webkit-transition:-webkit-transform .5s ease-out;
  transition:-webkit-transform .5s ease-out;
  transition:transform .5s ease-out;
  transition:transform .5s ease-out,-webkit-transform .5s ease-out;
  -webkit-transition:opacity .5 ease-out;
  transition:opacity .5 ease-out;
  -webkit-transition-delay:.75s, .75s;
          transition-delay:.75s, .75s;
}

.modal-content .modal__snack-bar>.snack-bar__content .icon{
  margin-right:8px;
  opacity:.6;
}

.modal-content .modal__snack-bar>.snack-bar__content:before{
  content:"";
  position:absolute;
  top:-64px;
  width:100%;
  height:64px;
  background:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, .8)));
  background:linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .8));
  -webkit-transition:opacity .5s ease-out;
  transition:opacity .5s ease-out;
  -webkit-transition-delay:1s;
          transition-delay:1s;
}

.modal-content .modal__snack-bar:not(.visible)>.snack-bar__content{
  pointer-events:none;
  -webkit-transition-delay:0s, 0s;
          transition-delay:0s, 0s;
  -webkit-transition-duration:0s, 0s;
          transition-duration:0s, 0s;
  -webkit-transform:translateY(48px);
          transform:translateY(48px);
  opacity:0;
}

.modal-content .modal__snack-bar:not(.visible)>.snack-bar__content:before{
  -webkit-transition-delay:0s;
          transition-delay:0s;
  -webkit-transition-duration:0s;
          transition-duration:0s;
  opacity:0;
}

.newsletter-unsubscribe .radio{
  color:#292929;
}

.creation-account .checkbox{
  font-size:12px;
  margin:0;
}

.creation-account .checkbox label{
  line-height:18px;
}

.creation-account .checkbox+.checkbox{
  margin-top:8px;
}

.keep-in-touch .checkbox{
  color:#292929;
}

.keep-in-touch .modal__event-abstract{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  border:2px solid #ebebeb;
  border-radius:4px;
  background-color:#fbfbfb;
}

.keep-in-touch .modal__event-abstract>div{
  padding:8px 16px;
}

.keep-in-touch .modal__event-abstract h6{
  font-size:10px;
  font-weight:700;
  color:#999;
  text-transform:uppercase;
}

.keep-in-touch .modal__event-abstract p{
  font-size:17px;
}

.keep-in-touch .modal__row{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.keep-in-touch .modal__row>*{
  -webkit-box-flex:1;
      -ms-flex:1 0 0px;
          flex:1 0 0;
}

@media (max-width:767px){
  .keep-in-touch{
    -webkit-box-flex:0;
        -ms-flex:0 1 604px;
            flex:0 1 604px;
  }
  .keep-in-touch .modal__event-abstract{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
  }
  .keep-in-touch .modal__event-abstract>div+div{
    border-top:1px solid #ebebeb;
  }
  .keep-in-touch .modal__row{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
  }
}

@media (min-width:768px){
  .keep-in-touch{
    -webkit-box-flex:0;
        -ms-flex:0 0 604px;
            flex:0 0 604px;
  }
  .keep-in-touch .modal__event-abstract{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .keep-in-touch .modal__event-abstract>div+div{
    border-left:1px solid #ebebeb;
  }
  .keep-in-touch .modal__row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .keep-in-touch .modal__row>*+*{
    margin-left:16px;
  }
}

@media (min-width:768px){
  .modal-content{
    max-height:calc(100% - 64px);
  }
  .modal-content .modal__wrapper{
    padding:32px 32px 24px;
  }
  .modal-share-content{
    max-width:392px;
  }
  .modal-share-content.modal-share-content--intro .modal__header{
    padding:52px 40px 32px;
  }
  .modal-share-content.modal-share-content--end .modal__header p{
    margin-bottom:56px;
  }
}

.site-club-add-admin,.site-club-creation-club{
  max-width:492px;
}

.site-club-add-admin .icon-close,.site-club-creation-club .icon-close{
  color:#fff;
  z-index:2;
  opacity:.6;
}

.site-club-add-admin .modal__wrapper,.site-club-creation-club .modal__wrapper{
  padding:24px;
}

@media (min-width:768px){
  .site-club-add-admin .modal__wrapper,.site-club-creation-club .modal__wrapper{
    padding:32px;
  }
}

.site-club-add-admin .modal__header,.site-club-creation-club .modal__header{
  position:relative;
  height:214px;
  text-align:center;
  color:#fff;
  -webkit-box-shadow:0 3px 8px 0 rgba(51, 51, 51, .12);
          box-shadow:0 3px 8px 0 rgba(51, 51, 51, .12);
  background-size:cover;
}

@media (min-width:768px){
  .site-club-add-admin .modal__header,.site-club-creation-club .modal__header{
    height:214px;
  }
}

.site-club-add-admin .modal__header h3,.site-club-creation-club .modal__header h3{
  width:100%;
  max-width:320px;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
}

.site-club-add-admin p,.site-club-creation-club p{
  color:#999;
  margin-right:auto;
  margin-left:0;
  font-size:14px;
}

@media (min-width:768px){
  .site-club-add-admin p,.site-club-creation-club p{
    font-size:17px;
  }
  .site-club-add-admin p:first-child,.site-club-creation-club p:first-child{
    padding-right:76px;
  }
  .site-club-add-admin p:last-child,.site-club-creation-club p:last-child{
    padding-right:11px;
  }
}

.site-club-add-admin .ft-700,.site-club-creation-club .ft-700{
  color:#292929;
}

@media (min-width:768px){
  .site-club-reminder,.site-club-revoke{
    width:409px;
  }
}

.site-club-reminder p:not(.ft-secondary),.site-club-revoke p:not(.ft-secondary){
  color:#999;
}

.site-club-reminder p,.site-club-revoke p{
  font-size:14px;
  line-height:22px;
}

@media (min-width:768px){
  .site-club-reminder p,.site-club-revoke p{
    font-size:17px;
    line-height:24px;
  }
}

.confirm-success{
  text-align:center;
  padding:70px 0;
}

.confirm-success div{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  width:80px;
  height:80px;
  border:4px solid #2ecc71;
  border-radius:50%;
  color:#2ecc71;
  margin-bottom:28px;
}

.confirm-success div i{
  position:absolute;
  top:51%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  font-size:17px;
}

.confirm-success p{
  color:#999;
  margin-bottom:56px;
}

.placeholder{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:208px;
  text-align:center;
  background-image:-webkit-gradient(linear, left bottom, left top, from(transparent), to(rgba(0, 0, 0, .02)));
  background-image:linear-gradient(0deg, transparent, rgba(0, 0, 0, .02));
}

.placeholder p{
  color:#ccc;
  margin-bottom:0;
  width:100%;
}

.placeholder i{
  font-size:32px;
  font-weight:400;
  color:#7d93a8;
  margin-bottom:8px;
}

.share{
  display:inline-block;
  position:relative;
  background-color:#fff;
}

.share .icon-delete{
  cursor:pointer;
  width:32px;
  line-height:32px;
  color:#7d93a8;
}

.share .btn--white{
  -webkit-box-shadow:none;
          box-shadow:none;
  border:2px solid #ebebeb;
  line-height:42px;
  padding:0;
}

.share .btn--white:focus{
  outline:0;
}

.share .btn--white:focus i{
  color:#e6679a;
}

.share.is-open .share__wrapper{
  display:block;
}

.share--left .share__wrapper{
  left:auto;
  right:0;
}

.share--left .share__wrapper .btn{
  float:left;
}

.share__wrapper{
  display:none;
  width:172px;
  border:2px solid #ebebeb;
  border-radius:4px;
  background-color:#fff;
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  padding:6px;
}

.share__wrapper .btn{
  margin-left:8px;
}

.share--transparent{
  background-color:transparent;
}

.share--transparent .btn--white{
  border:2px solid hsla(0, 0%, 100%, .32);
  background:rgba(80, 183, 72, .06);
  border-radius:4px;
}

.share--transparent .btn--white i{
  color:#fff;
}

.practice{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  background:#fcfcfc;
  border:1px solid #ebebeb;
  border-radius:4px;
}

.practice div{
  padding:16px;
}

.practice__desc,.practice__title{
  border-bottom:1px solid #ebebeb;
}

.practice__desc{
  color:#999;
}

.practice__place i{
  font-size:12px;
  color:#7d93a8;
}

@media (min-width:1280px){
  .practice{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .practice__desc,.practice__title{
    border-bottom:0;
    border-right:1px solid #ebebeb;
  }
  .practice__title{
    width:180px;
  }
  .practice__place,.practice__title{
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .practice__place{
    width:280px;
    -ms-flex-item-align:center;
        align-self:center;
  }
}

.practices-table{
  padding-left:24px;
  padding-right:32px;
  background-color:#fff;
}

.practices-table table{
  border:none;
  width:100%;
  border-collapse:collapse;
}

.practices-table th{
  font-family:Din;
  color:#999;
  text-transform:uppercase;
  font-size:12px;
  border-bottom:1px solid #ebebeb;
  padding-left:0;
}

.practices-table th:first-child{
  font-family:Din;
  color:#292929;
  text-transform:none;
  font-size:15px;
}

.practices-table tbody tr,.practices-table tbody tr:nth-child(odd){
  background-color:transparent;
}

.practices-table tbody tr:last-child td{
  border-bottom:none;
}

.practices-table tbody tr>td:first-child{
  font-family:Din;
  color:#292929;
  font-size:15px;
}

.practices-table tbody tr>td:last-child{
  color:#292929;
}

.practices-table tbody tr>td:last-child i{
  color:#7d93a8;
}

.practices-table td{
  font-weight:400;
  color:#999;
  font-size:14px;
  padding-left:0;
  border-bottom:1px solid #ebebeb;
}

.practices-table td.no-border{
  border-bottom:none;
}

@media (max-width:1080px){
  .practices-table{
    padding-left:16px;
    padding-right:16px;
  }
  .practices-table td:before{
    content:attr(data-title);
  }
  .practices-table table,.practices-table tbody,.practices-table td,.practices-table th,.practices-table thead,.practices-table tr{
    display:block;
  }
  .practices-table thead tr{
    display:none;
  }
  .practices-table tbody tr:last-child td:not(:last-child){
    border-bottom:1px solid #ebebeb;
  }
  .practices-table td{
    position:relative;
    min-height:55px;
    padding-left:45% !important;
    white-space:normal;
    text-align:right;
    border:0 solid transparent;
    border-bottom:1px solid #ebebeb;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    margin-bottom:0;
  }
  .practices-table td.no-border{
    border-bottom:1px solid #ebebeb;
  }
  .practices-table td.mobile-hidden{
    display:none;
  }
  .practices-table td:last-child:before{
    color:#999;
  }
  .practices-table td:before{
    position:absolute;
    top:50%;
    left:0;
    width:42%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
    padding-right:15px;
    white-space:nowrap;
    text-overflow:ellipsis !important;
    overflow:hidden !important;
    text-transform:uppercase;
    font-weight:700;
    font-size:12px;
    text-align:left;
  }
}

.list-label{
  overflow:auto;
  -webkit-overflow-scrolling:touch;
  white-space:nowrap;
  margin-left:-16px;
  margin-right:-16px;
  padding-left:16px;
  padding-bottom:12px;
}

.list-label li{
  display:inline-block;
  margin-right:6px;
}

.list-tag li{
  display:inline-block;
  margin-right:8px;
  margin-bottom:8px;
}

@media (min-width:768px){
  .list-label{
    overflow:visible;
    white-space:normal;
    margin-left:0;
    margin-right:0;
    padding:0;
  }
  .list-label li{
    margin-bottom:8px;
  }
}

.itw{
  position:relative;
  overflow:hidden;
  font-size:18px;
  line-height:32px;
  padding:16px 20px;
  border-left:3px solid #e6679a;
  background-color:#fafbfb;
}

.itw:before{
  content:"”";
  position:absolute;
  top:0;
  font-size:800px;
  font-weight:700;
  line-height:600px;
  right:-80px;
  pointer-events:none;
  opacity:.03;
}

.itw p{
  margin-bottom:0;
}

.itw__author{
  color:#e6679a;
  margin-top:16px;
}

.itw__author:first-child{
  margin-top:0;
}

@media (min-width:768px){
  .itw{
    font-size:17px;
  }
}

@media (min-width:1280px){
  .itw{
    padding:32px 40px;
  }
}

.filter{
  z-index:1;
  border-top:1px solid #ebebeb;
  border-bottom:1px solid #ebebeb;
  height:56px;
}

.filter .input{
  margin:0;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  border-right:1px solid #ebebeb;
}

.filter .input input{
  border:none;
  height:54px;
}

.filter.mobile-only{
  margin-left:-16px;
  margin-right:-16px;
}

.filter .filter-option__cta{
  width:110px;
  line-height:54px;
  height:54px;
  -webkit-box-shadow:none;
          box-shadow:none;
  border:0;
}

.filter .filter-option__cta:hover{
  color:#e6679a;
}

.filter__wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

@media (min-width:768px){
  .filter,.filter .input{
    height:64px;
  }
  .filter .input input{
    height:62px;
  }
  .filter .dropdown{
    display:inline-block;
    margin:0;
    -webkit-box-shadow:none;
            box-shadow:none;
  }
  .filter .dropdown__head{
    height:62px;
    line-height:62px;
    padding:0 16px;
  }
  .filter .dropdown__head:after{
    margin-left:8px;
    position:static;
  }
  .filter .dropdown__list{
    width:auto;
  }
}

@media (min-width:1280px){
  .filter .input{
    border-left:1px solid #ebebeb;
  }
  .filter .filter__options{
    border-right:1px solid #ebebeb;
  }
  .filter__wrapper{
    max-width:1680px;
    padding-left:60px;
    padding-right:60px;
    margin:0 auto;
  }
}

.filter-option__list{
  display:none;
  overflow:auto;
  position:fixed;
  background:#f0f2f5;
  left:0;
  top:127px;
  width:100%;
  height:calc(100% - 127px);
  padding:24px 24px 120px;
}

.mobile-only .filter-option__list{
  top:112px;
  height:calc(100% - 112px);
}

.filter-option__action{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  padding:40px 24px 24px;
  z-index:2;
  background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(240, 242, 245, 0)), color-stop(55%, #f0f2f5));
  background-image:linear-gradient(-180deg, rgba(240, 242, 245, 0), #f0f2f5 55%);
}

.filter-option__action .btn--white{
  border:2px solid #ebebeb;
  margin-right:16px;
  line-height:42px;
}

.filter-option__action .btn--primary{
  width:100%;
}

.filter-option__cta.is-active{
  background-color:#f0f2f5;
  color:inherit;
}

.filter-option__cta.is-active+.filter-option__list{
  display:block;
  z-index:100;
}

@media (min-width:768px){
  .filter-option--mobile .filter-option__list{
    display:block;
    position:static;
    padding:0;
    height:auto;
    overflow:visible;
    background-color:transparent;
  }
  .filter-option--mobile .filter-option__action,.filter-option--mobile .filter-option__cta{
    display:none;
  }
}

@media (min-width:1280px){
  .filter-option__list{
    display:block;
    position:static;
    padding:0;
    height:auto;
    overflow:visible;
    background-color:transparent;
  }
  .filter-option__action,.filter-option__cta{
    display:none;
  }
}

.dropdown{
  display:block;
  position:relative;
  background-color:#fff;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  border-radius:4px;
}

.dropdown.is-open{
  z-index:2;
}

.dropdown.is-open .dropdown__list{
  visibility:visible;
  opacity:1;
  max-height:1000px;
}

.dropdown.is-open .dropdown__head:after{
  content:"\e914";
  color:#e6679a;
}

.dropdown.has-counter .dropdown__counter{
  opacity:1;
  display:initial;
}

.dropdown.is-dark{
  background:none;
  color:hsla(0, 0%, 100%, .6);
  border-color:#344f6b;
}

.dropdown.is-dark .dropdown__head{
  color:hsla(0, 0%, 100%, .6);
  text-transform:none;
  font-size:14px;
  font-family:Roboto;
  font-weight:400;
}

.dropdown.is-dark .dropdown__label{
  color:hsla(0, 0%, 100%, .6);
}

.dropdown.is-dark .dropdown__list{
  background-color:#280b0d;
  -webkit-box-shadow:0 0 0 1px #344f6b;
          box-shadow:0 0 0 1px #344f6b;
}

.dropdown.is-dark .radio{
  color:#fff;
}

.dropdown ::-webkit-scrollbar{
  position:relative;
  -webkit-appearance:none;
          appearance:none;
  width:4px;
}

.dropdown ::-webkit-scrollbar-thumb{
  border-radius:5px;
  background-color:#7d93a8;
}

.dropdown ::-webkit-scrollbar-track{
  background-color:rgba(125, 147, 168, .32);
  border-radius:5px;
}

.dropdown .radio{
  color:#292929;
}

.dropdown .radio label{
  padding-left:0;
}

.dropdown .radio label:after,.dropdown .radio label:before{
  display:none;
}

.dropdown .radio input[checked]+label{
  font-weight:700;
}

.dropdown__head{
  display:block;
  cursor:pointer;
  height:48px;
  line-height:50px;
  padding:0 48px 0 24px;
  border-radius:4px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.dropdown__head:after{
  content:"\e906";
  font-family:icon;
  font-size:8px;
  position:absolute;
  right:24px;
  margin-left:16px;
  color:#7d93a8;
}

.dropdown__head:hover:after{
  color:#e6679a;
}

.dropdown__head i{
  font-size:12px;
  color:#7d93a8;
}

.dropdown__counter{
  opacity:0;
  display:none;
  color:#e6679a;
  margin-left:4px;
}

.dropdown__list{
  position:absolute;
  top:0;
  left:0;
  z-index:20;
  max-height:0;
  overflow:hidden;
  visibility:hidden;
  opacity:0;
  background-color:#fff;
  -webkit-transition:all .5s ease;
  transition:all .5s ease;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08), 0 0 0 1px #ebebeb;
          box-shadow:0 10px 16px rgba(51, 51, 51, .08),0 0 0 1px #ebebeb;
  width:100%;
  padding:0 16px 16px 0;
  border-radius:4px;
}

.dropdown__list .checkbox,.dropdown__list .radio,.dropdown__list .switch{
  margin-bottom:0;
}

.dropdown__list .checkbox:first-child,.dropdown__list .radio:first-child,.dropdown__list .switch:first-child{
  margin-top:0;
}

.dropdown__list .checkbox label{
  width:100%;
}

.dropdown__list label:hover{
  cursor:pointer;
}

.dropdown__list .dropdown__head{
  -webkit-box-shadow:none;
          box-shadow:none;
  padding-right:32px;
}

.dropdown__wrapper{
  overflow-y:auto;
  max-height:220px;
  -webkit-overflow-scrolling:touch;
  padding:0 24px;
  font-size:14px;
}

.dropdown--checkbox input:checked~label,.dropdown__wrapper>div:hover,.dropdown__wrapper>p:hover{
  font-weight:500;
  color:#292929;
}

.dropdown--accordion .dropdown__wrapper{
  max-height:none;
  padding:0 24px 16px;
}

.dropdown--accordion .dropdown__list{
  position:static;
  padding:0;
  -webkit-box-shadow:none;
          box-shadow:none;
}

.dropdown--accordion .dropdown__list .dropdown__head{
  display:none;
}

.dropdown--select{
  border:1px solid #ccc;
  -webkit-box-shadow:none;
          box-shadow:none;
  height:56px;
}

.dropdown--select .dropdown__head{
  height:56px;
  line-height:56px;
  padding-left:16px;
}

.dropdown--select .dropdown__label{
  text-transform:none;
  color:#999;
  font-size:14px;
  font-family:Roboto;
  font-weight:400;
}

.dropdown--select .dropdown__subhead{
  opacity:0;
  line-height:normal;
  font-size:11px;
  margin-top:14px;
  color:#999;
}

.dropdown--select .dropdown__list{
  padding-right:0;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08), 0 0 0 1px #ccc;
          box-shadow:0 10px 16px rgba(51, 51, 51, .08),0 0 0 1px #ccc;
}

.dropdown--select .dropdown__wrapper{
  padding-left:16px;
  margin-right:16px;
}

.dropdown--select.is-selected .dropdown__label{
  color:#292929;
}

.dropdown--select.is-open .dropdown__head{
  position:relative;
  z-index:1;
}

.dropdown--select.is-disabled{
  background-color:#fcfcfc;
  pointer-events:none;
}

.dropdown--match .dropdown__wrapper{
  max-height:400px;
  padding:0;
  margin-right:0;
}

.dropdown--match .dropdown__wrapper .card--match:last-child{
  border-bottom:0;
}

.dropdown--match .dropdown__head{
  -webkit-box-shadow:initial;
          box-shadow:none;
}

.dropdown--match.is-open .dropdown__head{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.dropdown--match .dropdown__list{
  padding:0;
}

.dropdown--guest .input-custom:hover{
  font-weight:400;
}

.dropdown--guest .input-custom.input-custom--guest{
  padding:20px 0 20px 8px;
  width:100%;
  height:100%;
}

.dropdown--guest .input-custom.input-custom--guest input{
  width:124px;
  outline:none;
}

.dropdown--guest .input-custom.input-custom--guest span:hover{
  color:#e6679a;
}

.dropdown--guest .dropdown__wrapper{
  padding-right:0;
}

.dropdown--guest .dropdown__list{
  padding:0;
  border-top-left-radius:0;
  margin-top:1px;
}

.dropdown--guest .dropdown__list .dropdown__head{
  height:78px;
}

.dropdown--big{
  height:80px;
}

.dropdown--big .dropdown__head{
  height:80px;
  line-height:80px;
  padding-left:24px;
}

.dropdown--big .dropdown__head .dropdown__subhead{
  position:absolute;
  top:0;
}

.dropdown--big .dropdown__head .dropdown__label{
  font-size:17px;
}

.dropdown--big.is-open .dropdown__subhead,.dropdown--big.is-selected .dropdown__subhead{
  opacity:1;
}

.dropdown--big.is-open .dropdown__label,.dropdown--big.is-selected .dropdown__label{
  position:relative;
  top:6px;
}

.dropdown--big.is-open .dropdown__head,.dropdown--big.is-selected .dropdown__head{
  position:relative;
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
  z-index:1;
}

.dropdown--big.is-selected .dropdown__head{
  -webkit-box-shadow:initial;
          box-shadow:none;
}

.dropdown--contact{
  font-family:Roboto;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
}

.dropdown--contact .dropdown__head{
  position:relative;
  height:72px;
  line-height:68px;
  text-transform:none;
  padding-left:48px;
  font-weight:500;
  font-size:17px;
  color:#ccc;
  border:1px solid #ccc;
  -webkit-box-shadow:inherit;
          box-shadow:inherit;
}

.dropdown--contact .dropdown__head:before{
  content:"\e912";
  font-family:icon;
  font-size:16px;
  line-height:16px;
  color:#ccc;
  position:absolute;
  left:24px;
  top:50%;
  margin-top:-8px;
}

.dropdown--contact .dropdown__list{
  padding-right:0;
}

.dropdown--contact .dropdown__wrapper a{
  display:block;
  color:#292929;
  font-size:17px;
  line-height:40px;
}

.dropdown--contact .dropdown__wrapper a:hover{
  text-decoration:none;
  font-weight:700;
}

.dropdown--contact .dropdown__wrapper a:first-child{
  margin-top:24px;
}

.dropdown--noBorder{
  display:inline-block;
  -webkit-box-shadow:none;
          box-shadow:none;
  background-color:transparent;
}

.dropdown--noBorder .dropdown__wrapper{
  padding:8px 16px 0;
}

.dropdown--noBorder label{
  white-space:nowrap;
}

.dropdown--noBorder .dropdown__head{
  display:inline-block;
  padding-left:16px;
  padding-right:16px;
}

.dropdown--noBorder .dropdown__head:after{
  position:static;
}

.dropdown--noBorder .dropdown__list{
  width:auto;
}

.dropdown--margin{
  margin-left:-16px;
}

.multi-dropdown{
  display:inline-block;
}

.multi-dropdown__desktop{
  display:none;
}

.multi-dropdown__mobile{
  display:block;
}

.multi-dropdown .dropdown__wrapper span{
  display:block;
  color:#999;
  font-weight:500;
}

.multi-dropdown .dropdown__wrapper span:not(:first-child){
  margin-top:24px;
}

@media (min-width:1195px){
  .multi-dropdown__desktop{
    display:block;
  }
  .multi-dropdown__mobile{
    display:none;
  }
}

@media (min-width:768px){
  .dropdown--accordion .dropdown__list{
    min-width:100%;
    position:absolute;
    padding:0 16px 16px 0;
    -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08), 0 0 0 1px #ebebeb;
            box-shadow:0 10px 16px rgba(51, 51, 51, .08),0 0 0 1px #ebebeb;
  }
  .dropdown--accordion .dropdown__list .dropdown__head{
    display:block;
  }
  .dropdown--accordion .dropdown__wrapper{
    padding:0 0 0 24px;
  }
  .dropdown__wrapper{
    max-height:360px;
  }
  .dropdown--big{
    height:80px;
  }
  .dropdown--big .dropdown__head{
    height:80px;
    line-height:80px;
  }
}

.cookie{
  position:fixed;
  z-index:9;
  bottom:12px;
  left:12px;
  right:12px;
  padding:16px;
  -webkit-box-shadow:0 0 36px rgba(51, 51, 51, .3);
          box-shadow:0 0 36px rgba(51, 51, 51, .3);
}

.cookie p{
  font-size:11px;
  color:#999;
  max-width:815px;
  margin:0;
  padding-right:24px;
}

.cookie p a{
  color:#e6679a;
  font-weight:500;
  text-decoration:underline;
}

.cookie .btn{
  margin-top:16px;
}

.cookie .icon-close{
  position:absolute;
  top:16px;
  right:16px;
  width:16px;
  height:16px;
  font-size:12px;
  cursor:pointer;
  color:#999;
}

@media (min-width:768px){
  .cookie{
    padding-right:260px;
    padding-left:40px;
  }
  .cookie .btn{
    position:absolute;
    top:50%;
    right:48px;
    margin-top:-24px;
    width:200px;
    padding:0 32px;
  }
  .cookie .icon-close{
    top:50%;
    margin-top:-6px;
  }
}

@media (min-width:1280px){
  .cookie{
    padding-right:510px;
  }
  .cookie p{
    max-width:1015px;
  }
  .cookie__about{
    position:absolute;
    right:282px;
    top:50%;
    line-height:24px;
    margin-top:-12px;
  }
  .cookie__separator{
    display:none;
  }
}

.warning{
  background:#e6679a;
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
  color:hsla(0, 0%, 100%, .8);
}

.warning p{
  margin-bottom:0;
}

.warning strong{
  font-weight:500;
  color:#fff;
}

.warning i{
  color:#fff;
  font-size:16px;
  margin-right:8px;
}

.warning .icon-close{
  float:right;
  margin:6px 0 0 8px;
  cursor:pointer;
  font-size:12px;
}

.warning__wrapper{
  max-width:1672px;
  padding:20px;
  min-height:64px;
  margin-left:auto;
  margin-right:auto;
}

.sidebar{
  padding-bottom:48px;
}

.sidebar>h3{
  margin-top:56px;
  margin-bottom:24px;
}

.sidebar>h3:first-child{
  margin-top:0;
}

.sidebar>h4{
  color:#999;
  margin-top:24px;
  margin-bottom:8px;
}

.sidebar .btn--competition{
  width:100%;
  padding:16px 24px 16px 16px;
}

@media (min-width:768px){
  .sidebar{
    padding-top:56px;
    padding-bottom:0;
    margin-bottom:48px;
  }
  .sidebar--article{
    padding-top:80px;
  }
  .sidebar--alt{
    padding-top:110px;
  }
}

@media (min-width:1280px){
  .sidebar{
    padding-top:40px;
  }
  .sidebar>h3{
    margin-top:40px;
  }
  .sidebar--article{
    padding-top:650px;
  }
  .sidebar--alt{
    padding-top:110px;
  }
}

@media (max-width:1279px){
  .sidebar--form{
    position:fixed;
    z-index:201;
    bottom:0;
    left:0;
    padding:0;
    margin:0;
    -webkit-transition:-webkit-transform .3s ease;
    transition:-webkit-transform .3s ease;
    transition:transform .3s ease;
    transition:transform .3s ease,-webkit-transform .3s ease;
    max-height:95%;
    overflow:scroll;
    -webkit-overflow-scrolling:touch;
    -webkit-box-shadow:0 -3px 8px rgba(51, 51, 51, .08);
            box-shadow:0 -3px 8px rgba(51, 51, 51, .08);
    border-top:1px solid #ebebeb;
  }
  .sidebar--form.is-hidden{
    -webkit-transform:translateY(calc(100% - 60px));
            transform:translateY(calc(100% - 60px));
    z-index:9;
  }
  .sidebar--form.is-hidden h3{
    font-size:15px;
    padding:18px 16px;
  }
  .sidebar--form.is-hidden h3:after{
    content:"\e914";
    color:#7d93a8;
  }
  .sidebar--form .box{
    border-radius:4px 4px 0 0;
  }
  .sidebar--form h3{
    cursor:pointer;
  }
  .sidebar--form h3:after{
    font-family:icon;
    content:"\e906";
    float:right;
    font-size:8px;
    color:#e6679a;
  }
}

.club{
  text-align:center;
}

.club .list-type{
  color:#999;
}

.club .list-type i{
  color:#7d93a8;
}

.club__logo{
  width:88px;
  height:88px;
  margin-bottom:8px;
}

.club__title{
  font-size:20px;
  line-height:26px;
  margin-bottom:24px;
}

.club__label{
  margin-bottom:8px;
  max-width:730px;
}

.club__label .label{
  margin:0 4px 8px;
}

@media (min-width:768px){
  .club{
    text-align:left;
  }
  .club__logo{
    float:right;
    width:64px;
    height:64px;
    margin:0 0 0 16px;
  }
  .club__title{
    font-size:40px;
    line-height:56px;
    margin-bottom:16px;
  }
}

@media (min-width:1280px){
  .club{
    position:relative;
  }
  .club__title{
    max-width:730px;
  }
  .club__logo{
    width:116px;
    height:116px;
    position:absolute;
    top:calc(50% - 58px);
    right:0;
  }
}

.address{
  position:relative;
  background-color:#f0f2f5;
  z-index:0;
}

.address iframe{
  width:100%;
  height:456px;
}

.address .card{
  position:absolute;
  bottom:16px;
  left:24px;
  right:24px;
  width:auto;
}

@media (min-width:768px){
  .address iframe{
    width:100%;
    height:248px;
  }
  .address .card{
    bottom:inherit;
    top:32px;
    right:16px;
    left:auto;
    width:50%;
    max-width:300px;
  }
}

@media (min-width:1280px){
  .address iframe{
    width:100%;
    height:268px;
  }
  .address .card{
    top:40px;
    right:40px;
  }
}

.contact .box{
  position:relative;
  border-top:4px solid #e6679a;
  border-radius:0 0 4px 4px;
}

.contact.is-open .contact__more{
  visibility:visible;
  max-height:1000px;
}

.contact__club{
  margin-bottom:0;
}

.contact__item{
  position:relative;
  border-bottom:1px solid #ebebeb;
  padding:16px 16px 16px 44px;
}

.contact__item i{
  position:absolute;
  top:20px;
  left:16px;
  font-size:12px;
  color:#7d93a8;
}

.contact__item p:last-child{
  margin-bottom:0;
  color:#999;
  line-height:20px;
}

.contact__more{
  border:1px solid #ebebeb;
  border-top:0;
  border-radius:0 0 4px 4px;
  background:#fcfcfc;
  max-height:0;
  overflow:hidden;
  visibility:hidden;
  -webkit-transition:all .6s ease;
  transition:all .6s ease;
}

.contact__more h3{
  padding:0 24px;
}

.contact__more h3:first-child{
  padding-top:24px;
}

.contact__more .member{
  padding-bottom:24px;
  margin-bottom:24px;
  border-bottom:1px solid #ebebeb;
}

.contact__more .member:last-child{
  margin-bottom:0;
  border-bottom:0;
}

.contact__staff{
  padding:0 24px;
  border-bottom:1px solid #ebebeb;
  margin-bottom:32px;
}

.contact__staff:last-of-type{
  border-bottom:0;
  margin-bottom:0;
}

@media (min-width:768px){
  .contact__item p{
    margin:0;
  }
}

@media (min-width:1280px){
  .contact__club{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .contact__item{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    width:33%;
    border-right:1px solid #ebebeb;
    text-align:center;
    padding:24px;
  }
  .contact__item:last-child{
    border-right:0;
  }
  .contact__item i{
    position:static;
  }
  .contact__staff{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:0;
  }
  .contact__more .member{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1;
    width:33%;
    border-bottom:0;
    margin-bottom:0;
    padding:0 24px 32px;
  }
  .contact__more h3:first-child{
    padding-top:32px;
  }
}

.navbar{
  position:relative;
  width:100%;
  font-family:Din;
  background-color:#fff;
}

.navbar.has-accordion-open{
  z-index:201;
}

.navbar .accordion-trigger{
  display:block;
  font-size:12px;
  font-weight:700;
  line-height:56px;
  padding:0 16px;
  position:relative;
  z-index:1;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  color:#292929;
}

.navbar .accordion-trigger:after{
  line-height:56px;
}

.navbar .accordion-trigger:hover{
  color:#292929;
}

.navbar .accordion-trigger.is-selected{
  color:#e6679a;
}

.navbar.box{
  overflow:initial;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
}

.navbar.box.has-accordion-open{
  -webkit-box-shadow:inherit;
          box-shadow:inherit;
}

.navbar--blue .accordion-trigger,.navbar--blue .accordion-trigger.is-selected,.navbar--blue .accordion-trigger.is-selected:after,.navbar--blue .accordion-trigger:focus,.navbar--blue .accordion-trigger:focus:after,.navbar--blue .accordion-trigger:hover,.navbar--blue .accordion-trigger:hover:after,.navbar--green .accordion-trigger,.navbar--green .accordion-trigger.is-selected,.navbar--green .accordion-trigger.is-selected:after,.navbar--green .accordion-trigger:focus,.navbar--green .accordion-trigger:focus:after,.navbar--green .accordion-trigger:hover,.navbar--green .accordion-trigger:hover:after{
  color:#fff;
}

.navbar--blue .accordion-trigger:after,.navbar--blue .navbar__links,.navbar--green .accordion-trigger:after,.navbar--green .navbar__links{
  color:hsla(0, 0%, 100%, .6);
}

.navbar--blue .navbar__links a.is-active,.navbar--blue .navbar__links a:focus,.navbar--blue .navbar__links a:hover,.navbar--green .navbar__links a.is-active,.navbar--green .navbar__links a:focus,.navbar--green .navbar__links a:hover{
  color:#fff;
}

.navbar--blue .navbar__sub a:hover,.navbar--green .navbar__sub a:hover{
  text-decoration:underline;
}

.navbar--green{
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
}

.navbar--green,.navbar--green .navbar__wrapper{
  background-color:#e6679a;
}

.navbar--blue{
  background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
  background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
}

.navbar--blue,.navbar--blue .navbar__plus:hover,.navbar--blue .navbar__sub,.navbar--blue .navbar__wrapper{
  background-color:#280b0d;
}

.navbar__right{
  display:none;
}

.navbar__wrapper{
  position:absolute;
  top:100%;
  left:0;
  width:100%;
  padding:16px 16px 8px;
  background-color:#fff;
}

.navbar__links{
  font-size:16px;
  line-height:32px;
  font-weight:500;
  margin:0;
  color:#999;
}

.navbar__links li{
  margin-bottom:16px;
}

.navbar__links a{
  display:block;
}

.navbar__links a.is-active,.navbar__links a:focus,.navbar__links a:hover{
  color:#292929;
  text-decoration:none;
}

.navbar__links .label{
  margin-left:8px;
}

.navbar__plus{
  display:none;
}

@media (min-width:768px){
  .navbar .accordion-trigger{
    display:none;
  }
  .navbar.box{
    overflow:hidden;
    -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
            box-shadow:0 3px 8px rgba(51, 51, 51, .08);
    border-radius:4px;
  }
  .navbar--blue .navbar__links>li:not(.navbar__plus)>a:after,.navbar--green .navbar__links>li:not(.navbar__plus)>a:after{
    background:#fff;
  }
  .navbar--blue .navbar__plus:hover,.navbar--green .navbar__plus:hover{
    color:#fff;
  }
  .navbar--blue .navbar__wrapper,.navbar--green .navbar__wrapper{
    background:none;
  }
  .navbar--green .navbar__plus:hover,.navbar--green .navbar__sub{
    background-color:#e6679a;
    background-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .2)), to(rgba(0, 0, 0, .2)));
    background-image:linear-gradient(0deg, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, .2));
  }
  .navbar--green .navbar__sub li{
    color:#fff;
  }
  .navbar__wrapper{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    position:relative;
    padding:0;
    background:none;
  }
  .navbar__right{
    display:block;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .navbar__links{
    position:relative;
    font-size:0;
    background:none;
    -webkit-box-flex:2;
        -ms-flex-positive:2;
            flex-grow:2;
  }
  .navbar__links li{
    font-size:12px;
    font-weight:700;
    text-transform:uppercase;
    margin-bottom:0;
  }
  .navbar__links>li{
    display:inline-block;
    line-height:56px;
  }
  .navbar__links>li>a{
    padding:0 16px;
  }
  .navbar__links>li:not(.navbar__plus){
    position:relative;
  }
  .navbar__links>li:not(.navbar__plus)>a:after{
    content:"";
    display:block;
    position:absolute;
    bottom:0;
    left:2px;
    right:2px;
    height:0;
    background:#e6679a;
    border-radius:4px;
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
    z-index:1;
  }
  .navbar__links>li:not(.navbar__plus)>a.is-active:after,.navbar__links>li:not(.navbar__plus)>a:hover:after{
    height:4px;
  }
  .navbar__plus:hover{
    -webkit-box-shadow:none;
            box-shadow:none;
  }
  .navbar__plus:hover>a:after{
    content:"\e914";
  }
  .navbar__plus:hover .navbar__sub{
    opacity:1;
    visibility:visible;
    z-index:2;
  }
  .navbar__plus>a:after{
    position:relative;
    top:-2px;
    font-family:icon;
    content:"\e906";
    font-size:6px;
    margin-left:8px;
  }
  .navbar__sub{
    margin:0;
    position:absolute;
    top:100%;
    right:200px;
    text-align:right;
    opacity:0;
    visibility:hidden;
    padding:16px 24px;
    background-color:#fff;
    -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
            box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  }
  .navbar__sub li{
    display:block;
    line-height:32px;
  }
}

@media (min-width:1280px){
  .navbar__wrapper{
    max-width:1680px;
    margin-left:auto;
    margin-right:auto;
    padding-left:100px;
    padding-right:100px;
  }
}

.list-competition .btn--icon{
  position:absolute;
  right:16px;
  top:50%;
  margin-top:-16px;
}

.list-competition .link-competition{
  margin-bottom:0;
  display:block;
}

.list-competition ul{
  margin-bottom:0;
}

.list-competition__head{
  position:relative;
  display:block;
  padding:16px 56px 16px 16px;
  border-bottom:1px solid #ebebeb;
  z-index:1;
}

.list-competition__head:after{
  display:none;
}

.list-competition__head:focus .ft-h3{
  color:#292929;
}

.list-competition__head.is-selected{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.list-competition__legend{
  display:none;
}

.list-competition__item{
  padding:16px;
  border-bottom:1px solid #ebebeb;
  background:#f6f6f6;
}

.list-competition__club{
  display:none;
}

.list-competition__count{
  color:#999;
}

@media (min-width:768px){
  .list-competition .btn--icon{
    right:40px;
  }
  .list-competition .ft-h3,.list-competition .link-competition{
    padding-right:24px;
  }
  .list-competition__head,.list-competition__item,.list-competition__legend{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    padding:20px 24px;
  }
  .list-competition__head{
    height:80px;
  }
  .list-competition__legend{
    line-height:48px;
    color:#999;
    background:#ebebeb;
    padding:0 24px;
  }
  .list-competition__legend span:last-child{
    width:320px;
  }
  .list-competition__club,.list-competition__count{
    width:320px;
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .list-competition__count{
    padding-right:56px;
  }
  .list-competition__club{
    display:block;
    margin:0;
  }
  .list-competition__club img{
    margin-right:16px;
  }
}

@media (min-width:1280px){
  .list-competition__head,.list-competition__item,.list-competition__legend{
    padding-left:40px;
  }
  .list-competition__club,.list-competition__count,.list-competition__legend span:last-child{
    width:366px;
  }
}

.match{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  text-align:center;
  padding:8px;
  min-height:80px;
  font-family:Din;
  font-size:15px;
  line-height:20px;
}

.match:hover{
  color:inherit;
  text-decoration:none;
}

.match p{
  margin:0;
}

.news .match{
  width:100%;
  height:auto;
  min-height:60px;
  padding:8px 0;
}

.news .match .match__club{
  font-size:14px;
}

.news .match .match__club:first-child{
  text-align:left;
  padding-right:32px;
}

.news .match .match__club:last-child{
  text-align:right;
  padding-left:32px;
}

.news .match .match__club img{
  width:25px;
  height:25px;
}

.news .match .match__info{
  width:110px;
}

.match--score,.match-context{
  color:#999;
}

.match--score .ft-700{
  color:#292929;
}

.match__info{
  width:88px;
  -ms-flex-negative:0;
      flex-shrink:0;
}

.match__score{
  font-size:20px;
  color:#292929;
}

.match__time{
  color:#999;
  font-size:14px;
  min-width:92px;
}

.match__club{
  position:relative;
  width:31%;
}

.match__club p span{
  display:-webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical;
  overflow:hidden;
  text-align:center;
}

.match__club img{
  position:absolute;
  top:50%;
  margin-top:-12px;
}

.match__club:first-child{
  padding-right:32px;
  text-align:right;
}

.match__club:first-child img{
  right:0;
}

.match__club:last-child{
  padding-left:32px;
  text-align:left;
}

.match__club:last-child img{
  left:0;
}

.match__colon{
  font-size:15px;
  margin:0 8px;
  line-height:24px;
  vertical-align:middle;
}

.list-match .match{
  margin-bottom:8px;
}

.match.match--widget{
  position:relative;
  display:block;
  text-align:left;
  padding:0;
  height:auto;
}

.match.match--widget:before{
  position:absolute;
  content:"";
  right:0;
  top:10px;
  left:0;
  height:1px;
  width:100%;
  background-color:hsla(0, 0%, 100%, .2);
}

.match.match--widget:not(:last-child){
  margin-bottom:16px;
}

.match.match--widget .match__division{
  position:relative;
  display:inline-block;
  margin-bottom:8px;
  padding-right:16px;
  color:hsla(0, 0%, 100%, .72);
}

.match.match--widget .match__club{
  width:calc(100% - 50px);
  font-size:15px;
  line-height:20px;
  padding:0;
  white-space:nowrap;
}

.match.match--widget .match__club:not(:last-child){
  margin-bottom:8px;
}

.match.match--widget .match__club:first-child,.match.match--widget .match__club:last-child{
  text-align:left;
  padding-right:0;
}

.match.match--widget .match__club img{
  position:relative;
  width:28px;
  height:28px;
  margin-top:0;
  margin-right:16px;
  border-radius:100%;
  padding:5px;
  background:#fff;
  border:1px solid #ebebeb;
}

.match.match--widget .match__club p{
  overflow:hidden;
  text-overflow:ellipsis;
}

.match.match--widget .match__info{
  position:absolute;
  right:0;
  top:30px;
  bottom:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  width:auto;
}

.match.match--widget .match__progress{
  font-size:12px;
  font-weight:700;
  padding-right:16px;
}

.match.match--widget .match__score{
  height:90%;
  color:#fff;
  font-size:14px;
  padding-left:16px;
  border-left:1px solid hsla(0, 0%, 100%, .2);
}

.match.match--widget .match__score span:not(.match__colon){
  display:block;
}

.match.match--widget .match__score span{
  text-align:right;
}

.match.match--widget .match__score span:first-child{
  margin-bottom:17px;
}

.match.match--widget .match__colon{
  display:none;
}

.news--result .match{
  font-size:14px;
  padding:8px 0;
}

.news--result .match .match__club{
  position:relative;
}

.news--result .match .match__club:first-child{
  text-align:left;
  padding-right:35px;
}

.news--result .match .match__club:last-child{
  text-align:right;
  padding-left:35px;
}

.news--result .match .match__club img{
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  margin-top:0;
  width:20px;
  height:auto;
}

.news--result .match .match__info{
  width:80px;
  margin:0 8px;
}

@media (min-width:768px){
  .match{
    padding:16px;
    min-height:auto;
    height:76px;
  }
  .match__info{
    width:120px;
  }
  .match__club{
    width:50%;
  }
  .match__club:first-child{
    padding-right:36px;
  }
  .match__club:last-child{
    padding-left:36px;
  }
  .match__club p span{
    -webkit-line-clamp:2;
    text-align:inherit;
  }
  .list-match .match{
    margin-bottom:16px;
  }
}

@media (min-width:1080px){
  .match.match--widget:before{
    display:none;
  }
  .match.match--widget .match__club{
    font-size:14px;
    width:calc(100% - 60px);
  }
  .match.match--widget .match__club img{
    width:16px;
    height:16px;
    padding:1px;
  }
  .match.match--widget .match__info{
    bottom:16px;
    right:16px;
  }
  .match.match--widget .match__score span:first-child{
    margin-bottom:8px;
  }
}

@media (min-width:1280px){
  .match{
    padding:8px 32px;
    height:60px;
  }
  .news .match .match__club:first-child{
    padding-right:40px;
  }
  .news .match .match__club:last-child{
    padding-left:40px;
  }
  .match__club img{
    width:32px;
    height:32px;
    margin-top:-16px;
  }
  .match__club:first-child{
    padding-right:44px;
  }
  .match__club:last-child{
    padding-left:44px;
  }
  .match.match--widget:not(:last-child){
    margin-bottom:0;
  }
}

.match{
  height:80px;
}

@media (min-width:1280px){
  .match,.news .match .match__score{
    font-size:20px;
  }
  .match__score{
    font-size:25px;
  }
}

.ranking{
  padding-left:16px;
  padding-right:16px;
  font-weight:700;
  font-family:Din;
  font-size:15px;
  background-color:#fcfcfc;
}

.ranking p{
  margin:0;
}

.ranking img{
  margin-right:16px;
}

.ranking abbr{
  text-decoration:none;
}

.ranking table{
  width:100%;
  border-collapse:collapse;
}

.page .ranking table{
  margin-bottom:0;
  margin-top:0;
}

.ranking td,.ranking th{
  padding-top:16px;
  padding-bottom:16px;
  display:none;
}

.ranking thead{
  border-bottom:1px solid #ebebeb;
  color:#ccc;
}

.ranking thead .ranking__point{
  color:#292929;
}

.ranking thead th{
  text-align:left;
}

.ranking tbody td:not(.ranking__count){
  border-bottom:1px solid #ebebeb;
}

.ranking tbody .ranking__count{
  font-size:12px;
  color:#999;
}

.ranking tbody tr:last-child td{
  border-bottom:0;
}

.ranking .ranking__count,.ranking .ranking__name,.ranking .ranking__point{
  display:table-cell;
}

.ranking .ranking__name{
  padding-left:8px;
}

.ranking .ranking__name div{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.ranking .ranking__name img{
  max-height:28px;
  max-width:28px;
}

.ranking th.ranking__name{
  padding-left:54px;
}

.ranking--small{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:100%;
  -ms-flex-pack:distribute;
      justify-content:space-around;
  text-align:center;
  padding-left:0;
  padding-right:0;
}

.ranking--small,.ranking--small div{
  overflow:hidden;
  white-space:nowrap;
}

.ranking--small div{
  display:inline-block;
  text-overflow:ellipsis;
  text-align:left;
}

.ranking--small div:first-child span{
  margin-left:0;
}

.ranking--small div:nth-child(n+3){
  display:none;
}

.ranking--small div span{
  color:#999;
  margin-left:16px;
}

.ranking--small div img{
  margin-left:8px;
  margin-right:8px;
}

.ranking--opposition{
  border:1px solid #ebebeb;
  border-radius:4px;
  padding-left:21px;
  padding-right:17px;
}

.ranking--opposition td,.ranking--opposition th{
  display:table-cell;
}

.ranking--opposition tr{
  color:inherit;
}

.ranking--opposition .ranking__count,.ranking--opposition .ranking__point{
  color:#292929;
}

.ranking--opposition .ranking__name{
  font-weight:400;
}

.ranking--opposition th.ranking__stat{
  font-size:12px;
}

.ranking--opposition .ranking__stat{
  color:#999;
  padding:0 11px;
}

.ranking--opposition .ranking__form,.ranking--opposition .ranking__last,.ranking--opposition .ranking__stat:not(.points){
  display:none;
}

.ranking--opposition .ranking__point,.ranking--opposition .ranking__stat{
  text-align:center;
  padding:0 11px;
  white-space:nowrap;
  text-transform:uppercase;
}

.ranking__name p{
  font-weight:400;
  font-family:Roboto;
  font-size:16px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding-right:24px;
}

.ranking__count{
  width:24px;
}

.ranking__point{
  width:40px;
}

.ranking__history{
  font-size:0;
  text-align:right;
}

.ranking__history li{
  display:inline-block;
  width:10px;
  height:10px;
  border-radius:100%;
  background-color:#ccc;
  margin-left:4px;
}

.ranking__history li:first-child{
  margin-left:0;
}

.ranking__history li.has-win{
  background-color:#e6679a;
}

@media (min-width:560px){
  .ranking.ranking--small div:nth-child(n+3){
    display:inline-block;
  }
  .ranking.ranking--small div:nth-child(n+4){
    display:none;
  }
}

@media (min-width:768px){
  .ranking--opposition{
    padding-left:22px;
    padding-right:32px;
  }
  .ranking--opposition .ranking__stat{
    width:40px;
  }
  .ranking .ranking__name img{
    max-height:30px;
    max-width:30px;
  }
}

@media (min-width:1280px){
  .ranking__title{
    width:calc(100% - 220px);
  }
  .ranking td,.ranking th{
    display:table-cell;
  }
  .ranking th{
    padding-top:24px;
    padding-bottom:24px;
  }
  .ranking tbody .ranking__stat{
    color:#999;
  }
  .ranking .ranking__last{
    width:86px;
    text-align:right;
  }
  .ranking.ranking--small div:nth-child(n+3),.ranking.ranking--small div:nth-child(n+4){
    display:inline-block;
  }
  .ranking.ranking--small div:nth-child(n+5){
    display:none;
  }
  .ranking.ranking--small div img{
    margin-left:16px;
    margin-right:16px;
  }
  .ranking .ranking__form,.ranking .ranking__last,.ranking .ranking__stat:not(.points){
    display:table-cell;
  }
  .ranking+.link-chevron.link-chevron--external{
    position:absolute;
    top:0;
    right:0;
  }
  .ranking__count{
    width:40px;
  }
  .ranking__stat{
    width:56px;
  }
  .ranking__point{
    width:70px;
  }
  .ranking .ranking__name p{
    font-size:14px;
  }
  .ranking .ranking__name img{
    max-height:32px;
    max-width:32px;
  }
  .ranking--opposition br{
    display:none;
  }
  .ranking--opposition tr:first-child td:first-child{
    position:relative;
  }
  .ranking--opposition tr:first-child td:first-child:after{
    position:absolute;
    content:"....";
    left:3px;
    bottom:2px;
    height:16px;
    width:1px;
    line-height:7px;
    color:#999;
    opacity:.6;
  }
}

.social-wall{
  position:relative;
  background-color:#280b0d;
  padding-top:40px;
  padding-bottom:40px;
}

.social-wall__more{
  position:absolute;
  width:100%;
  bottom:30px;
  left:0;
  padding-top:90px;
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(3, 57, 113, 0)), color-stop(69%, #280b0d));
  background:linear-gradient(180deg, rgba(3, 57, 113, 0) 0, #280b0d 69%);
}

.social-wall__more a{
  position:relative;
}

@media (min-width:768px){
  .social-wall{
    padding-bottom:56px;
  }
  .social-wall__more{
    bottom:40px;
  }
}

@media (min-width:1280px){
  .social-wall{
    padding-top:56px;
    padding-bottom:80px;
  }
  .social-wall__more{
    bottom:80px;
  }
}

.form-action div:last-child{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:24px;
}

.form-action button[type=reset]{
  margin-right:24px;
}

.form-action button[type=reset]:hover{
  color:#e6679a;
}

.form-action button[type=submit]{
  width:100%;
}

.form-action p{
  margin:0;
}

@media (min-width:768px){
  .form-row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .form-row .input{
    width:50%;
    margin-left:16px;
  }
  .form-row .input:first-child{
    margin-left:0;
  }
  .form-action{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
  }
  .form-action div:last-child{
    margin-top:0;
  }
  .form-action button[type=submit]{
    width:auto;
  }
}

.form-contact .form-contact__row{
  display:block;
}

.form-contact .form-contact__input{
  width:100%;
}

.form-contact .form-contact__textarea textarea{
  min-height:168px;
  padding-top:16px;
}

.form-contact .form-action{
  margin-top:24px;
}

.form-contact .form-action .btn{
  width:100%;
}

@media (min-width:768px){
  .form-contact .form-contact__row{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .form-contact .form-contact__row>div{
    margin-left:32px;
    -webkit-box-flex:4;
        -ms-flex-positive:4;
            flex-grow:4;
  }
  .form-contact .form-contact__row>div:first-child{
    margin-left:0;
  }
  .form-contact .form-contact__row.is-right{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end;
  }
  .form-contact .form-contact__input{
    width:calc(50% - 16px);
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .form-contact .form-action{
    margin-bottom:24px;
  }
}

@media (min-width:1280px){
  .form-contact .form-action{
    margin-bottom:0;
  }
}

.block-contact{
  width:100%;
}

.block-contact p{
  color:#999;
}

.block-contact .block-contact__faq{
  padding:24px;
  border:1px solid rgba(15, 40, 66, .2);
}

.block-contact .block-contact__faq h3{
  margin-bottom:32px;
}

.block-contact .block-contact__faq .link-chevron{
  border-bottom:1px solid rgba(41, 41, 41, .1);
  padding-bottom:16px;
}

.block-contact .block-contact__faq .link-chevron:last-child{
  border-bottom:0;
  padding-bottom:0;
  margin-bottom:0;
}

.block-contact .block-contact__faq a{
  position:relative;
  margin-bottom:16px;
}

.block-contact .block-contact__faq a span{
  display:inline-block;
  max-width:296px;
}

.block-contact .block-contact__faq a i{
  top:30%;
  position:absolute;
  right:0;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}

.block-contact .block-contact__faq a,.block-contact .block-contact__faq i{
  line-height:22px;
}

@media (min-width:768px){
  .block-contact .block-contact__faq{
    padding:32px 24px 16px;
  }
  .block-contact .block-contact__faq h3{
    margin-bottom:16px;
  }
  .block-contact .block-contact__faq a{
    width:calc(100% - 16px);
    margin-bottom:0;
    height:52px;
    line-height:52px;
    margin-left:16px;
  }
  .block-contact .block-contact__faq a i{
    top:53%;
  }
}

.mosaic-images{
  width:100%;
  height:100%;
}

.mosaic-images__left,.mosaic-images__right{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  font-size:0;
}

.mosaic-images__left>a,.mosaic-images__left>div,.mosaic-images__right>a,.mosaic-images__right>div{
  display:inline-block;
  width:calc(50% - 8px);
  background-size:cover;
  min-width:161px;
  min-height:161px;
  margin-bottom:16px;
  cursor:pointer;
}

.mosaic-images__left>a:nth-child(2n),.mosaic-images__left>div:nth-child(2n),.mosaic-images__right>a:nth-child(2n),.mosaic-images__right>div:nth-child(2n){
  margin-right:0;
}

.mosaic-images__left>a img,.mosaic-images__left>div img,.mosaic-images__right>a img,.mosaic-images__right>div img{
  display:none;
}

.mosaic-images__left>a:after,.mosaic-images__left>div:after,.mosaic-images__right>a:after,.mosaic-images__right>div:after{
  content:"";
  display:block;
  padding-bottom:100%;
}

.mosaic-images .card--video{
  min-height:161px;
  height:43vw;
  width:100%;
  margin-right:0;
}

.mosaic-images .card--video h5{
  margin-top:0;
}

.mosaic-images .card,.mosaic-images .card .card__thumbnail{
  border-radius:0;
}

@media (min-width:768px){
  .mosaic-images__left,.mosaic-images__right{
    display:block;
  }
  .mosaic-images__left>a,.mosaic-images__left>div,.mosaic-images__right>a,.mosaic-images__right>div{
    width:calc(33.33333% - 11px);
    min-width:inherit;
    min-height:146px;
    margin-right:16px;
  }
  .mosaic-images__left>a:nth-child(2n),.mosaic-images__left>div:nth-child(2n),.mosaic-images__right>a:nth-child(2n),.mosaic-images__right>div:nth-child(2n){
    margin-right:16px;
  }
  .mosaic-images__left>a:nth-child(3n):not(.card),.mosaic-images__left>div:nth-child(3n):not(.card),.mosaic-images__right>a:nth-child(3n):not(.card),.mosaic-images__right>div:nth-child(3n):not(.card){
    margin-right:0;
  }
  .mosaic-images .card--video{
    min-height:146px;
    margin-right:16px;
    width:calc(66.66% - 5px);
  }
}

@media (min-width:1280px){
  .mosaic-images{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin-top:-8px;
  }
  .mosaic-images__left,.mosaic-images__right{
    width:51%;
  }
  .mosaic-images__left>a,.mosaic-images__left>div,.mosaic-images__right>a,.mosaic-images__right>div{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    width:calc(50% - 16px);
    vertical-align:top;
    min-height:192px;
    margin-right:16px !important;
  }
  .mosaic-images__left>a:nth-child(2n),.mosaic-images__left>div:nth-child(2n),.mosaic-images__right>a:nth-child(2n),.mosaic-images__right>div:nth-child(2n){
    margin-right:inherit;
  }
  .mosaic-images__left>a:nth-child(9n+3),.mosaic-images__left>div:nth-child(9n+3){
    width:calc(100% - 16px);
    height:inherit;
  }
  .mosaic-images__right{
    margin-right:-16px;
  }
  .mosaic-images__right>a:nth-child(9n),.mosaic-images__right>div:nth-child(9n){
    width:calc(100% - 16px);
    height:inherit;
  }
}

.directory .member{
  padding:24px;
  border-bottom:2px solid #ebebeb;
}

.directory .member:last-child{
  border-bottom:0;
}

.directory .member--small{
  padding:0;
  border:2px solid transparent;
}

.directory .member--area{
  padding-right:72px;
}

.directory .col-lg-1on9{
  clear:both;
}

.directory__ref{
  position:relative;
  top:10px;
  font-family:Din;
  font-size:17px;
}

.directory__items{
  margin:-20px 0 32px 28px;
}

.directory__members{
  margin:0 2px;
}

.directory__members.accordion-panel{
  border-radius:0 0 4px 4px;
}

@media (min-width:768px){
  .directory__ref{
    font-size:40px;
    line-height:1;
    top:55px;
  }
  .directory__items{
    margin:-40px 0 24px 57px;
  }
}

@media (min-width:1280px){
  .directory__ref{
    top:25px;
  }
  .directory__items{
    margin:0 0 32px;
  }
}

.header-filter .link-chevron{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  line-height:48px;
  vertical-align:top;
  margin-bottom:8px;
  height:48px;
}

.header-filter .link-chevron i{
  line-height:48px;
  margin-left:16px;
}

.header-filter .link-chevron i.icon-external{
  margin-left:8px;
}

.header-filter .input--dropdown{
  margin-bottom:8px;
}

.header-filter__sep{
  position:relative;
  border-bottom:1px solid #ebebeb;
  width:100%;
  right:0;
}

.header-filter__sep.mobile-only{
  border-bottom:none;
}

.header-filter .header-filter__left{
  display:inline-block;
  margin-left:-16px;
}

.header-filter--team .header-filter__right{
  min-width:243px;
}

.header-filter--team .header-filter__right .input--dropdown:before{
  left:0;
}

.header-filter--team .header-filter__right .input--dropdown input{
  padding-left:24px;
}

.header-filter--teamWfilter .header-filter__left .dropdown{
  display:inline-block;
}

.header-filter--teamWfilter .header-filter__left .dropdown .dropdown__head{
  height:40px;
  line-height:40px;
}

.header-filter--specific.header-filter--specific--Wfilter .header-filter__right{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.header-filter--specific .link-chevron{
  display:inline-block;
}

.header-filter--specific .header-filter__left,.header-filter--specific .header-filter__right,.header-filter--specific .link-chevron i{
  margin-left:0;
}

.header-filter--specific .header-filter__left>p,.header-filter--specific .header-filter__right>p{
  display:inline-block;
  margin-bottom:0;
  margin-right:8px;
  margin-top:-5px;
  color:rgba(41, 41, 41, .4);
}

.header-filter--specific .header-filter__left>div,.header-filter--specific .header-filter__left>p,.header-filter--specific .header-filter__right>div,.header-filter--specific .header-filter__right>p{
  vertical-align:middle;
}

.header-filter--specific .header-filter__right{
  display:none;
}

.header-filter--simple .col{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.header-filter--simple .header-filter__left{
  margin-left:0;
  -webkit-transform:translateY(-4px);
          transform:translateY(-4px);
}

.header-filter--simple .header-filter__left p{
  display:inline-block;
  margin-bottom:0;
  margin-right:8px;
  margin-top:-5px;
  color:rgba(41, 41, 41, .4);
}

.header-filter--simple .header-filter__right{
  margin-right:-16px;
}

@media (min-width:475px){
  .header-filter--teamWfilter .header-filter__left .dropdown .dropdown__head{
    height:48px;
    line-height:48px;
  }
}

@media (min-width:768px){
  .header-filter .dropdown--noBorder{
    display:inline-block;
  }
  .header-filter .link-chevron{
    width:auto;
  }
  .header-filter .input--dropdown{
    margin-top:0;
    margin-left:0;
  }
  .header-filter .header-filter__right{
    float:right;
  }
  .header-filter--year .header-filter__left{
    margin-left:-16px;
  }
  .header-filter--competition .header-filter__right,.header-filter--team .header-filter__right{
    float:none;
  }
  .header-filter--team .header-filter__right .input--dropdown:before{
    left:16px;
  }
  .header-filter--team .header-filter__right .input--dropdown input{
    padding-left:40px;
  }
}

@media (min-width:855px){
  .header-filter--team .header-filter__right{
    position:absolute;
    right:0;
    top:0;
    width:auto;
    margin-top:0;
  }
  .header-filter--team .header-filter__left{
    margin-left:-16px;
  }
  .header-filter--specific .header-filter__left{
    margin-left:0;
  }
  .header-filter.header-filter--specific--Wfilter .header-filter__right{
    display:inline-block;
  }
}

@media (min-width:1080px){
  .header-filter--competition .header-filter__right{
    float:right;
  }
  .header-filter--specific.header-filter--specific--Wfilter .header-filter__right{
    right:0;
  }
  .header-filter--specific .header-filter__right{
    display:inline-block;
    min-width:213px;
    right:-16px;
  }
}

.player-detail{
  border-bottom:1px solid #ebebeb;
  padding-bottom:48px;
}

.player-detail__head{
  margin-top:-52px;
}

.player-detail__head .link-chevron{
  position:absolute;
  top:15px;
  left:154px;
  width:70px;
  white-space:nowrap;
  overflow:hidden;
  color:#fff;
}

.player-detail__head .link-chevron:hover{
  color:#e6679a;
}

@media (min-width:540px){
  .player-detail__head .link-chevron{
    left:0;
  }
}

@media (min-width:768px){
  .player-detail__head .link-chevron{
    left:0;
    top:30px;
    width:auto;
    overflow:visible;
  }
}

.player-detail__head .player-portrait{
  float:left;
  width:130px;
  height:175px;
  margin-right:24px;
  background-repeat:no-repeat center center;
  background-size:cover;
  border-radius:4px;
}

.player-detail__head .player-portrait img{
  display:none;
}

@media (min-width:540px){
  .player-detail__head .player-portrait{
    width:180px;
    height:240px;
  }
}

@media (min-width:768px){
  .player-detail__head .player-portrait{
    max-width:48%;
    width:286px;
    height:42vw;
    margin-bottom:0;
    margin-right:32px;
  }
}

@media (min-width:940px){
  .player-detail__head .player-portrait{
    height:388px;
  }
}

.player-detail__head>div:last-of-type{
  position:relative;
  padding-top:68px;
}

@media (min-width:540px){
  .player-detail__head>div:last-of-type{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start;
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start;
    margin-left:212px;
    min-height:286px;
    padding-top:76px;
  }
}

@media (min-width:768px){
  .player-detail__head>div:last-of-type{
    margin-left:calc(48% + 32px);
    min-height:388px;
    padding-top:113px;
  }
}

@media (min-width:940px){
  .player-detail__head>div:last-of-type{
    margin-left:318px;
  }
}

.player-detail__head h3{
  font-size:30px;
}

.player-detail__head ul{
  clear:both;
}

.player-detail__head ul li{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -ms-flex-line-pack:center;
      align-content:center;
}

.player-detail__head ul li span{
  color:#999;
  width:154px;
}

@media (min-width:768px){
  .player-detail__head ul li span{
    width:124px;
  }
}

.player-detail__head ul li p{
  display:inline-block;
  font-size:15px;
  line-height:20px;
}

.player-detail__head .role{
  display:block;
  font-weight:500;
  color:#999;
}

@media (min-width:768px){
  .player-detail__head .role{
    font-size:17px;
  }
}

@media (min-width:540px){
  .player-detail__head .btn{
    width:auto;
  }
}

@media (min-width:768px){
  .player-detail__head .btn{
    margin-top:auto;
  }
}

@media (min-width:768px){
  .player-detail__head{
    margin-top:-81px;
  }
}

.player-detail h2{
  line-height:48px;
}

.player-detail>div>p:not(.ft-h6){
  font-size:18px;
  line-height:32px;
  margin-bottom:16px;
}

.player-detail .card--club{
  margin-bottom:16px;
}

.player-detail .section{
  padding-left:0;
  padding-right:0;
  padding-bottom:0;
}

.player-detail .menu-team{
  display:inline-block;
}

.player-detail .menu-team .accordion-panel{
  height:inherit;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  padding:24px;
}

@media (min-width:768px){
  .player-detail .menu-team .accordion-panel{
    padding:32px 40px;
  }
}

.player-detail .menu-team .accordion-panel>li{
  display:block;
  width:100%;
  margin-top:16px;
}

.player-detail .menu-team .accordion-panel>li:first-child{
  margin-top:0;
}

.player-detail .news-slider__wrapper--video{
  width:604px;
}

@media (min-width:768px){
  .player-detail .news-slider__wrapper--video .card--video{
    width:calc(50% - 8px);
  }
}

@media (min-width:1280px){
  .player-detail .news-slider__wrapper--video .card--video{
    width:calc(50% - 12px);
  }
}

@media (min-width:768px){
  .player-detail .news-slider__wrapper--video{
    width:auto;
  }
}

.auteur-detail h1{
  font-size:30px;
  line-height:37px;
}

.auteur-detail .role{
  font-size:18px;
}

.auteur-detail .player-detail__head p{
  font-size:17px;
  line-height:32px;
}

.auteur-detail .player-detail__head h3{
  font-size:20px;
}

@media (min-width:540px){
  .auteur-detail .player-detail__head h3{
    margin-top:32px;
  }
}

.auteur-detail .player-portrait span{
  display:none;
  height:100%;
  background-repeat:no-repeat;
  background-position:50%;
}

.auteur-detail .player-portrait.player-portrait--empty{
  background:none;
  background-color:#ebebeb;
}

.auteur-detail .player-portrait.player-portrait--empty span{
  display:block;
}

.properties{
  border:1px solid #ebebeb;
  border-radius:4px;
}

.properties__title{
  padding:0 24px;
  line-height:48px;
  color:#e6679a;
  border-bottom:1px solid #ebebeb;
}

.properties__items{
  padding:24px;
  margin:0;
  font-size:17px;
  line-height:24px;
  color:#999;
}

.properties__items li{
  position:relative;
  margin-bottom:16px;
  padding-left:26px;
}

.properties__items li:last-child{
  margin-bottom:0;
}

.properties__items .icon{
  position:absolute;
  left:0;
  top:5px;
  color:#7d93a8;
}

.page-maintenance .topbar{
  padding-left:8px;
}

.page-maintenance .topbar .topbar__right{
  display:none;
}

@media (min-width:768px){
  .page-maintenance .topbar .topbar__right{
    display:inline-block !important;
  }
}

.page-maintenance--ffr .topbar li:first-child,.page-maintenance .topbar li:nth-child(5),.page-maintenance .topbar li:nth-child(6){
  display:none;
}

.page-maintenance--ffr .topbar li:nth-child(2) a{
  padding-left:24px;
}

.page-maintenance--ligue .topbar li:nth-child(4){
  display:none;
}

@media (min-width:0) and (max-width:1080px){
  .page-maintenance .topbar{
    font-size:13px;
    line-height:40px;
    padding:0;
    margin-bottom:0;
  }
  .page-maintenance .topbar a{
    padding:0 8px;
  }
  .page-maintenance .topbar li{
    display:inline-block;
    margin-bottom:0;
  }
  .page-maintenance .topbar li:first-child a{
    padding-left:24px;
  }
  .page-maintenance .topbar li:nth-child(7){
    display:none;
  }
  .page-maintenance .topbar .accordion-trigger{
    padding:0 24px;
  }
  .page-maintenance .topbar .accordion-trigger:after{
    margin-left:8px;
    line-height:40px;
    float:none;
    content:"\e906";
  }
  .page-maintenance .topbar .accordion-trigger.is-selected{
    background-color:#004b98;
    color:#fff;
    text-decoration:none;
  }
  .page-maintenance .topbar .accordion-trigger.is-selected:after{
    content:"\e914";
  }
  .page-maintenance .topbar .topbar__sub{
    width:230px;
    position:absolute;
    background-color:#004b98;
    line-height:2;
    padding:8px 24px;
    color:hsla(0, 0%, 100%, .6);
  }
  .page-maintenance .topbar .topbar__sub li{
    display:block;
  }
  .page-maintenance .topbar .topbar__sub a{
    padding:0;
  }
  .page-maintenance .topbar .topbar__right{
    float:right;
  }
}

@media (min-width:0) and (max-width:1080px) and (min-width:768px){
  .page-maintenance .topbar .topbar__right{
    display:inline-block;
  }
}

@media (min-width:0) and (max-width:1080px){
  .page-maintenance .topbar .topbar__right i{
    top:-2px;
  }
  .page-maintenance .topbar .topbar__right .topbar__sub{
    padding-bottom:16px;
  }
  .page-maintenance .topbar .topbar__user{
    padding:0 24px;
    color:#fff;
    max-width:220px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    border-left:1px solid hsla(0, 0%, 100%, .06);
  }
  .page-maintenance .topbar .topbar__user img{
    margin-right:8px;
    border-radius:100%;
  }
  .page-maintenance .topbar .topbar__login{
    background:#1a395a;
    color:#fff;
    line-height:32px;
    padding:0 8px;
    border-radius:4px;
    margin-right:4px;
    margin-top:4px;
  }
}

@media (min-width:0) and (max-width:1080px) and (min-width:768px){
  .page-maintenance .topbar .topbar__login{
    display:inline-block;
  }
}

@media (min-width:0) and (max-width:1080px){
  .page-maintenance .topbar .topbar__login:hover{
    background:#20456e;
  }
  .page-maintenance .topbar .topbar__news{
    display:none !important;
  }
}

@media (min-width:768px){
  .page-maintenance .topbar li:first-child,.page-maintenance .topbar li:nth-child(5),.page-maintenance .topbar li:nth-child(6){
    display:inline-block;
  }
  .page-maintenance .topbar li:nth-child(2) a{
    padding-left:8px;
  }
  .page-maintenance .topbar li:nth-child(4),.page-maintenance .topbar li:nth-child(7){
    display:inline-block;
  }
}

@media (min-width:768px) and (max-width:1080px){
  .page-maintenance .topbar li:nth-child(5),.page-maintenance .topbar li:nth-child(6){
    display:inline-block;
  }
  .page-maintenance .topbar li:nth-child(7){
    display:none;
  }
}

.page-maintenance{
  margin-bottom:24px;
}

.page-maintenance .container{
  padding:0;
}

.page-maintenance .copyright{
  margin-top:24px;
  text-align:center;
  opacity:.2;
  font-size:14px;
}

.block-maintenance{
  background:#fff;
  padding:48px 24px;
  -webkit-box-shadow:0 4px 10px 0 rgba(13, 24, 33, .1);
          box-shadow:0 4px 10px 0 rgba(13, 24, 33, .1);
}

@media (min-width:768px){
  .block-maintenance{
    width:704px;
    border-radius:2px;
    border:1px solid #ebebeb;
    border-radius:4px;
    margin:112px auto 0;
    padding:96px 54px;
  }
}

@media (min-width:1280px){
  .block-maintenance{
    width:816px;
  }
}

.block-maintenance>div{
  max-width:327px;
  margin:0 auto;
}

@media (min-width:768px){
  .block-maintenance>div{
    max-width:614px;
  }
}

.block-maintenance>div img{
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:43px;
  width:76px;
  height:auto;
}

.block-maintenance>div h2{
  margin-bottom:16px;
  text-align:center;
}

.block-maintenance>div h2 br{
  display:none;
}

@media (min-width:768px){
  .block-maintenance>div h2 br{
    display:block;
  }
}

@media (min-width:1280px){
  .block-maintenance>div h2 br{
    display:none;
  }
}

.block-maintenance>div p{
  text-align:center;
  color:#999;
  margin-bottom:32px;
}

@media (min-width:768px){
  .block-maintenance>div p{
    max-width:538px;
    margin-left:auto;
    margin-right:auto;
  }
}

.block-maintenance>div a{
  display:block;
  width:100%;
}

@media (min-width:768px){
  .block-maintenance>div a{
    display:inline-block;
    max-width:291px;
  }
}

@media (min-width:1280px){
  .block-maintenance>div a{
    max-width:301px;
  }
}

.block-maintenance>div a.btn--facebook{
  margin-bottom:12px;
}

@media (min-width:768px){
  .block-maintenance>div a.btn--facebook{
    margin-bottom:0;
  }
}

@media (min-width:768px){
  .block-maintenance>div a.btn--twitter{
    margin-left:12px;
  }
}

.block-maintenance--ligue>div h2 br{
  display:none;
}

@media (min-width:768px){
  .block-maintenance--ligue>div h2 br{
    display:block;
  }
}

.block-maintenance--ligue>div img{
  width:300px;
}

.accordion{
  border-radius:0;
}

.accordion.accordion--mini .accordion-trigger{
  border-radius:4px;
}

.accordion.accordion--mini .accordion-trigger,.accordion.accordion--mini .accordion-trigger.is-selected{
  -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:0 10px 16px rgba(51, 51, 51, .08);
}

.accordion.accordion--mini .accordion-trigger.is-selected{
  border-bottom:0;
}

.accordion.accordion--mini .btn--icon{
  position:absolute;
  right:16px;
  top:50%;
  margin-top:-16px;
}

.accordion.accordion--mini .accordion-panel{
  border:1px solid #ebebeb;
  border-radius:4px;
  border-top:0;
}

.accordion.accordion--mini .accordion-panel:last-child,.accordion .accordion-trigger{
  border-bottom:1px solid #ebebeb;
}

.accordion .accordion-trigger{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:73px;
  width:100%;
  background-color:#fff;
}

.accordion .accordion-trigger:hover{
  cursor:pointer;
}

.accordion .accordion-trigger:nth-last-child(2){
  border-bottom:0;
}

.accordion .accordion-trigger.is-selected{
  -webkit-box-shadow:none;
          box-shadow:none;
  border-bottom:1px solid #ebebeb;
}

.accordion .accordion-trigger .btn--icon i{
  font-weight:700;
}

.accordion .accordion-panel{
  border-bottom:1px solid #ebebeb;
  margin-bottom:0;
  padding-bottom:0;
  background-color:#fcfcfc;
  overflow:hidden;
}

.accordion .accordion-panel.is-hidden{
  padding:0;
  max-height:0;
}

.accordion .accordion-panel:last-child{
  border-bottom:0;
}

.accordion .accordion-panel .content{
  padding:24px 16px;
}

.accordion .accordion-panel .content .list,.accordion .accordion-panel .content p{
  font-size:14px;
  line-height:22px;
}

.accordion .accordion-panel .card:last-child{
  margin-bottom:8px;
}

.accordion .directory .member{
  border-bottom-width:1px;
}

.accordion .ft-h4{
  font-size:15px;
}

@media (min-width:768px){
  .accordion .accordion-trigger{
    padding:16px 20px 16px 40px;
  }
  .accordion .accordion-trigger h4{
    width:calc(100% - 56px);
  }
  .accordion .accordion-trigger .btn--icon{
    right:20px;
  }
  .accordion .accordion-panel .content{
    padding:32px 40px;
  }
}

@media (min-width:1280px){
  .accordion .content{
    padding:32px 48px 40px 40px;
  }
}

@-webkit-keyframes fadeIn{
  0%{
    opacity:0;
  }
  to{
    opacity:1;
  }
}

@keyframes fadeIn{
  0%{
    opacity:0;
  }
  to{
    opacity:1;
  }
}

.block-contact-persons .contact__staff{
  padding:0;
}

.block-contact-persons .card__body>p{
  font-size:16px;
}

.block-contact-persons .member{
  display:block;
  padding:15px 0 10px;
  border-bottom:1px solid #ebebeb;
}

.block-contact-persons .member:first-child{
  padding-top:5px;
}

.block-contact-persons .member:last-child{
  border-bottom:0;
  padding-bottom:0;
}

.block-contact-persons--sidebar{
  padding:0;
  background-color:#fff;
  border-radius:4px;
  border:1px solid #ebebeb;
}

.block-contact-persons--sidebar .card__body{
  padding:0;
}

.block-contact-persons--sidebar .member{
  width:100%;
  padding:24px;
  border-top:1px solid #ebebeb;
}

.block-contact-persons--sidebar .member:first-child{
  border-top:0;
  padding-top:24px;
}

.block-contact-persons--sidebar .member:last-child{
  padding-bottom:24px;
}

.block-contact-persons--sidebar .member .icon{
  margin-right:24px;
}

@media (min-width:768px){
  .block-contact-persons .contact__staff{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .block-contact-persons .member{
    display:inline-block;
    width:25%;
    border-bottom:0;
    padding-top:0;
  }
  .block-contact-persons .member:first-child{
    padding-top:0;
  }
  .block-contact-persons--sidebar .contact__staff{
    display:block;
  }
  .block-contact-persons--sidebar .member{
    width:100%;
    padding-top:24px;
  }
  .block-contact-persons--sidebar .member:first-child{
    padding-top:24px;
  }
  .block-contact-persons--sidebar .member:last-child{
    padding-bottom:24px;
  }
}

.color-picker{
  padding:16px;
}

.color-picker .colors{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  margin-left:-4px;
}

.color-picker .colors--secondary{
  display:none;
}

.color-picker .color{
  border-radius:4px;
  height:38px;
  width:calc(25% - 4px);
  margin-left:4px;
  margin-bottom:4px;
  overflow:hidden;
}

.color-picker .color:nth-child(n+9){
  display:none;
}

.color-picker .color:hover i{
  opacity:1;
}

.color-picker .color label{
  display:block;
  height:100%;
  text-align:center;
  line-height:32px;
  font-size:16px;
  border:2px solid transparent;
  cursor:pointer;
}

.color-picker .color label i{
  color:#fff;
  opacity:0;
}

.color-picker .color input{
  position:absolute;
  visibility:hidden;
}

.color-picker .color input:checked+label{
  border-color:rgba(41, 41, 41, .2);
}

.color-picker .color input:checked+label i{
  opacity:1;
}

.color-picker-primary-1{
  background-color:#d62f1a;
}

.color-picker-primary-2{
  background-color:#a86cc2;
}

.color-picker-primary-3{
  background-color:#3f5c9e;
}

.color-picker-primary-4{
  background-color:#2a9bff;
}

.color-picker-primary-5{
  background-color:#42b347;
}

.color-picker-primary-6{
  background-color:#f4ca20;
}

.color-picker-primary-7{
  background-color:#eb6a38;
}

.color-picker-primary-8{
  background-color:#f49898;
}

.color-picker-primary-9{
  background-color:#e3b4f7;
}

.color-picker-primary-10{
  background-color:#aac4f9;
}

.color-picker-primary-11{
  background-color:#a9d6ff;
}

.color-picker-primary-12{
  background-color:#a1d99b;
}

.color-picker-primary-13{
  background-color:#ffe57c;
}

.color-picker-primary-14{
  background-color:#fda871;
}

.color-picker-secondary-1{
  background-color:#5d0904;
}

.color-picker-secondary-2{
  background-color:#47294f;
}

.color-picker-secondary-3{
  background-color:#00234b;
}

.color-picker-secondary-4{
  background-color:#003e69;
}

.color-picker-secondary-5{
  background-color:#004915;
}

.color-picker-secondary-6{
  background-color:#645000;
}

.color-picker-secondary-7{
  background-color:#65260f;
}

.color-picker-secondary-8{
  background-color:#8b0b00;
}

.color-picker-secondary-9{
  background-color:#6a3d77;
}

.color-picker-secondary-10{
  background-color:#1d4366;
}

.color-picker-secondary-11{
  background-color:#005e9e;
}

.color-picker-secondary-12{
  background-color:#006d1e;
}

.color-picker-secondary-13{
  background-color:#967900;
}

.color-picker-secondary-14{
  background-color:#983915;
}

@media (min-width:768px){
  .color-picker .colors{
    margin-left:-1px;
  }
  .color-picker .colors--secondary{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .color-picker .color{
    width:calc(14% - 4px);
    height:23px;
    margin-left:1px;
    margin-bottom:1px;
  }
  .color-picker .color:nth-child(n+9){
    display:block;
  }
  .color-picker .color label{
    line-height:17px;
  }
  .color-picker .color span{
    position:relative;
    display:block;
    height:100%;
    text-align:center;
    font-size:16px;
    border-width:0;
  }
  .color-picker[data-type=primary] .color span:before{
    position:absolute;
    content:"";
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:hsla(0, 0%, 100%, .45);
  }
  .color-picker[data-type=secondary] .color span:before{
    position:absolute;
    content:"";
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:hsla(0, 0%, 100%, .15);
  }
}

@media (min-width:1280px){
  .color-picker .color{
    height:32px;
  }
  .color-picker .color label{
    line-height:26px;
  }
}

.bloc-user-manage{
  padding:32px 24px;
  background-color:#fcfcfc;
  border:1px solid #ebebeb;
  border-radius:4px;
}

.bloc-user-manage .buttons .btn{
  width:100%;
  height:48px;
  line-height:48px;
}

.bloc-user-manage .icon{
  margin-right:16px;
  color:#7d93a8;
  font-size:16px;
}

.bloc-user-manage p{
  display:inline-block;
  font-size:17px;
  line-height:20px;
  margin-bottom:0;
}

@media (min-width:768px){
  .bloc-user-manage{
    padding:24px;
  }
  .bloc-user-manage .buttons{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .bloc-user-manage .buttons .btn{
    width:calc(50% - 8px);
  }
  .bloc-user-manage .icon{
    font-size:20px;
    margin-right:24px;
  }
}

@media (min-width:1280px){
  .bloc-user-manage{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
  }
  .bloc-user-manage .icon{
    font-size:24px;
  }
  .bloc-user-manage .buttons .btn{
    width:auto;
  }
  .bloc-user-manage .buttons .btn:first-child{
    margin-right:16px;
  }
}

.badge-score{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  margin:0 auto;
  width:60px;
  height:60px;
  -webkit-box-shadow:0 2px 26px 0 rgba(0, 0, 0, .3);
          box-shadow:0 2px 26px 0 rgba(0, 0, 0, .3);
  border-radius:50%;
  border:4px solid #ebebeb;
  background-color:#fff;
}

.badge-score img{
  max-height:36px;
  max-width:43px;
}

.live-results{
  position:absolute;
  top:0;
  height:46px;
  width:100%;
  border-bottom:1px solid hsla(0, 0%, 100%, .12);
  background-color:rgba(40, 11, 13, .2);
  z-index:5;
}

.live-results.has-accordion-open,.live-results.has-accordion-open .accordion-panel{
  background-color:#0f2842;
}

.live-results.has-accordion-open .accordion-trigger:after{
  content:"\e914";
}

.live-results.has-accordion-open .match .match__division{
  background-color:#0f2842;
}

.live-results>.container{
  padding:0;
}

.live-results .live-results__head{
  height:100%;
}

.live-results .live-results__head .container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.live-results .live-results__head .live-results__game-count{
  display:inline-block;
  vertical-align:middle;
}

.live-results .live-results__head .label{
  margin-bottom:0;
  margin-right:12px;
}

.live-results .live-results__head .dropdown__head{
  position:relative;
  padding:0 16px 0 24px;
  height:45px;
}

.live-results .live-results__head .dropdown__head:after{
  top:10px;
  right:0;
}

.live-results .accordion-panel{
  padding-bottom:16px;
}

.live-results .billboard__sub{
  color:#292929;
}

@media (min-width:768px){
  .live-results{
    height:72px;
  }
  .live-results .container{
    height:100%;
  }
  .live-results .match:hover{
    cursor:pointer;
    background-color:rgba(40, 11, 13, .4);
  }
}

@media (min-width:1080px){
  .live-results{
    top:106px;
    height:92px;
  }
  .live-results>.container{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:0 0 0 60px;
  }
  .live-results .live-results__head{
    padding-right:24px;
    border-right:1px solid hsla(0, 0%, 100%, .12);
  }
  .live-results .live-results__head .container{
    padding:0;
  }
  .live-results .accordion-trigger{
    display:none;
  }
  .live-results .match{
    min-width:300px;
    height:91px;
    display:inline-block;
  }
  .live-results .match.match--widget{
    margin-bottom:0;
  }
  .live-results .accordion-panel{
    display:block;
    max-height:inherit;
    visibility:visible;
    opacity:1;
    -webkit-box-flex:1;
        -ms-flex:1;
            flex:1;
    overflow:auto;
    padding-bottom:0;
  }
  .live-results .accordion-panel .container{
    padding:0;
  }
  .live-results .live-results__wrapper{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    overflow-y:hidden;
  }
  .live-results .match{
    padding:2px 16px 0;
    border-right:1px solid hsla(0, 0%, 100%, .12);
  }
}

.block-bonus{
  position:relative;
  min-height:120px;
  padding:24px 32px;
  background:#fcfcfc;
  border:1px solid #ebebeb;
  border-radius:4px;
  overflow:hidden;
  font-family:14px;
  font-weight:700;
}

.block-bonus,.block-bonus>div{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.block-bonus>div span img{
  height:29px;
  max-width:29px;
}

.block-bonus .number{
  font-size:40px;
  line-height:51px;
}

.block-bonus p{
  padding-left:16px;
  font-size:12px;
  color:#999;
  margin-bottom:0;
}

.block-bonus strong{
  color:#292929;
}

.block-bonus>img{
  position:absolute;
  bottom:-6px;
  right:-6px;
}

.block-bonus>svg{
  position:absolute;
  bottom:0;
  right:0;
}

@media (min-width:1280px){
  .block-bonus{
    padding-right:48px;
  }
  .block-bonus>div{
    max-width:78%;
  }
}

.place-event{
  font-size:17px;
  line-height:24px;
}

.place-event>span{
  display:block;
}

.place-event p{
  color:#999;
}

.place-event .link-chevron .icon{
  margin-left:32px;
}

.block-cards-news .col:first-child .news{
  padding-top:0;
}

.block-cards-news .col:first-child article{
  display:block;
}

.block-cards-news .col:first-child .news__thumbnail{
  height:223px;
  width:100%;
  margin-left:0;
  margin-bottom:16px;
}

.block-cards-news .link-chevron{
  padding-bottom:16px;
  border-bottom:1px solid #ebebeb;
}

@media (min-width:768px){
  .block-cards-news .col:first-child .news__thumbnail{
    height:188px;
    margin-bottom:8px;
  }
}

@media (min-width:1280px){
  .block-cards-news .col:first-child .news__thumbnail{
    height:188px;
    margin-bottom:8px;
  }
  .block-cards-news .news__thumbnail{
    height:188px;
    margin-bottom:16px;
  }
}

.composition.officials li{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  text-transform:uppercase;
}

.composition.officials li span{
  min-width:50%;
  margin-right:32px;
  font-weight:500;
  color:#999;
  text-transform:none;
}

.composition .composition__hoverCard{
  position:absolute;
  top:0;
  left:0;
  width:345px;
  height:84px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  background-color:#fff;
  border-radius:4px;
  -webkit-box-shadow:1px 1px 8px 2px rgba(51, 51, 51, .08);
          box-shadow:1px 1px 8px 2px rgba(51, 51, 51, .08);
  z-index:200;
  overflow:hidden;
  pointer-events:none;
  opacity:0;
  -webkit-transition:opacity 1s cubic-bezier(.2, 1, .5, 1),-webkit-transform .2s cubic-bezier(.2, 0, .3, 1);
  transition:opacity 1s cubic-bezier(.2, 1, .5, 1),-webkit-transform .2s cubic-bezier(.2, 0, .3, 1);
  transition:opacity 1s cubic-bezier(.2, 1, .5, 1),transform .2s cubic-bezier(.2, 0, .3, 1);
  transition:opacity 1s cubic-bezier(.2, 1, .5, 1),transform .2s cubic-bezier(.2, 0, .3, 1),-webkit-transform .2s cubic-bezier(.2, 0, .3, 1);
}

.composition .composition__hoverCard.active{
  opacity:1;
}

.composition .composition__hoverCard .hoverCard__portrait{
  width:84px;
  height:84px;
  background-image:url(../img/trash/home-ffr/Joueur-1-HD.jpg);
  background-size:cover;
}

.composition .composition__hoverCard .hoverCard__desc{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  padding:20px 24px;
}

.composition .composition__hoverCard .hoverCard__desc h6{
  color:#999;
}

.composition__schema{
  height:681px;
}

.composition__schema .schema{
  display:block;
  margin-left:auto;
  margin-right:auto;
  width:346px;
  height:681px;
  background-image:url(../img/bg-schema.png);
  background-size:100% 100%;
}

.composition__schema .schema__player{
  display:inline-block;
  text-align:center;
  font-family:Din;
  font-weight:700;
  max-width:48px;
  vertical-align:top;
}

.composition__schema .schema__player span{
  display:block;
  margin-left:auto;
  margin-right:auto;
  width:32px;
  height:32px;
  border-radius:50%;
  background-color:#e6679a;
  color:#fff;
  text-align:center;
  line-height:33px;
  font-size:14px;
  margin-bottom:5px;
}

.composition__schema .schema__player p{
  width:200%;
  position:relative;
  left:-50%;
  font-size:12px !important;
}

.composition__schema .schema__firstLine{
  width:100%;
  padding:78px 47px 0;
  text-align:center;
}

.composition__schema .schema__firstLine .schema__player{
  margin-right:46px;
}

.composition__schema .schema__firstLine .schema__player:last-child{
  margin-right:0;
}

.composition__schema .schema__twoPlayer{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:32px;
  padding:0 90px;
}

.composition__schema .schema__twoPlayer .schema__player:last-child{
  margin-left:auto;
}

.composition__schema .schema__twoPlayer--second{
  margin-top:46px;
}

.composition__schema .schema__thirdLine{
  margin-top:47px;
  padding:0 24px;
}

.composition__schema .schema__thirdLine .schema__player{
  margin-right:70px;
}

.composition__schema .schema__thirdLine .schema__player:last-child{
  margin-right:0;
}

.composition__schema .schema__backLine{
  margin-top:22px;
  padding:0 9px;
}

.composition__schema .schema__backLine .schema__player{
  margin-right:40px;
}

.composition__schema .schema__backLine .schema__player:last-child{
  margin-right:0;
}

.composition__schema .schema__oneLine{
  margin-top:20px;
}

.composition__schema .schema__oneLine .schema__player{
  display:block;
  margin-left:auto;
  margin-right:auto;
}

.composition__team .team__header{
  padding-bottom:16px;
  border-bottom:1px solid #ebebeb;
}

.composition__team .team__header i{
  width:36px;
  height:36px;
  color:#e6679a;
  border:2px solid #ebebeb;
  border-radius:50%;
  line-height:32px;
  margin-right:16px;
  padding-left:1px;
}

.composition__team .team__header i.icon-shirt{
  font-size:12px;
}

.composition__team .team__body ol{
  width:100%;
  display:inline-block;
  vertical-align:top;
  margin-bottom:0;
}

.composition__team .team__body ol li,.composition__team .team__body ul li{
  position:relative;
  list-style-type:none;
  font-family:Din;
  line-height:29px;
  font-size:15px;
}

.composition__team .team__body ol li p,.composition__team .team__body ul li p{
  display:inline-block;
  text-align:left;
  margin-bottom:0;
  margin-left:5px;
}

.composition__team .team__body ol li span:nth-child(2),.composition__team .team__body ul li span:nth-child(2){
  display:block;
  color:#999;
  font-size:13px;
  line-height:9px;
  margin-bottom:5px;
}

.composition__team .team__body ol li:before{
  vertical-align:top;
  color:#999;
  content:attr(data-num) ".";
}

.composition__team .team__body .substitution:after{
  display:inline-block;
  position:relative;
  top:8px;
  vertical-align:top;
}

.composition--ext .icon-less,.composition--ext .icon-plus{
  font-size:15px;
  font-weight:600;
  vertical-align:1px;
}

.captain.red-card:before,.captain.substitution:before,.captain.yellow-card:before{
  float:right;
  content:"";
  width:9px;
  height:14px;
  margin-left:4px;
  margin-top:7px;
}

.captain.red-card:after,.captain.substitution:after,.captain.yellow-card:after{
  display:inline-block;
  content:"C";
  margin-left:4px;
  width:auto;
  height:auto;
  -webkit-transform:translateY(-2px);
          transform:translateY(-2px);
  background-color:#e6679a;
  border-radius:2px;
  padding:3px 4px 2px;
  color:#fff;
  line-height:12px;
  font-size:11px;
  background-image:none;
}

.captain.yellow-card:before{
  background-color:#f7c019;
  border-radius:2px;
}

.captain.red-card:before{
  background-color:#f2362f;
  border-radius:2px;
}

.captain.substitution:before{
  width:11px;
  height:11px;
  background-image:url(../img/icon-substitution.svg);
  background-size:cover;
}

.captain:after{
  display:inline-block;
  content:"C";
  margin-left:4px;
  background-color:#e6679a;
  border-radius:2px;
  padding:3px 4px 2px;
  color:#fff;
  line-height:12px;
  font-size:11px;
}

.yellow-card:after{
  background-color:#f7c019;
}

.red-card:after,.yellow-card:after{
  display:inline-block;
  content:"";
  width:9px;
  height:14px;
  margin-left:4px;
  border-radius:2px;
}

.red-card:after{
  background-color:#f2362f;
}

.substitution:after{
  display:inline-block;
  content:"";
  width:11px;
  height:11px;
  margin-left:4px;
  background-image:url(../img/icon-substitution.svg);
  background-size:cover;
}

.substitution.yellow-card{
  position:relative;
}

.substitution.yellow-card:before{
  display:inline-block;
  position:absolute;
  right:-15px;
  content:"";
  width:9px;
  height:14px;
  background-color:#f7c019;
  border-radius:2px;
  -webkit-transform:translateY(6px);
          transform:translateY(6px);
}

.substitution.yellow-card:after{
  background-color:inherit;
}

@media (min-width:768px){
  .composition__schema{
    height:700px;
  }
  .composition__schema .schema{
    width:355px;
  }
  .composition__schema .schema__thirdLine{
    padding:0 30px;
  }
  .composition__schema .schema__backLine{
    padding:0 14px;
  }
  .composition__team .team__body ol{
    width:inherit;
  }
  .composition__team .team__body ol:first-child{
    margin-right:21px;
  }
}

@media (min-width:1280px){
  .composition__schema .schema{
    margin-right:37px;
    margin-left:0;
  }
  .composition__team{
    padding-left:32px;
  }
  .composition--ext .composition__team:last-child{
    padding-left:105px;
  }
}

.menu-team{
  position:relative;
  font-weight:500;
  min-width:250px;
}

.banner .menu-team li{
  color:#292929;
}

.menu-team .accordion-trigger:after{
  float:none;
  margin-left:8px;
  display:inline-block;
  vertical-align:middle;
  content:"\e906";
  font-size:10px;
}

.menu-team .accordion-trigger.is-selected:after{
  content:"\e914";
}

.menu-team .accordion-panel{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  position:absolute;
  top:100%;
  left:0;
  width:100%;
  height:300px;
  max-width:788px;
  background:#fff;
  margin-top:8px;
  border-bottom:3px solid #e6679a;
  padding:16px 16px 32px;
  overflow:auto;
  -webkit-transition:opacity .3s ease,max-height .3s ease,visibility .3s ease;
  transition:opacity .3s ease,max-height .3s ease,visibility .3s ease;
}

.menu-team .accordion-panel ul{
  font-size:14px;
  margin-bottom:0;
}

.menu-team .accordion-panel ul li{
  margin-top:16px;
}

.menu-team .accordion-panel a:hover{
  text-decoration:none;
}

.menu-team .accordion-panel .is-collapsed{
  margin-bottom:24px;
}

.menu-team__category{
  display:block;
  font-family:Din;
  font-size:15px;
  font-weight:700;
  color:#999;
}

@media (min-width:768px){
  .menu-team .accordion-panel{
    height:inherit;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .menu-team .accordion-panel>li{
    display:inline-block;
    vertical-align:top;
    padding-right:24px;
    width:25%;
  }
  .menu-team .accordion-panel .is-collapsed{
    padding-top:20px;
  }
}

@media (min-width:1280px){
  .menu-team .accordion-panel{
    padding:32px 40px;
  }
}

.stats-match{
  background:#fff;
  border-radius:4px;
  border:1px solid #e1e5ea;
}

.stats-match.stats-match--article{
  display:block;
}

.stats-match.stats-match--article .stats-match__header{
  padding:0;
}

.stats-match.stats-match--article hr{
  width:calc(100% + 24px);
  left:-12px;
}

.stats-match.stats-match--article:hover p,.stats-match.stats-match--article:hover span{
  color:initial;
}

.stats-match .stats-match__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  font-weight:700;
  font-family:Din;
  padding:16px 32px 0;
}

.stats-match .stats-match__header .card{
  margin:0;
  -webkit-box-shadow:inherit;
          box-shadow:inherit;
}

.stats-match .stats-match__header .card .card__header span{
  color:#292929;
}

.stats-match .stats-match__header .card .card__body{
  padding:24px 16px 0;
}

.stats-match .stats-match__header .card .label{
  display:none;
}

.stats-match .stats-match__body{
  padding:16px 32px 30px;
}

.stats-match hr{
  position:relative;
  width:100%;
  margin:0;
  border:0;
  border-top:1px solid #e1e5ea;
}

.stats-match .score{
  font-family:Din;
}

.stats-match .score__head{
  padding-top:21px;
}

.stats-match .score__head a{
  display:block;
  width:100%;
  color:#292929;
  text-align:center;
  font-size:15px;
  text-decoration:none;
  margin-bottom:0;
}

.stats-match .score__head a:after{
  position:relative;
  display:inline-block;
  font-family:icon;
  font-size:8px;
  margin-left:13px;
  top:-2px;
}

.stats-match .score__head a.is-open:after{
  content:"\e923";
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg);
}

.stats-match .score__head a.is-close:after{
  content:"\e923";
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
}

.stats-match .score__head .score__head__ext,.stats-match .score__head .score__head__int{
  font-size:20px;
  width:30px;
  display:inline-block;
}

.stats-match .score__head .score__head__ext{
  text-align:right;
}

.stats-match .score__head span.is-superior{
  font-weight:700;
}

.stats-match .score__head div{
  position:relative;
  display:inline-block;
  width:calc(100% - 60px);
  height:8px;
  background:#ccc;
  border-radius:4px;
}

.stats-match .score__head div span{
  position:absolute;
  top:0;
  display:block;
  width:80%;
  height:8px;
  border-radius:4px;
  background:#e6679a;
}

.stats-match .score__head div span.is-right{
  right:0;
}

.stats-match .score .score__head{
  font-size:14px;
}

.stats-match .score .score__head p{
  font-family:Roboto;
}

.stats-match .score.is-disabled .score__head__ext,.stats-match .score.is-disabled .score__head__int{
  color:#ccc;
}

.stats-match .score.is-disabled .score__head div span{
  width:0;
}

.stats-match .score__body{
  margin-top:10px;
  font-size:0;
}

.stats-match .score__body.is-hidden{
  display:none;
}

.stats-match .score__body div{
  width:calc(50% - 4px);
  display:inline-block;
  vertical-align:top;
  font-weight:700;
  font-size:15px;
  color:#292929;
}

.stats-match .score__body div span{
  color:#999;
  padding:0 2px;
}

.stats-match .score__body div:first-child{
  text-align:right;
  border-right:1px solid #ebebeb;
  padding-right:30px;
}

.stats-match .score__body div:last-child{
  padding-left:30px;
}

@media (min-width:768px){
  .stats-match .card__header span:last-child{
    position:absolute;
    right:16px;
    top:16px;
  }
  .stats-match .score .score__head__ext,.stats-match .score .score__head__int{
    position:relative;
    top:-5px;
    font-size:22px;
    width:40px;
  }
  .stats-match .score__head p{
    position:relative;
    top:6px;
  }
  .stats-match .score__head div{
    top:-7px;
    width:calc(100% - 80px);
  }
}

@media (min-width:1280px){
  .stats-match.stats-match--article .stats-match__body{
    padding:0 75px 30px;
  }
  .stats-match .card__header{
    height:38px;
  }
  .stats-match .card__header span:last-child{
    top:8px;
  }
  .stats-match .score__head p{
    top:0;
  }
}

.highlight{
  font-family:Din;
  display:block;
  margin:0 auto;
  width:343px;
  text-align:center;
}

.highlight.highlight--embed{
  position:relative;
  width:100%;
  border-radius:4px;
  border:1px solid #ebebeb;
  background-color:#fcfcfc;
}

.highlight.highlight--embed:after,.highlight.highlight--embed:before{
  position:absolute;
  content:"";
  left:0;
  width:100%;
  height:20px;
  z-index:1;
}

.highlight.highlight--embed:before{
  top:0;
  background:-webkit-gradient(linear, left bottom, left top, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, .9)));
  background:linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, .9));
}

.highlight.highlight--embed:after{
  bottom:0;
  background:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, .9)));
  background:linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, .9));
}

.highlight.highlight--embed .highlight__circle .circle__content--left.circle__content--time{
  left:24px;
  top:6px;
  right:auto;
}

.highlight.highlight--embed .highlight__circle .circle__content--left.circle__content--time b{
  color:#0f2842;
  opacity:.72;
}

.highlight.highlight--embed .highlight__circle .circle__content--right.circle__content--info{
  left:50%;
  top:-4px;
}

.highlight.highlight--embed .highlight__circle .circle__content{
  color:#0f2842;
}

.highlight.highlight--embed .highlight__circle .circle__icon{
  width:32px;
  height:32px;
  line-height:43px;
  margin:22px auto 22px 25%;
  -webkit-box-shadow:0 0 2px 0 rgba(41, 41, 41, .16);
          box-shadow:0 0 2px 0 rgba(41, 41, 41, .16);
}

.highlight.highlight--embed .highlight__circle .circle__icon .icon-ball{
  color:#0f2842;
}

.highlight.highlight--embed .highlight__circle .circle__icon:before{
  top:-22px;
  height:22px;
  border-color:rgba(41, 41, 41, .2);
}

.highlight.highlight--embed .highlight__circle .circle__icon:after{
  position:relative;
  bottom:-11px;
  display:block;
  margin:0 auto;
  content:"";
  width:1px;
  height:22px;
  border:1px dashed rgba(41, 41, 41, .2);
}

.highlight .highlight__halfTime{
  margin-bottom:65px;
}

.highlight .highlight__halfTime p{
  font-size:30px;
  color:#292929;
  margin-bottom:0;
}

.highlight .highlight__halfTime p span.is-winner{
  font-weight:700;
}

.highlight .highlight__halfTime div,.highlight .highlight__title{
  font-family:Din;
  font-size:14px;
  text-transform:uppercase;
  color:#999;
  text-align:center;
  font-weight:700;
}

.highlight .highlight__title--end{
  margin-bottom:60px;
}

.highlight .highlight__circle{
  position:relative;
}

.highlight .highlight__circle .circle__content{
  position:absolute;
  margin-bottom:0;
  line-height:20px;
  color:#292929;
  font-weight:700;
  font-size:15px;
}

.highlight .highlight__circle .circle__content span{
  display:block;
  opacity:.4;
  font-weight:400;
}

.highlight .highlight__circle .circle__content--left{
  text-align:right;
}

.highlight .highlight__circle .circle__content--left.circle__content--info{
  top:0;
  right:217px;
}

.highlight .highlight__circle .circle__content--left.circle__content--time{
  top:12px;
  right:220px;
}

.highlight .highlight__circle .circle__content--right.circle__content--info{
  top:0;
  left:212px;
  text-align:left;
}

.highlight .highlight__circle .circle__content--right.circle__content--time{
  top:12px;
  left:214px;
}

.highlight .highlight__circle .circle__content--time b{
  color:#292929;
  opacity:.4;
}

.highlight .highlight__circle .circle__icon{
  position:relative;
  display:block;
  width:40px;
  height:40px;
  background:#fff;
  border-radius:50%;
  text-align:center;
  line-height:40px;
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, .16);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, .16);
  margin:0 auto 41px;
}

.highlight .highlight__circle .circle__icon:before{
  position:relative;
  top:-40px;
  display:block;
  margin:0 auto;
  content:"";
  width:1px;
  height:40px;
  border:1px dashed #ccc;
}

.highlight .highlight__circle .circle__icon i{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
}

.highlight .highlight__circle .circle__icon .icon-red-card,.highlight .highlight__circle .circle__icon .icon-sub,.highlight .highlight__circle .circle__icon .icon-yellow-card{
  display:inline-block;
  width:16px;
  height:18px;
  background-size:cover;
}

.highlight .highlight__circle .circle__icon .icon-sub{
  background-image:url(../img/icon-substitution.svg);
}

.highlight .highlight__circle .circle__icon .icon-red-card{
  background-image:url(../img/icon-red-card.svg);
  margin-left:2px;
}

.highlight .highlight__circle .circle__icon .icon-yellow-card{
  background-image:url(../img/icon-yellow-card.svg);
  margin-left:2px;
}

.highlight .highlight__circle .circle__icon .icon-ball,.highlight .highlight__circle .circle__icon .icon-cage,.highlight .highlight__circle .circle__icon .icon-drop{
  color:#280b0d;
}

.highlight .highlight__circle .circle__icon .icon-cage{
  font-size:20px;
}

.highlight .highlight__circle .circle__icon .icon-drop{
  font-size:17px;
}

.highlight .highlight__circle--first:before{
  top:42px;
  width:1px;
  height:40px;
  border:1px dashed #ccc;
}

.highlight .highlight__circle--first:after,.highlight .highlight__circle--first:before{
  position:absolute;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  display:block;
  content:"";
}

.highlight .highlight__circle--first:after{
  top:82px;
  width:8px;
  height:8px;
  border-radius:50%;
  background:#ccc;
}

.highlight .highlight__circle--first .circle__icon{
  margin-bottom:82px;
}

.highlight .highlight__circle--last:after{
  position:absolute;
  left:50%;
  bottom:82px;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  display:block;
  content:"";
  width:8px;
  height:8px;
  border-radius:50%;
  background:#ccc;
}

@media (min-width:768px){
  .highlight{
    width:inherit;
    max-width:427px;
  }
  .highlight.highlight--embed .highlight__circle .circle__content--left.circle__content--time{
    left:27px;
    top:14px;
  }
  .highlight.highlight--embed .highlight__circle .circle__content--right.circle__content--info{
    left:42%;
    top:5px;
  }
  .highlight.highlight--embed .highlight__circle .circle__icon{
    width:48px;
    height:48px;
    line-height:48px;
    margin-left:20%;
    margin-top:14px;
    margin-bottom:14px;
  }
  .highlight.highlight--embed .highlight__circle .circle__icon:before{
    height:13px;
    top:-14px;
  }
  .highlight.highlight--embed .highlight__circle .circle__icon:after{
    height:14px;
    bottom:-35px;
  }
  .highlight .highlight__circle .circle__content--left.circle__content--info{
    top:9px;
    right:287px;
  }
  .highlight .highlight__circle .circle__content--left.circle__content--time{
    top:17px;
    right:290px;
  }
  .highlight .highlight__circle .circle__content--right.circle__content--info{
    top:9px;
    left:289px;
  }
  .highlight .highlight__circle .circle__content--right.circle__content--time{
    top:17px;
    left:290px;
  }
  .highlight .highlight__circle .circle__icon{
    width:56px;
    height:56px;
    line-height:56px;
  }
  .highlight .highlight__circle--first:before{
    top:57px;
  }
  .highlight .highlight__circle--first:after{
    top:99px;
  }
  .highlight .highlight__circle--last:after{
    bottom:93px;
  }
}

.news-slider{
  overflow-x:auto;
  -webkit-overflow-scrolling:touch;
  margin-left:-16px;
  margin-right:-16px;
  padding-left:16px;
}

.news-slider .card--video,.news-slider .news{
  vertical-align:top;
  display:inline-block;
  width:220px;
  margin-left:16px;
  margin-bottom:0;
}

.news-slider .card--video:first-child,.news-slider .news:first-child{
  margin-left:0;
}

.news-slider .card--video{
  width:286px;
}

.news-slider .picture{
  position:relative;
  width:120px;
  height:76px;
  margin-left:8px;
}

.news-slider .picture:first-child{
  margin-left:0;
}

.news-slider .picture:after,.news-slider .picture:before{
  position:absolute;
  content:"";
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.news-slider .picture:before{
  background-color:rgba(230, 103, 154, .5);
  opacity:0;
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  z-index:2;
}

.news-slider .picture .see-more{
  position:absolute;
  display:none;
  top:50%;
  left:0;
  width:100%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  padding:0 8px;
  font-size:12px;
  font-family:Din;
  font-weight:700;
  text-align:center;
  color:#fff;
  z-index:3;
}

.news-slider .picture--last .see-more{
  display:block;
}

.news-slider .picture--last:before{
  background-color:rgba(230, 103, 154, .88);
  opacity:1;
}

.news-slider .picture:hover:not(.picture--active){
  cursor:pointer;
}

.news-slider .picture:hover:not(.picture--active):before{
  opacity:1;
}

.news-slider .picture.picture--active:after{
  border:4px solid #e6679a;
  z-index:1;
}

.news-slider .picture .picture__thumbnail{
  position:absolute;
  content:"";
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:no-repeat 50%;
  background-size:cover;
  z-index:1;
}

.news-slider .picture img{
  display:none;
}

.news-slider__wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:708px;
}

.news-slider__wrapper--video{
  width:908px;
}

.news-slider__wrapper--picture{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:786px;
}

@media (min-width:768px){
  .news-slider{
    overflow-x:auto;
    margin-left:0;
    margin-right:0;
    padding-left:0;
  }
  .news-slider::-webkit-scrollbar{
    width:4px;
    height:4px;
    border-radius:4px;
  }
  .news-slider::-webkit-scrollbar-track{
    background:rgba(125, 147, 168, .32);
    border-radius:4px;
  }
  .news-slider::-webkit-scrollbar-thumb{
    background:#7d93a8;
    border-radius:4px;
  }
  .news-slider::-webkit-scrollbar-thumb:hover{
    background:#7d93a8;
  }
  .news-slider .card--video,.news-slider .news{
    width:calc(50% - 8px);
    margin-left:16px;
  }
  .news-slider .card--video:nth-child(3),.news-slider .news:nth-child(3){
    display:none;
  }
  .news-slider .picture{
    width:160px;
    height:88px;
    margin-left:16px;
    margin-bottom:16px;
  }
  .card--video{
    height:269px;
  }
  .news-slider__wrapper{
    width:auto;
  }
  .news-slider__wrapper--picture{
    width:1068px;
  }
}

@media (min-width:1280px){
  .news-slider .card--video,.news-slider .news{
    width:calc(33.3% - 16px);
    margin-left:24px;
  }
  .news-slider .card--video:nth-child(3),.news-slider .news:nth-child(3){
    display:inline-block;
  }
  .news-slider .card--video{
    height:292px;
  }
  .news-slider .picture{
    width:184px;
    height:100px;
  }
  .news-slider .news--row-lg{
    display:block;
    width:100%;
    margin-left:0;
    margin-bottom:24px;
  }
  .news-slider .news--row-lg:last-child{
    margin-bottom:0;
  }
  .news-slider__wrapper{
    display:block;
  }
}

.result{
  position:relative;
}

.result .result-club__maps{
  position:absolute;
  top:0;
  padding-top:0;
  height:100%;
}

.result-club{
  position:relative;
  width:100%;
  height:calc(100vh - 128px);
}

.result-club.has-map .result-club__maps{
  display:block;
}

.result-club.has-map .result-club__list{
  display:none;
}

.result-club .card--club.box:hover,.result-club .card--club.is-selected{
  -webkit-box-shadow:0 0 0 2px inset #e6679a, 0 10px 16px rgba(51, 51, 51, .08);
          box-shadow:inset 0 0 0 2px #e6679a,0 10px 16px rgba(51, 51, 51, .08);
}

.result-club .card--club:focus .card__title,.result-club .card--club:hover .card__title{
  color:#292929;
  text-decoration:none;
}

.result-club .card--club:focus .card__position,.result-club .card--club:hover .card__position{
  color:#fff;
  background:#e6679a;
}

.result-club__maps{
  display:none;
  position:fixed;
  right:0;
  bottom:0;
  padding-top:129px;
  background-color:#f0f2f5;
  z-index:0;
}

.result-club__maps,.result-club__maps iframe{
  width:100%;
  height:100%;
}

.result-club__maps .btn{
  position:absolute;
  top:144px;
  right:16px;
  width:128px;
}

.result-club__slider{
  position:absolute;
  bottom:40px;
  left:0;
  width:100%;
}

.result-club__slider .card{
  width:303px;
  margin:0 auto;
}

.result-club__header .btn{
  float:right;
  width:128px;
}

.result-club__header p{
  margin:0;
  line-height:48px;
  color:#999;
}

.result-academies .result-club__maps{
  z-index:0;
  height:100vh;
}

.result-academies .result-club__maps.sticky{
  position:fixed;
  height:100vh;
}

.result-academies .result-club__maps.fullheight{
  bottom:0;
  top:auto;
}

.result-academies .result-club__maps.fullheight iframe{
  position:absolute;
  height:100vh;
  bottom:0;
}

@media (min-width:768px){
  .result-club{
    height:calc(100vh - 136px);
    overflow:scroll;
    -webkit-overflow-scrolling:touch;
  }
  .result-club__maps{
    display:block;
    right:0;
    width:41%;
  }
  .result-club__header .btn,.result-club__maps .btn,.result-club__slider{
    display:none;
  }
  .result-club__header .dropdown{
    float:right;
    width:255px;
  }
  .result-club__header p{
    float:left;
    line-height:56px;
  }
}

@media (min-width:1280px){
  .result-club{
    height:calc(100vh - 210px);
  }
  .result-club__maps{
    width:50%;
    width:calc(50% + 60px);
    padding-top:210px;
  }
}

.article{
  border-bottom:1px solid #ebebeb;
  margin-bottom:40px;
  padding-bottom:24px;
}

.article .list,.article>div>.row>.col>p,.article>div>p{
  margin-bottom:16px;
  font-size:18px;
  line-height:32px;
}

.article>div>p a{
  color:#e6679a;
  font-weight:700;
}

.article>div>p a.btn--primary{
  color:#fff;
}

.article .card--user{
  width:calc(33.3% - 21px);
  max-width:200px;
}

.article .card{
  margin-bottom:40px;
  margin-top:32px;
}

.article .event{
  margin-bottom:40px;
  margin-top:20px;
}

.article .card--download+.card--download{
  margin-top:-32px;
}

.article .card--score.over,.article .card--score.upcoming{
  width:100%;
  height:auto;
}

.article .card--hub-alt{
  height:244px;
  min-height:228px;
}

.article .card--hub-alt .card__title{
  font-size:30px;
  line-height:36px;
}

.article .card--hub-alt .card__desc{
  max-width:411px;
}

.article figure:not(.article__cover),.article table,.page figure:not(.article__cover),.page table{
  margin-top:32px;
  margin-bottom:40px;
}

.article figure.is-provider-instagram,.page figure.is-provider-instagram{
  max-width:522px;
}

.article .carousel-photo,.page .carousel-photo{
  margin-bottom:40px;
}

.article .carousel-photo figure,.page .carousel-photo figure{
  margin-bottom:8px;
}

.article .quote,.page .quote{
  margin-top:32px;
  margin-bottom:32px;
}

.article .twitter-tweet,.page .twitter-tweet{
  margin-top:24px !important;
  margin-bottom:32px !important;
}

.article .iframe-twitch,.article .iframe-youtube,.page .iframe-twitch,.page .iframe-youtube{
  width:100%;
  height:208px;
}

.article .iframe-twitch.iframe-twitch__channel,.page .iframe-twitch.iframe-twitch__channel{
  height:700px;
}

.article__title{
  margin-bottom:32px;
  font-size:30px;
  line-height:36px;
}

.article__meta{
  margin-bottom:16px;
}

.article__meta .label{
  margin-right:8px;
}

.article__date{
  color:#ccc;
}

.article__cover{
  margin-bottom:24px;
  background-color:transparent;
  -webkit-transition:background .3s;
  transition:background .3s;
}

.article__cover figcaption{
  margin-bottom:0;
}

.article__cover .iframe-close{
  display:none;
}

.article__thumbnail{
  height:280px;
  background:no-repeat top;
  background-size:cover;
}

.article__thumbnail img{
  display:none;
}

.article__social{
  padding:16px 0;
  margin:24px 0 32px;
  border-top:2px solid #ebebeb;
  border-bottom:2px solid #ebebeb;
  text-align:center;
}

.article__social .btn{
  margin:0 20px;
}

.article__social .btn:first-child{
  margin-left:0;
}

.article__social .btn:last-child{
  margin-right:0;
}

.article__headline{
  font-family:Din;
  font-size:20px;
  line-height:36px;
  font-weight:500;
}

.article__topbar{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:64px;
  z-index:20;
  background-color:#fff;
  -webkit-box-shadow:0 3px 8px rgba(51, 51, 51, .08);
          box-shadow:0 3px 8px rgba(51, 51, 51, .08);
  display:none;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
}

.article__topbar p{
  margin:0;
  line-height:32px;
}

.article__topbar>div{
  padding-left:16px;
  padding-right:16px;
  margin:0 auto;
  width:100%;
  max-width:1680px;
}

.article__topbar .label{
  float:left;
  margin-right:8px;
  margin-top:4px;
}

.article__topbar .btn--icon{
  display:none;
}

.article__topbar.is-visible{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.article__topbar-social{
  float:right;
}

.article__topbar-social .btn{
  margin-left:8px;
}

@media (min-width:768px){
  .article .list,.article>div>.row>.col>p,.article>div>p{
    font-size:17px;
  }
  .article .card--xSmall{
    height:inherit;
  }
  .article .iframe-twitch,.article .iframe-youtube,.page .iframe-twitch,.page .iframe-youtube{
    height:288px;
  }
  .article .iframe-twitch.iframe-twitch__channel,.page .iframe-twitch.iframe-twitch__channel{
    height:800px;
  }
  .article .carousel-photo,.page .carousel-photo{
    margin-bottom:56px;
  }
  .article .card:not(.card--large,.card--score,.card--xSmall,.card--picture),.page>.card:not(.card--large,.card--ticket){
    width:66.6%;
  }
  .article__cover{
    margin-bottom:32px;
  }
  figcaption{
    line-height:22px;
  }
  .article__social{
    margin-bottom:40px;
  }
  .article__social .btn:first-child{
    margin-left:20px;
  }
}

@media (min-width:1000px){
  .article .iframe-twitch.iframe-twitch__channel,.page .iframe-twitch.iframe-twitch__channel{
    height:1000px;
  }
}

@media (min-width:1337px){
  .article .iframe-twitch.iframe-twitch__channel,.page .iframe-twitch.iframe-twitch__channel{
    height:500px;
  }
}

@media (min-width:1080px){
  .article .card+.card+h2,.article .card+.card+h3,.article .card+.card+p,.page .card+.card+h2,.page .card+.card+h3,.page .card+.card+p{
    clear:both;
  }
  .article .card+p+.card,.page .card+p+.card{
    clear:left;
  }
  .article .card:not(.card--large,.card--large,.card--score,.card--xSmall,.card--picture),.page>.card:not(.card--large,.card--ticket){
    width:41.66%;
    margin-right:32px;
    margin-top:0;
    float:left;
  }
}

@media (min-width:768px){
  .article__headline{
    font-size:24px;
    line-height:36px;
  }
  .article__title{
    font-size:40px;
    line-height:48px;
  }
}

@media (min-width:1280px){
  .article{
    padding-bottom:56px;
  }
  .article .article__headline{
    margin-bottom:40px;
  }
  .article .card--score.over{
    height:116px;
  }
  .article .event,.page .event{
    max-width:498px;
    margin-left:auto;
    margin-right:auto;
  }
  .article .iframe-twitch,.article .iframe-youtube,.page .iframe-twitch,.page .iframe-youtube{
    height:432px;
  }
  .article__title{
    width:calc(51.2% - 32px);
    height:363px;
    border-bottom:1px solid #ebebeb;
    margin-bottom:80px;
  }
  .article__cover{
    position:absolute;
    top:0;
    left:53%;
    margin:0;
    width:100%;
  }
  .article__cover figcaption{
    width:60%;
  }
  .article__thumbnail{
    height:460px;
  }
  .article__sidebar{
    padding-top:610px;
  }
  .article__social{
    position:absolute;
    left:-10%;
    top:0;
    border:0;
    margin:0;
    padding-top:8px;
  }
  .article__social .btn{
    display:block;
    margin-bottom:12px;
  }
  .article__headline{
    font-size:28px;
    line-height:40px;
  }
  .article__topbar .btn--icon{
    float:right;
    display:block;
  }
  .article__topbar p{
    line-height:40px;
  }
  .article__topbar .label{
    margin-top:8px;
  }
  .article__topbar-social{
    display:none;
  }
}

.page{
  padding-top:32px;
}

.page .col p,.page .list,.page>div>.row>.col>p,.page>div>p,.page>p{
  font-size:18px;
  line-height:32px;
}

.page>h3{
  display:inline-block;
}

.page>h6{
  color:#999;
  text-transform:uppercase;
  margin-top:24px;
  margin-bottom:8px;
}

.page .list{
  margin-left:0;
}

.page .article__headline{
  margin-bottom:40px;
}

.page .page-head{
  font-size:20px;
  line-height:36px;
  font-family:Din;
}

.page__hub{
  padding-top:32px;
}

.page--full{
  padding-bottom:40px;
}

.page__cards{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

.page__cards .card{
  width:100%;
  margin-bottom:16px;
}

.page__head h3{
  margin-bottom:24px;
}

.page__head .input--search{
  margin-bottom:0;
}

.page__head .input--search:before{
  font-size:12px;
  margin-top:-6px;
}

.page__head .input--search input{
  font-size:16px;
}

.page__article{
  position:relative;
  margin-bottom:109px;
}

.page__article .link-chevron{
  position:absolute;
  bottom:-60px;
  height:60px;
  line-height:60px;
  width:100%;
  border-bottom:1px solid #ebebeb;
}

.page__article .link-chevron i{
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}

.page__article .news{
  border-bottom:1px solid #ebebeb;
  padding-bottom:16px;
}

.page__article .news .news__thumbnail{
  display:none;
  height:228px;
  border-radius:4px;
}

.page__article .news .news__title{
  font-size:17px;
  line-height:22px;
}

.page__article .col:first-child .news .news__thumbnail{
  display:block;
}

.page__article .col:last-child .news{
  margin-bottom:0;
}

.page__timeline>div{
  width:calc(100% - 22px);
  margin-left:auto;
  position:relative;
  border-style:dotted;
  border-image-source:url(../img/dots.svg);
  border-width:0 0 0 3px;
  border-image-slice:12% 20%;
  border-image-repeat:round;
  padding-left:32px;
  padding-bottom:24px;
}

@media not all and (min-resolution:0.001dpcm){
  @supports (-webkit-appearance:none){
    .page__timeline>div{
      border-color:rgba(15, 40, 66, .72);
      border-image-slice:15% 20%;
    }
  }
}

.page__timeline>div.with--title{
  padding-bottom:0;
}

.page__timeline>div .page__timeline__icon{
  position:absolute;
  top:5px;
  left:-25px;
  width:48px;
  height:48px;
  text-align:center;
  line-height:47px;
  border-radius:50%;
  color:#0f2842;
  border:1px solid #ebebeb;
  background:#fcfcfc;
}

.page__timeline>div .page__timeline__icon i{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  font-size:17px;
}

.page__timeline>div.page__timeline__head{
  padding-top:32px;
  margin-top:56px;
}

.page__timeline>div.page__timeline__head.with--title{
  padding-top:0;
}

.page__timeline>div.page__timeline__head .page__timeline__icon{
  top:35px;
  background-color:#fff;
  border:1px solid rgba(15, 40, 66, .4);
  -webkit-box-shadow:0 0 2px 0 rgba(41, 41, 41, .16);
          box-shadow:0 0 2px 0 rgba(41, 41, 41, .16);
}

.page__timeline>div.page__timeline__head>span{
  position:absolute;
  top:-41px;
  left:-22px;
  color:#0f2842;
  font-size:17px;
}

.page__timeline>div.page__timeline__head>h2{
  position:absolute;
  top:-40px;
  font-size:20px;
  line-height:26px;
}

.page__timeline>div.page__timeline__last{
  position:relative;
}

.page__timeline>div.page__timeline__last:after{
  position:absolute;
  bottom:-20px;
  left:-10px;
  display:block;
  content:"";
  width:20px;
  height:140px;
  background:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
  background:linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
  filter:progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 );
}

.page__timeline>div p{
  font-size:17px;
  line-height:32px;
}

.page__timeline .card+p,.page__timeline .card--histoire+p{
  margin-top:24px;
}

.page__timeline .card--hub{
  height:228px;
}

.page__timeline .card--hub .card__body{
  padding-top:32px;
  padding-bottom:87px;
  max-width:100%;
}

.page__timeline .card--hub--compact{
  height:316px;
}

.page__timeline .card--hub--compact .card__body{
  padding-bottom:0;
}

.page__timeline__container.page__timeline__container--close{
  height:1200px;
  overflow:hidden;
}

.page__more{
  z-index:5;
  width:calc(100% - 16px);
  height:291px;
  background-image:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .05)), color-stop(38%, hsla(0, 0%, 100%, .6)), color-stop(99%, #fff));
  background-image:linear-gradient(180deg, hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, .6) 38%, #fff 99%);
}

.page__more,.page__more .btn{
  position:absolute;
  bottom:0;
}

.page__more .btn{
  width:calc(100% - 8px);
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}

@media (min-width:768px){
  .page{
    padding-top:48px;
  }
  .page .card--histoire,.page .card--hub--compact{
    width:286px;
  }
  .page .col p,.page .list,.page>div>.row>.col>p,.page>div>p,.page>p{
    font-size:17px;
  }
  .page .card--data{
    width:286px;
    margin-left:auto;
    margin-right:auto;
  }
  .page .article__headline{
    margin-bottom:56px;
  }
  .page__hub{
    padding-top:48px;
  }
  .page__article{
    margin-bottom:0;
  }
  .page__article .news{
    border-bottom:0;
    padding-bottom:0;
    margin-bottom:48px;
  }
  .page__article .news .news__thumbnail{
    display:block;
    height:165px;
    border-radius:0;
    margin-bottom:16px;
  }
  .page__article .news .news__title{
    font-size:inherit;
    line-height:inherit;
  }
  .page__article .col:last-child .news{
    display:none;
  }
  .page__article .link-chevron{
    width:inherit;
    border-bottom:0;
    height:inherit;
    line-height:inherit;
    bottom:0;
  }
  .page__article .link-chevron i{
    top:0;
    -webkit-transform:inherit;
            transform:inherit;
  }
  .page--full{
    padding-top:56px;
    padding-bottom:56px;
  }
  .page__head{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
  }
  .page__head .dropdown,.page__head h3{
    margin-bottom:0;
  }
  .page__head .dropdown,.page__head .input--search{
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .page__head .dropdown{
    width:240px;
  }
  .page__head .input--search{
    width:275px;
  }
  .page__head .input--search:before{
    left:16px;
  }
  .page__head .input--search input{
    font-size:14px;
    padding-left:40px;
    padding-right:16px;
  }
  .page__cards .card{
    width:48%;
    width:calc(50% - 8px);
  }
  .page__timeline>div{
    padding-bottom:32px;
    padding-left:48px;
  }
  .page__timeline>div.with--title{
    padding-bottom:8px;
  }
  .page__timeline>div.page__timeline__head{
    padding-top:40px;
  }
  .page__timeline>div.page__timeline__head.with--title{
    margin-top:90px;
  }
  .page__timeline>div.page__timeline__head h2{
    top:-49px;
    font-size:30px;
    line-height:38px;
  }
  .page__timeline .page__timeline__icon{
    font-size:17px;
  }
  .page__timeline .card+p,.page__timeline .card--histoire+p{
    margin-top:32px;
  }
  .page__timeline .card--news-richscore,.page__timeline .card--simple-event{
    -webkit-transform:translateY(-16px);
            transform:translateY(-16px);
  }
  .page__more .btn{
    width:auto;
    max-width:212px;
    bottom:35px;
  }
  .page__timeline__container.page__timeline__container--close{
    height:1500px;
  }
}

@media (min-width:1280px){
  .page{
    padding-top:64px;
  }
  .page .card--data,.page .card--game,.page .card--histoire,.page .card--hub--compact,.page .card--job{
    width:286px;
    margin-right:32px;
    margin-top:0;
    float:left;
  }
  .page .page-head{
    font-size:24px;
    line-height:36px;
  }
  .page__hub{
    padding-top:64px;
  }
  .page--full{
    padding-bottom:80px;
  }
  .page__cards:after{
    content:"";
  }
  .page__cards .card,.page__cards:after{
    width:33%;
    width:calc(33.3% - 21px);
  }
  .page__cards .card{
    margin-bottom:32px;
  }
  .page__head .input--search{
    width:390px;
  }
  .page__article{
    margin-bottom:0;
  }
  .page__article>.row{
    margin-bottom:72px;
  }
  .page__article .news{
    margin-bottom:0;
  }
  .page__article .col:last-child .news{
    display:block;
  }
  .page__article .link-chevron{
    position:inherit;
    top:2px;
  }
  .page__timeline{
    padding-left:24px;
  }
  .page__timeline>div{
    width:100%;
    margin-left:auto;
  }
  .page__timeline .card+p,.page__timeline .card--histoire+p{
    margin-top:0;
  }
  .page__timeline>div{
    padding-bottom:48px;
  }
  .page__timeline>div.page__timeline__head{
    padding-top:56px;
  }
  .page__timeline>div.page__timeline__head .page__timeline__icon{
    top:62px;
  }
  .page__more{
    width:calc(100% + 8px);
    left:-8px;
  }
  .page__more .btn{
    margin-left:16px;
    bottom:0;
  }
  .page__timeline__container .page{
    padding-top:48px;
  }
  .page__timeline__container.page__timeline__container--close{
    height:1700px;
  }
}

.events{
  position:relative;
}

.events.not-relative{
  position:inherit;
}

.events:after{
  position:absolute;
  content:"";
  height:200px;
  width:100%;
  left:0;
  bottom:0;
  background-image:-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .05)), color-stop(0, hsla(0, 0%, 100%, 0)), color-stop(29%, hsla(0, 0%, 100%, .6)), to(#fff));
  background-image:linear-gradient(180deg, hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, .6) 29%, #fff);
  z-index:1;
}

.events .navbar__sub{
  top:95%;
}

.events .btn--see-more{
  position:absolute;
  left:24px;
  right:24px;
  bottom:24px;
  z-index:2;
}

.events__filter{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:stretch;
      -ms-flex-align:stretch;
          align-items:stretch;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  height:auto;
  border-bottom:2px solid #ebebeb;
}

.events__filter .dropdown,.events__filter .filter-option__cta{
  -webkit-box-shadow:none;
          box-shadow:none;
  border-radius:0;
  border:none;
}

.events__filter .dropdown__head,.events__filter .filter-option__cta{
  height:100%;
  line-height:62px;
}

.events__filter .dropdown{
  -webkit-box-flex:2;
      -ms-flex-positive:2;
          flex-grow:2;
  min-width:0;
  border-right:0;
}

.events__filter .dropdown:last-child{
  border-bottom:0;
}

.events__filter .dropdown--navbar{
  display:none;
}

.events__filter .dropdown--filter{
  width:60%;
  float:left;
  border-right:1px solid #ebebeb;
}

.events__filter .filter-option .btn--white{
  border-left:1px solid #ebebeb;
}

.events__filter .filter-option__cta{
  padding:0 32px;
  -ms-flex-negative:0;
      flex-shrink:0;
  font-family:Din;
  font-size:12px;
  font-weight:700;
}

.events__filter .navbar{
  display:none;
}

.events__pagination{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:72px;
  padding:0 16px;
}

.events__pagination .is-inline{
  margin-left:0;
}

.events__group{
  border-bottom:1px solid #ebebeb;
}

.events__group>.link-chevron{
  margin-left:80px;
  border-left:1px solid #ebebeb;
  border-bottom:3px solid #e6679a;
}

.events__group .event-row{
  border-bottom:0;
}

.events__group .event-row .card--nextGameFull{
  border-bottom:1px solid #ebebeb;
}

.events__group .event-row:last-child .card--nextGameFull,.events__group .event-row:last-child .event__content{
  border-bottom:0;
}

.events__group .accordion-panel.is-hidden+.link-chevron{
  border-top:1px solid #ebebeb;
}

.events__group .accordion-panel{
  border-bottom:1px solid #ebebeb;
}

.events__group .accordion-panel .event-row{
  border-top:1px solid #ebebeb;
}

.events__group .accordion-panel .event-row .event__date{
  display:none;
}

.events__group .card--score{
  border-radius:0;
  -webkit-box-shadow:none;
          box-shadow:none;
}

.events__filter--ligue{
  display:initial;
  height:124px;
}

.events__filter--ligue .dropdown{
  border-bottom:1px solid #ebebeb;
}

.events__filter-group{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:60%;
  border-right:1px solid #ebebeb;
}

.events__filter-group .dropdown{
  border-bottom:0;
}

@media (min-width:768px){
  .events .event__content{
    -webkit-transition:-webkit-box-shadow .3s ease;
    transition:-webkit-box-shadow .3s ease;
    transition:box-shadow .3s ease;
    transition:box-shadow .3s ease,-webkit-box-shadow .3s ease;
  }
  .events .card--nextGameFull:hover,.events .event__content:hover{
    -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08), 0 0 40px rgba(51, 51, 51, .08);
            box-shadow:0 10px 16px rgba(51, 51, 51, .08),0 0 40px rgba(51, 51, 51, .08);
  }
  .events .card--nextGameFull.has-training:hover{
    -webkit-box-shadow:none;
            box-shadow:none;
  }
  .events .card--score:hover{
    cursor:pointer;
    -webkit-box-shadow:0 10px 16px rgba(51, 51, 51, .08);
            box-shadow:0 10px 16px rgba(51, 51, 51, .08);
    z-index:2;
  }
  .events .btn--see-more{
    left:50%;
    right:auto;
    bottom:32px;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
  }
  .events__filter{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
  }
  .events__filter .dropdown{
    -ms-flex-negative:0;
        flex-shrink:0;
    border-left:1px solid #ebebeb;
    border-right:0;
    border-bottom:0;
  }
  .events__filter .dropdown--navbar{
    display:none;
  }
  .events__filter .navbar{
    display:block;
  }
  .events__filter .filter-option{
    display:none;
  }
  .events__filter .dropdown--navbar{
    width:60%;
    -ms-flex-negative:1;
        flex-shrink:1;
  }
  .events__filter .dropdown--filter{
    width:auto;
  }
  .events__filter .navbar__links>li{
    line-height:62px;
  }
  .events__filter .navbar__links>li>a{
    padding:0 12px;
  }
  .events__group .accordion-panel .event-row{
    border-top:none;
  }
  .events__group .accordion-panel .event-row .event__date{
    display:table-cell;
  }
  .events__group .card--score{
    border-top:1px solid #ebebeb;
  }
  .events__group .card--score .match__info{
    width:140px;
  }
  .events__group .match__club:first-child{
    padding-right:50px;
  }
  .events__group .match__club:last-child{
    padding-left:50px;
  }
  .events__pagination{
    padding:0 24px;
  }
  .events__filter-group{
    -ms-flex-negative:0;
        flex-shrink:0;
  }
  .events__filter--ligue{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    height:64px;
  }
  .events__filter--ligue .dropdown{
    border-bottom:initial;
  }
  .events__filter--ligue .dropdown.dropdown--navbar{
    display:none;
  }
}

@media (min-width:1280px){
  .events:after{
    height:160px;
  }
  .events__group>.link-chevron{
    margin-left:119px;
  }
  .events__filter{
    padding-left:40px;
  }
  .events__filter .navbar__links>li>a{
    padding:0 16px;
  }
  .events__filter .filter-option .btn--white{
    display:none;
  }
  .events__filter .filter-option .dropdown{
    margin-bottom:0;
  }
  .events__filter .filter-option__list{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .events__pagination{
    padding:0 40px;
  }
  .events__filter-group{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse;
  }
  .events__filter-group .dropdown{
    border-left:1px solid #ebebeb;
  }
}

.search{
  background-color:#fff;
  padding-top:24px;
  padding-bottom:48px;
}

.search .select{
  margin-bottom:24px;
}

.search .link-blur:before{
  height:160px;
}

.search .navbar{
  display:none;
}

.search h3 .label{
  margin-left:8px;
}

@media (min-width:768px){
  .search{
    padding-top:40px;
    padding-bottom:56px;
  }
  .search .select{
    display:none;
  }
  .search .navbar{
    display:block;
    position:relative;
  }
  .search .navbar:after{
    content:"";
    display:block;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:4px;
    background:#ebebeb;
    border-radius:4px;
  }
}

@media (min-width:1280px){
  .search{
    padding-bottom:80px;
  }
  .search .col-lg-4,.search .col-lg-8{
    float:right;
  }
}

.home-slider{
  position:relative;
  padding-bottom:120px;
  color:#fff;
}

.home-slider+.container>.card--score{
  margin-top:-80px;
}

.home-slider+.container>.card--score .label{
  background-color:#e6679a;
  text-align:center;
}

.home-slider .container{
  height:100%;
}

.home-slider .label{
  margin-bottom:16px;
}

.home-slider .btn--cheer{
  display:none;
}

.home-slider .img-blue-dark:before{
  opacity:.6;
}

.home-slider h2 a:hover{
  color:#fff;
  text-decoration:none;
}

.home-slider__links{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:100%;
  width:calc(100% - 326px);
  margin-bottom:0;
}

@media (min-width:1590px){
  .home-slider__links{
    width:calc(100% - 370px);
  }
}

@media (max-width:1080px){
  .home-slider__links{
    width:calc(100% - 35vw + 60px);
  }
}

.home-slider__links li{
  display:inline-block;
  margin-right:12px;
}

.home-slider__links li a,.home-slider__links li span{
  white-space:nowrap;
}

.home-slider__links>li:first-child{
  opacity:.5;
}

.home-slider__links .accordion-panel li{
  display:block;
}

.home-slider__links .accordion-trigger:after{
  position:absolute;
  line-height:22px;
  margin-left:4px;
}

.home-slider__wrapper{
  position:relative;
  height:100%;
}

.home-slider__item{
  padding-top:100px;
}

.home-slider__pagination{
  position:absolute;
  bottom:50px;
  right:0;
  width:100%;
  text-align:center;
  font-size:0;
  font-weight:500;
  margin-bottom:0;
}

.home-slider__pagination li{
  display:inline-block;
  vertical-align:middle;
  width:20px;
  height:20px;
  border:2px solid transparent;
  border-radius:100%;
  line-height:15px;
  margin:0 8px;
  overflow:hidden;
  cursor:pointer;
  color:hsla(0, 0%, 100%, .32);
  font-size:14px;
}

.home-slider__pagination li:after{
  content:"";
  width:8px;
  height:8px;
  background-color:hsla(0, 0%, 100%, .32);
  display:inline-block;
  border-radius:100%;
}

.home-slider__pagination li.is-active{
  border-color:hsla(0, 0%, 100%, .32);
  color:#fff;
}

.home-slider__pagination li.is-active:after,.home-slider__pagination span{
  display:none;
}

@media (min-width:768px){
  .home-slider{
    height:448px;
    padding-bottom:40px;
  }
  .home-slider .btn--cheer{
    display:inline-block;
    position:absolute;
    bottom:145px;
  }
  .home-slider .container{
    padding:0 16px;
  }
  .home-slider .live-results>.container{
    padding:0;
  }
  .home-slider__links{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
  }
  .home-slider__item{
    padding-top:162px;
  }
  .home-slider__pagination{
    width:auto;
  }
  .home-slider__pagination li{
    width:48px;
    height:48px;
    line-height:44px;
    border-color:hsla(0, 0%, 100%, .32);
  }
  .home-slider__pagination li:after{
    display:none;
  }
  .home-slider__pagination li:hover{
    border-color:#fff;
    color:#fff;
  }
  .home-slider__pagination span{
    display:inline-block;
  }
}

@media (min-width:1080px){
  .home-slider{
    height:608px;
  }
  .home-slider h2{
    font-size:48px;
    line-height:60px;
  }
  .home-slider .btn--cheer{
    bottom:170px;
  }
  .home-slider .live-results>.container{
    padding:0 0 0 60px;
  }
  .home-slider .container{
    padding:0 60px;
  }
  .home-slider__item{
    padding-top:272px;
  }
}

@media (min-width:1280px){
  .home-slider .home-slider__links li{
    margin-right:24px;
  }
}
