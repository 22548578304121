.footer__contact-detail {
  padding-bottom: 2em;
  margin-bottom: 2em;
  text-align: center;
  border-bottom: 1px solid #352019;
}

.footer__address,
.footer__phone-number {
  display: flex;
  text-align: left;
}

.footer__address-detail,
.footer__phone-number p {
  margin-left: .5em;
}

.footer__address-detail p {
  margin-bottom: 0;
}

.footer__phone-number {
  margin-top: 1em;
}

@media screen AND (min-width: 768px) {
  .footer__contact-detail {
    display: flex;
  }

  .footer__phone-number {
    margin-top: 0;
    margin-left: 3em;
  }
}

@media screen AND (min-width: 1280px) {
  .footer__links {
    display: flex;

  }

  .footer__links .row {
    width: 100% !important;
    margin-top: 0 !important;

  }

  .footer__links .col:first-child {
    margin-left: 0 !important;

  }

  .footer__logo {
    display: flex;
    flex-direction: row;
  }

  .footer__contact-detail {
    display: block;
    width: 25%;
    padding-bottom: unset;
    margin-bottom: unset;
    margin-left: 4em;
    border-bottom: unset;
  }

  .footer__phone-number {
    margin-left: unset;
    margin-top: 1em;
  }
} 