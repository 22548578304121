.partner_logo {
  animation: fade 2s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}
